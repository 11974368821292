class Panel {

    constructor(config) {
       
        this._controls = [];
        this._position = config.position;
        this._elementId = '';
        this._map = null;

    }

    set map (map){
        this._map = map;
        this.initialize();
    }

    get map(){
        return this._map;
    }

    get position() {
        return this._position;
    }

    get elementId(){
        return this._elementId;
    }

    initialize() {

        this._createPanel();
        this._saveDefaultStyle();

    }

    _panelExists() {

        for (let i = 0; i < this.map._panels.lenght; i++) {

            if (this.map._panels[i].position == this._position) {
                throw 'Painel já adicionado.';
            }
        }

    }

    _createPanel() {

        if (!this._panelExists(this._position)) {
            this.map._panels.push(this);
        }

        let cssClass = `gb-panel-${this._position}`;
        this._elementId = `${cssClass}_${this.map.elementId}`;
        let panelElement = document.createElement('div');
        let tooltipElement = document.createElement('div');

        panelElement.className = `gb-panel ${cssClass}`;
        panelElement.id = this._elementId;
        document.getElementById(this.map.elementId).append(panelElement);

        tooltipElement.className = `gb-panel-tooltip ${cssClass}-tooltip`;
        tooltipElement.id = `${this._elementId}-tooltip`;
        document.getElementById(this.map.elementId).append(tooltipElement);

    }

    _saveDefaultStyle(){

        this._defaultStyle = Object.assign({}, document.getElementById(this._elementId).style);

    }

    _restoreDefaultStyle(){

        document.getElementById(this._elementId).style = this._defaultStyle;

    }

}

const PanelPosition = {
    LEFT_TOP: 'lt',
    LEFT_CENTER: 'lc',
    LEFT_BOTTOM: 'lb',
    RIGHT_TOP: 'rt',
    RIGHT_CENTER: 'rc',
    RIGHT_BOTTOM: 'rb',
    CENTER_TOP: 'ct',
    CENTER_CENTER: 'cc',
    CENTER_BOTTOM: 'cb'
};

export { PanelPosition, Panel };