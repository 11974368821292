import { GeoTool } from '../core/GeoTool';

class EditGeom extends GeoTool {

    constructor(config) {
      config.source = config.source || new ol.source.Vector();
      config.tip = config.tip || 'Editar geometria';
      config.class = config.class || 'gb-edit-geom';
      super(config);
      this._source = config.source;
      this._feature = config.feature || '';
      this._modify = null;
    }

    initialize() { };

    activate() {
        super.activate();
        this._modify = new ol.interaction.Modify({ 
            source: this._source,
            deleteCondition: function (event) {
                var isClick = event.originalEvent.type === 'pointerdown';
                var isCtrl = event.originalEvent.ctrlKey;

                if (isClick && isCtrl) {
                    return ol.events.condition.always(event);
                } else {
                    return ol.events.condition.never(event);
                }
            }
        });
        this.map.ol.addInteraction(this._modify);
    }

    deactivate() {

      this.map.ol.removeInteraction(this._modify);
      this._modify = null;

      super.deactivate();
    }
}

export { EditGeom };