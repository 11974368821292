import { GeoWidget } from '../core/GeoWidget';

class GeoDesc extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.hasUI = false;
        config.position = config.position || 'cb';
        super(config);

        this._hasUI = false;
        this.format = new ol.format.GeoJSON();

    }

    initialize() {

        this._registerExternalInteraction();

    }

    _registerExternalInteraction() {

        this.map.geodesc = {};
        this.map.geodesc.pointDescriptor = (g) => this._pointsDescriptor(g);
        this.map.geodesc.lineDescriptor = (o) => this._linesDescriptor(o);
        this.map.geodesc.polygonDescriptor = (o) => this._polygonDescriptor(o);
        this.map.geodesc.azimuthDescriptor = (o) => this._azimuthsDescriptor(o);

    }

    _pointsDescriptor(opt) {

        return this._pointDescriptor(opt);

    }

    _linesDescriptor(opt) {

        return this._lineDescriptor(opt);

    }

    _azimuthsDescriptor(opt) {


        return this._azimuthDescriptor(opt);



    }

    _lineDescriptor(opt) {

        let { features, labelClickCb, tolerance } = opt;
        tolerance = tolerance || 0;
        let mapProj = this.map.ol.getView().getProjection().getCode();
        let allOverlays = [];

        if (features) {

            for (let i = 0; i < features.length; i++) {

                let overlaysCollection = opt.features[i].get('overlays') || [];

                let segmentCount = 0;

                let jfeatures = this.format.writeFeatureObject(features[i], {
                    dataProjection: 'EPSG:4326',
                    featureProjection: mapProj
                })

                turf.segmentEach(jfeatures, (currentSegment, featureIndex, multiFeatureIndex, geometryIndex, segmentIndex) => {

                    segmentCount++;
                    let dist = turf.length(currentSegment, { units: 'meters' });


                    if (dist > tolerance) {

                        let lineLabel = document.createElement('div');
                        if (labelClickCb) {
                            lineLabel.addEventListener('click', labelClickCb);
                        }

                        let label = overlaysCollection[featureIndex + '' + segmentIndex];

                        if (!label) {

                            label = new ol.Overlay({
                                element: lineLabel,
                                offset: [2, 0],
                                positioning: 'top-center'
                            });

                            this.map.ol.addOverlay(label);
                            overlaysCollection[featureIndex + '' + segmentIndex] = label;

                        }

                        let lineLength = turf.length(currentSegment);
                        let labelLength = lineLength / 2;
                        let labelPoint = turf.along(currentSegment, labelLength);
                        //let labelPoint = turf.explode(currentSegment).features[0];

                        let points = turf.explode(currentSegment).features;
                        let bearing = turf.bearing(points[0].geometry, points[1].geometry);
                        let rotation = bearing <= 0 ? bearing + 90 : bearing - 90;

                        let values = this._stUnits(lineLength, 'km', 'linear');
                        lineLabel.className = 'gb-desc-line';
                        lineLabel.innerHTML = values.defaultValue;
                        lineLabel.setAttribute('alternativeValue', values.alternativeValue);
                        lineLabel.setAttribute('defaultValue', values.defaultValue);
                        lineLabel.setAttribute('valueType', 'defaultValue');
                        lineLabel.style.transform = `rotate(${rotation}deg)`;
                        this._makeDraggable(lineLabel);
                        lineLabel.addEventListener('dblclick', () => {

                            let valueType = lineLabel.getAttribute('valueType') == 'defaultValue' ? 'alternativeValue' : 'defaultValue';
                            let value = lineLabel.getAttribute(valueType);
                            lineLabel.innerHTML = value;
                            lineLabel.setAttribute('valueType', valueType);

                        });

                        let olPointLabel = this.format.readGeometry(labelPoint.geometry);
                        olPointLabel.transform('EPSG:4326', mapProj);

                        label.setElement(lineLabel);
                        label.setPosition(olPointLabel.getCoordinates());

                    } else {

                        let label = overlaysCollection[featureIndex + '' + segmentIndex];
                        if (label) {
                            label.setPosition([Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]);
                        }
                    }

                });

                if (segmentCount > 1) {

                    turf.featureEach(jfeatures, (currentFeature, featureIndex) => {


                        let lineLabel = document.createElement('div');
                        if (labelClickCb) {
                            lineLabel.addEventListener('click', labelClickCb);
                        }
                        let label = overlaysCollection[featureIndex + 't'];

                        if (!label) {

                            label = new ol.Overlay({
                                element: lineLabel,
                                offset: [-2, 0],
                                positioning: 'bottom-center'
                            });

                            this.map.ol.addOverlay(label);
                            overlaysCollection[featureIndex + 't'] = label;

                        }

                        let lineLength = turf.length(currentFeature);
                        let labelLength = lineLength / 2;
                        let labelPoint = turf.along(currentFeature, labelLength);

                        let snapped = turf.nearestPointOnLine(currentFeature, labelPoint, { units: 'miles' });
                        let rotation = 0;

                        turf.segmentEach(currentFeature, (currentSegment, featureIndex, multiFeatureIndex, geometryIndex, segmentIndex) => {

                            if (segmentIndex == snapped.properties.index) {

                                let points = turf.explode(currentSegment).features;
                                let bearing = turf.bearing(points[0].geometry, points[1].geometry);
                                rotation = bearing <= 0 ? bearing + 90 : bearing - 90;

                            }

                        });

                        let values = this._stUnits(lineLength, 'km', 'linear');
                        lineLabel.className = 'gb-desc-line';
                        lineLabel.innerHTML = 'Total: ' + values.defaultValue;
                        lineLabel.setAttribute('alternativeValue', values.alternativeValue);
                        lineLabel.setAttribute('defaultValue', values.defaultValue);
                        lineLabel.setAttribute('valueType', 'defaultValue');
                        lineLabel.style.transform = `rotate(${rotation}deg)`;
                        this._makeDraggable(lineLabel);
                        lineLabel.addEventListener('dblclick', () => {

                            let valueType = lineLabel.getAttribute('valueType') == 'defaultValue' ? 'alternativeValue' : 'defaultValue';
                            let value = lineLabel.getAttribute(valueType);
                            lineLabel.innerHTML = 'Total: ' + value;
                            lineLabel.setAttribute('valueType', valueType);

                        });

                        let olPointLabel = this.format.readGeometry(labelPoint.geometry);
                        olPointLabel.transform('EPSG:4326', mapProj);

                        label.setElement(lineLabel);
                        label.setPosition(olPointLabel.getCoordinates());

                    });

                }

                opt.features[i].set('overlays', overlaysCollection);
                allOverlays = allOverlays.concat(Object.values(overlaysCollection));

            }

            return allOverlays;

        }

    }

    _azimuthDescriptor(opt) {

        let { features, labelClickCb } = opt;
        let mapProj = this.map.ol.getView().getProjection().getCode();
        let allOverlays = [];

        if (features) {

            for (let i = 0; i < features.length; i++) {

                let overlaysCollection = opt.features[i].get('overlays') || [];

                let segmentCount = 0;

                let jfeatures = this.format.writeFeatureObject(opt.features[i], {
                    dataProjection: 'EPSG:4326',
                    featureProjection: mapProj
                })

                turf.segmentEach(jfeatures, (currentSegment, featureIndex, multiFeatureIndex, geometryIndex, segmentIndex) => {

                    segmentCount++;

                    if (turf.length(currentSegment, { units: 'meters' }) != 0) {

                        let lineLabel = document.createElement('div');
                        if (labelClickCb) {
                            lineLabel.addEventListener('click', labelClickCb);
                        }
                        let label = overlaysCollection[featureIndex + '' + segmentIndex];

                        if (!label) {

                            label = new ol.Overlay({
                                element: lineLabel,
                                offset: [2, 0],
                                positioning: 'top-center'
                            });

                            this.map.ol.addOverlay(label);
                            overlaysCollection[featureIndex + '' + segmentIndex] = label;

                        }

                        let lineLength = turf.length(currentSegment);
                        let labelLength = lineLength / 2;
                        let labelPoint = turf.along(currentSegment, labelLength);

                        let points = turf.explode(currentSegment).features;
                        let bearing = turf.bearing(points[0].geometry, points[1].geometry);
                        let rotation = bearing <= 0 ? bearing + 90 : bearing - 90;

                        lineLabel.className = 'gb-desc-line';
                        lineLabel.innerHTML = 'Az:' + this._localeFormat(this._getAzimuth(currentSegment, 'dms'));
                        lineLabel.style.transform = `rotate(${rotation}deg)`;
                        this._makeDraggable(lineLabel);

                        let olPointLabel = this.format.readGeometry(labelPoint.geometry);
                        olPointLabel.transform('EPSG:4326', mapProj);

                        label.setElement(lineLabel);
                        label.setPosition(olPointLabel.getCoordinates());

                    }

                });

                opt.features[i].set('overlays', overlaysCollection);
                allOverlays = allOverlays.concat(Object.values(overlaysCollection));
            }

            return allOverlays;

        }

    }

    _polygonDescriptor(opt) {


        let { features, labelClickCb } = opt;
        let mapProj = this.map.ol.getView().getProjection().getCode();
        let allOverlays = [];

        if (features) {

            for (let i = 0; i < features.length; i++) {

                let overlaysCollection = opt.features[i].get('overlays') || [];
                let segmentCount = 0;

                let jfeatures = this.format.writeFeatureObject(opt.features[i], {
                    dataProjection: 'EPSG:4326',
                    featureProjection: mapProj
                })

                turf.geomEach(jfeatures, (currentGeometry, featureIndex, featureProperties, featureBBox, featureId) => {

                    let area = turf.area(currentGeometry);
                    let labelPoint = turf.centroid(currentGeometry);

                    let polygonLabel = document.createElement('div');
                    if (labelClickCb) {
                        polygonLabel.addEventListener('click', labelClickCb);
                    }
                    let label = overlaysCollection[featureIndex];

                    if (!label) {

                        label = new ol.Overlay({
                            element: polygonLabel,
                            offset: [0, 0],
                            positioning: 'center-center'
                        });

                        this.map.ol.addOverlay(label);
                        overlaysCollection[featureIndex] = label;

                    }

                    let values = this._stUnits(area, 'm2', 'area');
                    polygonLabel.className = 'gb-desc-line';
                    polygonLabel.innerHTML = values.defaultValue;
                    polygonLabel.setAttribute('alternativeValue', values.alternativeValue);
                    polygonLabel.setAttribute('defaultValue', values.defaultValue);
                    polygonLabel.setAttribute('valueType', 'defaultValue');
                    this._makeDraggable(polygonLabel);
                    polygonLabel.addEventListener('dblclick', () => {

                        let valueType = polygonLabel.getAttribute('valueType') == 'defaultValue' ? 'alternativeValue' : 'defaultValue';
                        let value = polygonLabel.getAttribute(valueType);
                        polygonLabel.innerHTML = value;
                        polygonLabel.setAttribute('valueType', valueType);

                    });

                    let olPointLabel = this.format.readGeometry(labelPoint.geometry);
                    olPointLabel.transform('EPSG:4326', mapProj);

                    label.setElement(polygonLabel);
                    label.setPosition(olPointLabel.getCoordinates());

                });

                opt.features[i].set('overlays', overlaysCollection);
                allOverlays = allOverlays.concat(Object.values(overlaysCollection));

            }
        }

        return allOverlays;

    }

    _pointDescriptor(opt) {

        let { features, labelClickCb } = opt;
        let mapProj = this.map.ol.getView().getProjection().getCode();
        let allOverlays = [];
        let displayCoords;

        let currentProjection = this.map.currentProjection;

        if (features) {

            for (let i = 0; i < features.length; i++) {

                let overlaysCollection = opt.features[i].get('overlays') || [];
                let jfeatures = this.format.writeFeatureObject(features[i]);

                turf.coordEach(jfeatures, (currentCoord, coordIndex, featureIndex, multiFeatureIndex, geometryIndex) => {

                    if (currentProjection.type == 'lat-lng') {

                        if (currentProjection.format == 'DMS') {

                            displayCoords = ol.coordinate.toStringHDMS(currentCoord, this.map.config.precision);

                        } else {

                            displayCoords = currentCoord.map((e) => {
                                return Number(e.toFixed(8)).toString().padEnd(8, "000");;
                            });
                            displayCoords = displayCoords.reverse().join(' ');

                        }

                    } else {

                        displayCoords = currentCoord.map((e) => {
                            return Number(e.toFixed(this.map.config.precision)).toString().padEnd(3, "000");;
                        });
                        displayCoords = displayCoords.join(' ');

                    }

                    let pointLabel = document.createElement('div');
                    pointLabel.className = 'gb-desc-point';
                    pointLabel.innerHTML = this._localeFormat(displayCoords);
                    this._makeDraggable(pointLabel);

                    let label = overlaysCollection[featureIndex + '' + geometryIndex + '' + coordIndex];
                    if (!label) {

                        label = new ol.Overlay({
                            element: pointLabel,
                            offset: [4, 4],
                            positioning: 'top-left'
                        });

                        this.map.ol.addOverlay(label);
                        overlaysCollection[featureIndex + '' + geometryIndex + '' + coordIndex] = label;

                    }

                    label.setPosition(currentCoord);

                    opt.features[i].set('overlays', overlaysCollection);
                    allOverlays = allOverlays.concat(Object.values(overlaysCollection));

                });
            }
        }

        return allOverlays;

    }

    _stUnits(value, unit, type) {

        let conversionFactor, unitDestination, convertedValue, defaultValue, alternativeValue;

        if (type === 'linear') {

            unitDestination = this.map.config.linearUnits;

        } else if (type === 'area') {

            unitDestination = this.map.config.areaUnits;

        } else if (type === 'angular') {

            unitDestination = this.map.config.angularUnits;

        } else {

            throw 'Tipo de unidade inexistente.';

        }

        conversionFactor = GeoBuilder.UnitsCoversion[unit][unitDestination];
        value = value * conversionFactor;
        value = Number(value.toFixed(this.map.config.precision));


        if (type === 'linear') {

            conversionFactor = GeoBuilder.UnitsCoversion[unitDestination]['km'];
            convertedValue = value * conversionFactor;
            convertedValue = Number(convertedValue.toFixed(this.map.config.precision));

            if (value > 1000) {

                defaultValue = convertedValue + 'km';;
                alternativeValue = value + 'm';

            } else {

                alternativeValue = convertedValue + 'km';
                defaultValue = value + 'm';
            }

        } else if (type === 'area') {

            conversionFactor = GeoBuilder.UnitsCoversion[unitDestination]['km2'];
            convertedValue = value * conversionFactor;
            convertedValue = Number(convertedValue.toFixed(this.map.config.precision));

            if (value > 100000) {

                defaultValue = convertedValue + 'km²';
                alternativeValue = value + 'm²';

            } else {

                alternativeValue = convertedValue + 'km²';
                defaultValue = value + 'm²';

            }

        }

        defaultValue = this._localeFormat(defaultValue);
        alternativeValue = this._localeFormat(alternativeValue);

        return { defaultValue, alternativeValue };

    }

    _localeFormat(value) {

        return value.replace(/,/g, '__COMMA__').replace(/\./g, ',').replace(/__COMMA__/g, '.');

    }

    _convertToDms(dd) {

        var absDd = Math.abs(dd);
        var deg = absDd | 0;
        var frac = absDd - deg;
        var min = (frac * 60) | 0;
        var sec = frac * 3600 - min * 60;
        // Round it to 2 decimal points.
        sec = Math.round(sec * 100) / 100;
        return deg + '°' + min + '\'' + sec + '"';
    }

    _getAzimuth(turfLine) {

        let turfLinesCollection = turf.lineSegment(turfLine);

        for (let i = 0; i < turfLinesCollection.features.length; i++) {

            let line = turfLinesCollection.features[i].geometry;
            let points = turf.explode(line).features;
            let bearing = turf.bearing(points[0].geometry, points[1].geometry);
            let azimuth = turf.bearingToAzimuth(bearing);

            if (this.map.config.angularUnits === 'dms') {
                return this._convertToDms(azimuth);
            } else {
                return azimuth;
            }

        }

    }

    _makeDraggable(element) {

        //let element = document.getElementById(this._elementId);
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        //if (document.getElementById(this._headerId)) {
        // if present, the header is where you move the DIV from:
        // document.getElementById(this._headerId).onmousedown = dragMouseDown;
        //} else {
        // otherwise, move the DIV from anywhere inside the DIV: 
        element.onmousedown = dragMouseDown;
        // }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            element.style.top = (element.offsetTop - pos2) + 'px';
            element.style.left = (element.offsetLeft - pos1) + 'px';
            element.style.opacity = 0.5;
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
            element.style.opacity = 1;
            //this._autoRepositioning();
        }
    }

}

export { GeoDesc };