import { GeoWidget } from '../core/GeoWidget';
import csv2geojson from '../assets/csv2geojson.js';

class GeomFromText extends GeoWidget {

    constructor(config) {
        config = config || {};
        config.tip = config.tip || 'Carregar CSV';
        config.title = config.title || 'Carregar CSV';
        config.class = config.class || 'gb-csv-control';
        config.docked = config.docked || true;
        config.minWidth = config.minWidth || '400';
        config.minHeight = config.minHeight || '400';
        config.maxWidth = config.maxWidth || '400';
        config.maxHeight = config.maxHeight || '600';
        super(config);
        this.ui = document.createElement('div');
        this._selectedFileInfo = {};
        this._layers = []; /*
        {
            id,
            title,
            olLayer
        }
        */

    }

    initialize() {

        this.ui.innerHTML = this._getUiTemplate();

        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();

        });

    };

    _handleInitList() {

        this._createList();

    }

    _createList() {

        if (this._layers.length == 0) {

            this._divListImages.innerHTML = 'Nenhum arquivo carregado.';
            return;

        }

        this._divListImages.innerHTML = '';

        const node = document.createElement('div');
        node.style.display = 'flex';
        node.style.flex = '1';

        for (let index = 0; index < this._layers.length; index++) {
            const node = document.createElement('div');
            node.className = 'list-item-geo-image';

            const btnExibir = document.createElement('button');
            btnExibir.textContent = 'Exibir';
            btnExibir.setAttribute('idItem', this._layers[index].id);
            btnExibir.addEventListener('click', (evt) => this._handleWithShow(evt));

            const btnRemover = document.createElement('button');
            btnRemover.textContent = 'Excluir';
            btnRemover.setAttribute('remove', true);
            btnRemover.setAttribute('idItem', this._layers[index].id);
            btnRemover.addEventListener('click', (evt) => this._handleWithRemove(evt));

            const pItem = document.createElement('p');
            pItem.textContent = this._layers[index].title;

            node.appendChild(pItem);
            node.appendChild(btnExibir);
            node.appendChild(btnRemover);

            this._divListImages.appendChild(node);
        }
    }

    _handleWithRemove(evt) {

        const id = evt.target.getAttribute('idItem');

        let msg = new MessageBox({
            title: 'Excluir arquivo',
            message: 'Deseja excluir o arquivo?',
            type: 'success',
            closable: false,
            buttons: {
                no: {
                    text: 'Sim',
                    fnc: () => {
                        this._removeLayer(id);
                        msg.hide();
                    }
                },
                yes: {
                    text: 'Não',
                    fnc: () => {
                        msg.hide();
                    }
                }
            }
        });

        msg.show();

    }

    _removeLayer(id) {

        //GET geo_images/delete_geo_image
        const idIndex = this._layers.findIndex(geo => geo.id === id);
        this.map.ol.removeLayer(this._layers[idIndex].olLayer);
        this._layers.splice(idIndex, 1);
        this._createList();

    }

    _handleWithShow(evt) {

        const id = evt.target.getAttribute('idItem');
        const layer = this._layers.find(l => l.id === id);

        if (!layer) {
            new Notify({ message: 'Erro ao exibir os dados.', type: 'error', timeout: 5 }).show();
            return;
        }

        this.map.ol.removeLayer(this._activeLayer.olLayer);
        this._activeLayer = layer;
        this.map.ol.addLayer(this._activeLayer.olLayer);

        this._fit(this._activeLayer.olLayer.getSource().getExtent());

    }

    _fit(extent) {

        try {
            const x = (extent[0] + extent[2]) / 2;
            const y = (extent[1] + extent[3]) / 2;
            this.map.ol.getView().setCenter([x, y]);
        } catch (e) { }

    }

    _getUiTemplate() {
        return `
      <div id="gb-list-section-${this.id}" style="min-width: 360px">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 5px 20px">
          
          <p style="margin-top: 10px; align-self: flex-start; font-weight: 600;">Arquivos carregados:</p>
          <div 
            style="display: flex; flex-direction: column; align-items: center; flex: 1; margin-top: 10px; width: 100%;"
            id="gb-list-images-${this.id}"
          >
            Nenhum arquivo disponível
          </div>
          
          <div class="row" style="width: 100%">
            <div class="col-5">

              <div class="form-group row">
                <label for="gb-input-opacity-${this.id}" class="col-sm-2 col-form-label">Opacidade</label>
                <div class="col-sm-10">

                  <input 
                  id="gb-input-opacity-${this.id}"
                  type="number"
                  min="0"
                  max="1"
                  step="0.2"
                  value="1"
                  class="form-control" 
                  />
                  
                  </div>
              </div>
              
              
            </div>
            <div class="col-7">
              <br>
              <button id="gb-add-new-${this.id}" class="btn btn-block btn-primary float-right">
                Adicionar
              </button>
            </div>
          </div>
          
        </div>
      </div>
      
      <div style="display: none;" id="gb-store-section-${this.id}">
        <div class='m-0 p-3 d-flex flex-column'>
          <p>Selecione o arquivo</p>
          <div class="input-group mb-3">
            <div class="custom-file">
              <input 
                type="file" 
                class="custom-file-input" 
                id="gb-input-file-${this.id}"
                accept=".csv, .tsv, .txt"
              >
              <label class="custom-file-label" for="gb-input-file-${this.id}">
                Escolher Arquivo
              </label>
            </div>
          </div>
          <p style="display: flex">Nome</p>
          <div style="display: flex; margin: 5px 0">
            <input id="gb-input-name-${this.id}" class="form-control" />
          </div>
          <br />
          <p>Sistema de referência</p>
          <div style="display: flex;">
            <select name="coords-system" id="gb-select-coords-${this.id}" class="form-control">
              ${this.map.config.srs.map(sr => (
            `<option value="${sr.code}">${sr.name}</option>`
        ))}
              <option value="outro">Outro</option>
            </select>
            <input id="gb-input-coord-${this.id}" disabled value="31982" style="width: 120px;" class="form-control" />
          </div>
          <br />
          <p>Atributos do arquivo</p>
          <div>
            <div style="display: flex; margin: 5px 0">
              <span style="padding: 7px 0; width: 85px; display: flex">Coord. X</span>
              <select id="gb-input-x-${this.id}" class="form-control" ></select>
            </div>
            <div style="display: flex; margin: 5px 0">
              <span style="padding: 7px 0; width: 85px; display: flex">Coord. Y</span>
              <select id="gb-input-y-${this.id}" class="form-control" ></select>
            </div>
            <div style="display: none; margin: 5px 0">
              <span style="padding: 7px 0; width: 85px; display: flex">Label</span>
              <select id="gb-input-label-${this.id}" class="form-control" ></select>
            </div>
          </div>
          <!--<div style="display: flex; margin: 5px 0">
            <span style="width: 85px; display: flex">Opacidade</span>
            <input 
              id="gb-input-opacity-${this.id}"
              type="number"
              min="0"
              max="1"
              step="0.2"
              value="1"
              style="width: 90px;" 
              class="form-control" 
            />
            
          </div>-->
          <br />
          <div class="row">
            <div class="col-4">
              <button id="gb-btn-cancel-${this.id}" class="btn btn-block">Cancelar</button>
            </div>
            <div class="col-8">
              <button id="gb-btn-import-${this.id}" class="btn btn-primary btn-block">Importar dados</button>
            </div>
          </div>
        </div>
      </div>
    `;
    }

    _registerElements() {

        this._divStoreSection = document.getElementById(`gb-store-section-${this.id}`);
        this._divListSection = document.getElementById(`gb-list-section-${this.id}`);
        this._divListImages = document.getElementById(`gb-list-images-${this.id}`);
        this._inputFile = document.getElementById(`gb-input-file-${this.id}`);
        this._selectCoordsSystem = document.getElementById(`gb-select-coords-${this.id}`);
        this._inputCoordsSystemValue = document.getElementById(`gb-input-coord-${this.id}`);
        this._inputOpacity = document.getElementById(`gb-input-opacity-${this.id}`);
        this._inputName = document.getElementById(`gb-input-name-${this.id}`);
        this._btnIport = document.getElementById(`gb-btn-import-${this.id}`);
        this._btnCancel = document.getElementById(`gb-btn-cancel-${this.id}`);
        this._btnAddNew = document.getElementById(`gb-add-new-${this.id}`);
        this._inputCoordX = document.getElementById(`gb-input-x-${this.id}`);
        this._inputCoordY = document.getElementById(`gb-input-y-${this.id}`);
        this._inputLabel = document.getElementById(`gb-input-label-${this.id}`);

        this._inputCoordX.disabled = true;
        this._inputCoordY.disabled = true;
        this._inputLabel.disabled = true;



    }

    _setDefaultValuesUi() {

        let mapSrid = this.map.ol.getView().getProjection().getCode();
        this._selectCoordsSystem.value = mapSrid;
        this._inputCoordsSystemValue.value = mapSrid;

    }

    _registerEvents() {

        this._inputFile.addEventListener('change', (evt) => this._onInputFileChanged(evt));
        this._selectCoordsSystem.addEventListener('change', (evt) => this._handleSelectCoordsChange(evt));
        this._inputOpacity.addEventListener('change', (evt) => this._handleWithOpacity());
        this._btnIport.addEventListener('click', (evt) => this._handleImportFile(evt));
        this._btnAddNew.addEventListener('click', () => this._handleShowView('store'));
        this._btnCancel.addEventListener('click', () => this._handleShowView('list'));

    }

    _handleShowView(view) {

        if (view === 'store') {

            this._divListSection.style.display = 'none';
            this._divStoreSection.style.display = 'block';

        } else {

            this._divListSection.style.display = 'block';
            this._divStoreSection.style.display = 'none';

        }

    }

    _handleWithOpacity() {

        if (this._layers.length == 0) return;
        const opacity = parseFloat(this._inputOpacity.value);
        this._activeLayer.olLayer.setOpacity(opacity);

    }

    _handleSelectCoordsChange(evt) {

        const valueSelected = evt.target.selectedOptions[0].value;

        if (valueSelected === 'outro') {

            this._inputCoordsSystemValue.disabled = false;
            this._inputCoordsSystemValue.value = '';

        } else {

            this._inputCoordsSystemValue.disabled = true;
            this._inputCoordsSystemValue.value = valueSelected;

        }

    }

    _validateForm() {

        const wkid = this._inputCoordsSystemValue.value;
        const name = this._inputName.value;

        return (
            this._selectedFileInfo.isValid &&
            !isNaN(wkid) &&
            name !== ''
        )
    }

    _onInputFileChanged(evt) {

        if (evt.target.files.length <= 0) return;

        this._getSelectedFileBasicInfo(evt);

        if (!this._selectedFileInfo.isValid) {
            new MessageBox({ title: 'Erro', message: `Arquivo não suportado.`, type: 'danger', timeout: 5 }).show();
            return;
        }

        this._getSelectedFileAdditionalInfo()
            .then(() => {
                this._bindUiValues(evt)
            })
            .catch((e) => {
                new MessageBox({ title: 'Erro', message: `Erro ao manipular o arquivo. ${e}`, type: 'danger', timeout: 5 }).show();
            });

    }

    _bindUiValues(evt) {

        let latRegex = /(Lat)(itude)?|y/gi,
            lonRegex = /(L)(on|ng)(gitude)?|x/gi,
            latField, lonField;

        let name = evt.srcElement.value.split('\\');
        evt.target.labels[0].innerText = evt.srcElement.value;
        this._inputName.value = name[name.length - 1];

        let options = '<option value="false">Selecione...</option>';
        for (let i = 0; i < this._selectedFileInfo.header.length; i++) {

            const field = this._selectedFileInfo.header[i];
            options += `<option value="${field}">${field}</option>`;
            latField = latField || field.match(latRegex);
            lonField = lonField || field.match(lonRegex);

        }

        this._inputCoordX.innerHTML = options;
        this._inputCoordY.innerHTML = options;

        this._inputCoordX.disabled = false;
        this._inputCoordY.disabled = false;

        if (this._selectedFileInfo.header.length > 2) {
            this._inputLabel.innerHTML = options;
            this._inputLabel.disabled = false;
        }

        if (latField && latField.length > 0) { this._inputCoordY.value = latField[0] };
        if (lonField && lonField.length > 0) this._inputCoordX.value = lonField[0];

    }

    _getSelectedFileBasicInfo(evt) {

        const file = evt.target.files[0];
        this._selectedFileInfo.isValid = false;

        const indexOfPoint = file.name.lastIndexOf(".");
        if (indexOfPoint != -1) {

            const fileExtension = file.name.substr(indexOfPoint + 1).toUpperCase();
            if (fileExtension === "CSV" || fileExtension === "TSV" || fileExtension === "TXT") {

                this._selectedFileInfo.fileObject = file;
                this._selectedFileInfo.filename = file.name;
                this._selectedFileInfo.isValid = true;

            }
        }
    }

    _getSelectedFileAdditionalInfo() {

        return new Promise((resolve, reject) => {

            const fileReader = new FileReader();
            fileReader.readAsText(this._selectedFileInfo.fileObject);

            fileReader.addEventListener('load', (e) => {

                if (e.target.result) {

                    this._selectedFileInfo.data = e.target.result;
                    let header = Object.keys(csv2geojson.auto(e.target.result)[0]);
                    if (header.length > 1) {
                        this._selectedFileInfo.header = header;
                        resolve();
                    } else {
                        reject('Arquivo mal formatado.');
                    }

                } else {
                    reject('Não foi possível ler o conteúdo do arquivo.');
                }



            });

            //fileReader.addEventListener('error', reject);


        })

    }

    _handleImportFile(evt) {

        let xField = this._inputCoordX.value,
            yField = this._inputCoordY.value,
            label = this._inputLabel.value,
            opacity = this._inputOpacity.value,
            srid = this._inputCoordsSystemValue.value,
            title = this._inputName.value,
            geoJson, gFormat, features, olLayer, activeLayer;

        try {
            opacity = Number(opacity);
        } catch (e) {
            opacity = 1;
        }

        geoJson = csv2geojson.csv2geojson(this._selectedFileInfo.data, {
            latfield: yField,
            lonfield: xField,
            delimiter: 'auto'
        }, (err, data) => {

            if (err) {
                new MessageBox({ title: 'Erro', message: `Erro ao manipular o arquivo.`, type: 'danger', timeout: 5 }).show();
                return;
            }

            if (this._activeLayer) {
                this.map.ol.removeLayer(this._activeLayer.olLayer);
            }

            gFormat = new ol.format.GeoJSON();
            features = gFormat.readFeaturesFromObject(data, {
                dataProjection: `${srid}`,
                featureProjection: this.map.ol.getView().getProjection().getCode()
            });

            olLayer = new ol.layer.Vector({
                source: new ol.source.Vector(),
                style: new ol.style.Style({
                    fill: new ol.style.Fill({
                        color: 'rgba(0, 255, 255, 0.2)'
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#0ff',
                        width: 2
                    }),
                    image: new ol.style.Circle({
                        radius: 7,
                        fill: new ol.style.Fill({
                            color: '#0ff'
                        })
                    })
                })
            });

            olLayer.setZIndex(999);
            olLayer.setOpacity(opacity);
            olLayer.getSource().addFeatures(features);
            this.map.ol.addLayer(olLayer);
            this._fit(olLayer.getSource().getExtent());

            activeLayer = {
                id: `layer_${this._layers.length}`,
                title,
                olLayer
            };

            this._activeLayer = activeLayer;
            this._layers.push(activeLayer);

            this._handleShowView('list');
            this._initializeInfos();

        });


    }

    _initializeInfos() {
        this._selectedFileInfo = {
            isValid: false,
            filename: "",
            fileObject: null,
            tiffImageObject: null,
            imageType: "",
            base64Url: "",
            dimensions: {
                width: 0,
                height: 0,
            },
        };

        this._inputName.value = '';
        this._inputFile.value = null;
        this._inputFile.labels[0].innerText = 'Escolher Arquivo';
        this._inputOpacity.value = 1;
        this._inputCoordX.innerHTML = '';
        this._inputCoordY.innerHTML = '';
        this._inputLabel.innerHTML = '';
        this._inputCoordX.disabled = true;
        this._inputCoordY.disabled = true;
        this._inputLabel.disabled = true;

        this._handleInitList();
    }

    activate() {
        this.show();
        this._handleInitList();
        this._setDefaultValuesUi();
    }

    deactivate() {
        //this.map.ol.removeLayer(this._mapImageLayer);
        //this._initializeInfos();
        //this._mapImageLayer = null;
        this.hide();
    }
}

export { GeomFromText };
