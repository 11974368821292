import { GeoTool } from '../core/GeoTool';

class SimpleDraft extends GeoTool {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Rascunho';
        config.title = config.title || 'Rascunho';
        config.class = config.class || 'gb-draft';
        super(config);

        this._layer = config.layer;
        this._draftType = config.draftType || 'line';
        this._overlays = [];

        this._arrow = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAF+SURBVFiFzde5TgMxEMbxvwMlgp4bREHD2QECInqehVDwSrQUFDSgEAQdHQ3XI3AFhY7sR8EusiKiOLu2NyNtZe/op5kpxjBoITQpNFu24y+E9oS+hY4HApaC3lPUh9CJ0EKZoKrQq5DSr1yY0G4HqFyY0E4XUDkwoe0eoLgwoS1HUByY0GafoLAwoQ2hlxwgG9b0BasAScEcQ8AosA/cFoVVABUE2bCxojCfIC+wEKBCMB8z5BUWskK5YDFBTrAYLXOB3QlVM1DsCmXRBprAKbBkMJcAwyWA2kALqANHBvNsH8YEtYEv4AY4MJin/y7FADlBbFCooRa/rbkGagbz6PJTiAplkKwiTpAQIBtSM5iHPEl8gLxAbFDeGbIhhwZzXwRiZdV8nytskm6IZ0KLXhAdoDnHFTaD1IVWvUMs0EyPCiVCn0KNoBALNN0FlKSviobQWnCIBZrqAGWQK6H1aBALNCH0ZrXmQmg5OsQCjaeYc6GV0iAWaCTKsDrGDyB3XaFxeFTeAAAAAElFTkSuQmCC';

    }

    initialize() { }

    _makeDraggable(element) {

        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

        // otherwise, move the DIV from anywhere inside the DIV: 
        element.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            element.style.top = (element.offsetTop - pos2) + 'px';
            element.style.left = (element.offsetLeft - pos1) + 'px';
            element.style.opacity = 0.5;
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
            element.style.opacity = 1;
        }
    }

    _toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    _setOverlays(point) {

        let coords = point.getGeometry();
        let pointLabel = document.createElement('div');
        let inputText = document.createElement('input');
        pointLabel.className = 'gb-desc-point';
        pointLabel.innerHTML = 'TEXTO';

        inputText.addEventListener('input', () => {
            inputText.size = inputText.value.length + 1;
        });

        this._makeDraggable(pointLabel);

        let label = new ol.Overlay({
            element: pointLabel,
            offset: [4, 4],
            positioning: 'top-left'
        });

        label.setPosition(coords.getCoordinates());
        this.map.ol.addOverlay(label);

        pointLabel.addEventListener('dblclick', () => {

            if (pointLabel.innerHTML.indexOf('<input') == -1) {

                let textTemp = pointLabel.innerHTML;
                inputText.value = textTemp;
                inputText.size = inputText.value.length + 1;;
                pointLabel.innerHTML = '';
                pointLabel.appendChild(inputText);
                inputText.focus();

                this.map.ol.once('click', () => {
                    if (pointLabel.firstElementChild) {
                        pointLabel.innerHTML = pointLabel.firstElementChild.value;
                    }
                });

            }

        });

        pointLabel.addEventListener('keydown', e => {
            if (e.which == 13) {
                pointLabel.innerHTML = pointLabel.firstElementChild.value;
            }
        });

        point.set('overlays', [label]);
        point.set('order', this._layer.getSource().getFeatures().length);
        point.setGeometry(null);
        this._layer.getSource().addFeature(point);
    }

    _clearDrafts() {

        return new Promise((resolve, reject) => {

            let features = this._layer.getSource().getFeatures();
            for (let i = 0; i < features.length; i++) {

                let f = features[i];
                let overlays = f.get('overlays');

                if (overlays) {

                    let keys = Object.keys(overlays);
                    keys.forEach(key => {

                        this.map.ol.removeOverlay(overlays[key])

                    });

                }

                this._layer.getSource().removeFeature(f);

            }

            this._overlays = [];

            new Notify({
                message: 'Todas as medidas foram removidas.',
                type: 'success',
                timeout: 5
            }).show();

            resolve();

        });

    }

    _interactionMeasures(primitive) {

        let drawInteraction = this.map.toolbox.draw.getInteraction();
        let ld = this.map.geodesc.lineDescriptor;
        let pd = this.map.geodesc.polygonDescriptor;
        let ad = this.map.geodesc.azimuthDescriptor;
        let overlaysCollection = [];

        drawInteraction.on('drawstart', (e) => {

            let sketch = e.feature;
            let feat = new ol.Feature();

            sketch.getGeometry().on('change', function (evt) {


                feat.setGeometry(evt.target.clone())
                let features = [feat];
                let labelClickCb = function () {
                    drawInteraction.finishDrawing();
                }
                let tolerance = 10;

                if (primitive == 'line') {

                    overlaysCollection = ld({ features, overlaysCollection, labelClickCb, tolerance })

                } else if (primitive == 'polygon') {

                    overlaysCollection = pd({ features, overlaysCollection, labelClickCb })

                } else if (primitive == 'azimuth') {

                    overlaysCollection = ad({ features, overlaysCollection, labelClickCb })

                }

            });

        });

        drawInteraction.on('drawend', (e) => {

            let keys = Object.keys(overlaysCollection);
            keys.forEach(key => {

                this.map.ol.removeOverlay(overlaysCollection[key])

            });

        });
    }


    _initDraft() {

        switch (this._draftType) {

            case 'line':

                this.map.toolbox.draw.getPolyline().then((line) => {

                    line.set('order', this._layer.getSource().getFeatures().length);
                    this._layer.getSource().addFeature(line);
                    this.deactivate();

                });

                this._interactionMeasures('line');

                break;

            case 'point':

                this.map.toolbox.draw.getPoint().then((point) => {

                    point.set('order', this._layer.getSource().getFeatures().length);
                    this._layer.getSource().addFeature(point);
                    this.deactivate();

                });

                break;

            case 'polygon':

                this.map.toolbox.draw.getPolygon().then((polygon) => {

                    polygon.set('order', this._layer.getSource().getFeatures().length);
                    this._layer.getSource().addFeature(polygon);
                    this.deactivate();

                });

                this._interactionMeasures('polygon');

                break;

            case 'text':

                this.map.toolbox.draw.getPoint().then((point) => {

                    this._setOverlays(point);
                    this.deactivate();

                });

                break;

            case 'arrow':

                const arrow = this._arrow;

                this.map.toolbox.draw.getArrow().then((pline) => {

                    let myStyles = [];

                    let plineTemp = pline.clone()

                    pline.getGeometry().forEachSegment(function (start, end) {
                        let dx = end[0] - start[0];
                        let dy = end[1] - start[1];
                        let rotation = Math.atan2(dy, dx);
                        // arrows
                        myStyles.push(new ol.style.Style({
                            geometry: new ol.geom.Point(end),
                            image: new ol.style.Icon({
                                src: arrow,
                                anchor: [0.75, 0.5],
                                rotateWithView: true,
                                rotation: -rotation
                            }),
                            stroke: new ol.style.Stroke({
                                color: '#685ff1',
                                width: 150
                            }),
                        }));
                    });

                    pline.setStyle(myStyles);

                    pline.set('order', this._layer.getSource().getFeatures().length);
                    plineTemp.set('order', this._layer.getSource().getFeatures().length);

                    this._layer.getSource().addFeature(pline);
                    this._layer.getSource().addFeature(plineTemp);

                    this.deactivate();

                });

                break;

            case 'image':

                let input = document.createElement('input');
                input.type = 'file';
                input.setAttribute('accept', '.jpg, .png, .jpeg, .gif |image/*');
                let imageFile = null;

                input.onchange = e => {
                    imageFile = e.target.files[0];

                    if (imageFile) {

                        this._toBase64(imageFile).then((image) => {

                            this._tipMsg = 'Clique no mapa para adicionar um texto';
                            this.map.toolbox.draw.getPoint().then((point) => {

                                let myStyle = new ol.style.Style({
                                    geometry: point.getGeometry(),
                                    image: new ol.style.Icon({
                                        src: image,
                                        anchor: [0.5, 0.5]
                                    })
                                });

                                point.setStyle(myStyle);
                                point.set('order', this._layer.getSource().getFeatures().length);
                                // this._tipMsg = this._initialMsg;
                                // this._textContainer.innerHTML = this._tipMsg;
                                this._layer.getSource().addFeature(point);

                                this.deactivate();
                            });

                        });
                    }
                }

                input.click();



                break;

            case 'clear':

                this._clearDrafts().then(() => this.deactivate());

                break;

            case 'undo':

                this._undo().then(() => this.deactivate());

                break;

        }

    }

    _undo() {

        return new Promise((resolve, reject) => {

            let count = this._layer.getSource().getFeatures().length;
            if (count > 0) {

                let maxOrder = 0;
                let features = this._layer.getSource().getFeatures();

                for (let i = 0; i < features.length; i++) {
                    const feature = features[i];
                    if (feature.get('order') > maxOrder) {
                        maxOrder = feature.get('order');
                    }
                }

                for (let i = 0; i < features.length; i++) {

                    const feature = features[i];
                    if (feature.get('order') == maxOrder) {

                        let overlays = feature.get('overlays');
                        console.log(feature.get('order'));

                        if (overlays) {

                            let keys = Object.keys(overlays);
                            keys.forEach(key => {
                                this.map.ol.removeOverlay(overlays[key])
                            });

                        }

                        this._layer.getSource().removeFeature(feature);

                    }

                }

            } else {

                new Notify({
                    message: 'Todas as medidas foram removidas.',
                    type: 'success',
                    timeout: 5
                }).show();

            }

            resolve();

        })


    }

    activate() {

        super.activate();
        this._initDraft();

    }

    deactivate() {

        this.map.toolbox.draw.stopDrawing();
        super.deactivate();
    }

}

export { SimpleDraft };
