import { GeoWidget } from '../core/GeoWidget';

class Draw extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.hasUI = false;
        config.position = config.position || 'ct';
        super(config);

        this._panelElement = null;
        this._hasUI = false;
        this._layer = null;
        this._geomType = 'Point';
        this._geomFunc = null;
        this._drawing = false;
        this._options = null;


    }

    initialize() {

        //this._initInterface();
        this._initStyles();
        this._initLayer();
        //this._addDrawInteraction();
        this._registerExternalInteraction();
    }

    _initInterface() {

        let panelElementId = this.map.getPanel(this._config.position).elementId;
        this._panelElement = document.getElementById(panelElementId);

        this.pointBtn = document.createElement('div');
        this.pointBtn.className = 'gb-control gb-draw-point';

        this.lineBtn = document.createElement('div');
        this.lineBtn.className = 'gb-control gb-draw-line';

        this.polygonBtn = document.createElement('div');
        this.polygonBtn.className = 'gb-control gb-draw-polygon';

        this.gapBtn = document.createElement('div');
        this.gapBtn.className = 'gb-control gb-draw-gap';

        this.bufferBtn = document.createElement('div');
        this.bufferBtn.className = 'gb-control gb-draw-buffer';

        // let input = document.createElement('input');
        // input.type = 'text';
        // input.addEventListener('keypress', (e) => {
        //     var key = e.which || e.keyCode;
        //     if (key === 13) { // 13 is enter
        //         this._setGeomType(input.value);

        //     }
        // });

        this._panelElement.appendChild(this.pointBtn);
        this._panelElement.appendChild(this.lineBtn);
        this._panelElement.appendChild(this.polygonBtn);
        this._panelElement.appendChild(this.gapBtn);
        this._panelElement.appendChild(this.bufferBtn);

    }

    _initStyles() {

        this._layerStyle = [
            new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#000',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#000'
                    })
                })
            }),
            new ol.style.Style({
                image: new ol.style.Circle({
                    radius: 5,
                    fill: new ol.style.Fill({
                        color: 'orange'
                    })
                }),
                geometry: function (feature) {
                    // return the coordinates of the first ring of the polygon
                    if (feature.getGeometry() instanceof ol.geom.Polygon) {
                        var coordinates = feature.getGeometry().getCoordinates()[0];
                        return new ol.geom.MultiPoint(coordinates);
                    }
                }
            })
        ];

        this._pointDrawStyle = new ol.style.RegularShape({
            fill: new ol.style.Fill({
                color: 'rgba(0, 255, 255, 0.5)'
            }),
            stroke: new ol.style.Stroke({
                color: 'cyan',
                width: 1.5
            }),
            points: 4,
            radius: 10,
            radius2: 0,
            angle: Math.PI / 4
        });

        this._drawStyle = new ol.style.Style({
            fill: new ol.style.Fill({
                color: 'rgba(0, 255, 255, 0.5)'
            }),
            stroke: new ol.style.Stroke({
                color: 'cyan',
                width: 1.5
            }),
            image: this._pointDrawStyle
        });


    }

    _initLayer() {

        this._layer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: this._layerStyle
        });

        this._layer.setZIndex(999);
        this.map.ol.addLayer(this._layer);

    }

    _addDrawInteraction() {

        document.getElementById(this.map.elementId).style.cursor = 'crosshair';

        this.draw = new ol.interaction.Draw({
            source: this._layer.getSource(),
            type: this._geomType,
            geometryFunction: this._geomFunc,
            style: this._drawStyle,
            stopClick: true,
            ...this._options,
            freehandCondition: function (event) {

                return ol.events.condition.never(event);

            }

        });

        this.map.ol.addInteraction(this.draw);
        this._options = null;

    }

    _addModifyInteraction() {

        this.modify = new ol.interaction.Modify({
            source: this._layer.getSource(),
            style: this._drawStyle,
            deleteCondition: function (event) {

                var isClick = event.originalEvent.type === 'pointerdown';
                var isCtrl = event.originalEvent.ctrlKey;

                if (isClick && isCtrl) {

                    return ol.events.condition.always(event);

                } else {

                    return ol.events.condition.never(event);

                }

            }

        });
        this.map.ol.addInteraction(this.modify);


        // this.select = new ol.interaction.Select({
        //     wrapX: false
        // });
        // this.map.ol.addInteraction(this.select);

    }

    _removeInteraction() {

        document.getElementById(this.map.elementId).style.cursor = 'auto';
        this.map.ol.removeInteraction(this.draw);
        this.draw = null;
        //this._geomFunc = null;
        //this._geomType = null;

    }

    _setGeomType(type) {

        switch (type.toLowerCase()) {
            case 'point':
            case 'ponto':
            case 'po':
                this._geomType = 'Point';
                this._geomFunc = null;
                break;
            case 'polyline':
            case 'polilinha':
            case 'pline':
                this._geomType = 'LineString';
                this._geomFunc = null;
                break;
            case 'polygon':
            case 'poligono':
            case 'pol':
                this._geomType = 'Polygon';
                this._geomFunc = null;
                break;
            case 'circle':
            case 'circulo':
            case 'c':
                this._geomType = 'Circle';
                this._geomFunc = null;
                break;
            case 'box':
                this._geomType = 'Circle';
                this._geomFunc = ol.interaction.Draw.createBox();
                break;
        }

        this._removeInteraction();
        this._addDrawInteraction();

        if (this.map.toolbox.hasSnap) {
            this.map.toolbox.refreshSnap();
        }


    }

    _registerExternalInteraction() {

        this.map.toolbox.draw = {};
        this.map.toolbox.draw.stopDrawing = () => this._stopDrawing();
        this.map.toolbox.draw.getArrow = () => this._getArrow();
        this.map.toolbox.draw.getBox = () => this._getBox();
        this.map.toolbox.draw.getPoint = () => this._getPoint();
        this.map.toolbox.draw.getPolyline = (evt) => this._getPolyline(evt);
        this.map.toolbox.draw.getPolygon = () => this._getPolygon();
        this.map.toolbox.draw.drawPolygon = () => this._drawPolygon();
        this.map.toolbox.draw.getCircle = () => this._getCircle();
        this.map.toolbox.draw.editFeature = (feature) => this._editFeature(feature);
        this.map.toolbox.draw.getInteraction = () => { return this.draw };

    }

    _stopDrawing() {

        this._removeInteraction();

    }

    _getCircle() {

        this._setGeomType('circle');

        return new Promise((resolve) => {


            this.draw.once('drawend', (evt) => {

                this._removeInteraction();
                resolve(evt.feature.clone());
                evt.feature.setStyle([]);

            });

        });

    }

    _getArrow() {

        this._setGeomType('pline');

        return new Promise((resolve) => {

            this.draw.once('drawend', (evt) => {

                this._removeInteraction();
                resolve(evt.feature.clone());
                evt.feature.setStyle([]);

            });

        });

    }

    _getBox() {

        var self = this;
        this._setGeomType('box');

        return new Promise((resolve) => {

            this.draw.once('drawend', (evt) => {

                evt.feature.setStyle([]);
                self._removeInteraction();
                self._geomType = null;
                resolve(evt.feature);

            });

        });

    }

    _getPoint() {

        var self = this;

        this._setGeomType('point');

        return new Promise((resolve) => {

            this.draw.once('drawend', (evt) => {

                self._removeInteraction();
                resolve(evt.feature.clone());
                evt.feature.setStyle([]);

            });

        });

    }

    _getPolyline(props) {

        this._options = props;

        this._setGeomType('pline');

        return new Promise((resolve) => {

            this.draw.once('drawend', (evt) => {

                this._removeInteraction();
                resolve(evt.feature.clone());
                evt.feature.setStyle([]);

            });

        });

    }

    _getPolygon() {

        this._setGeomType('pol');

        return new Promise((resolve) => {

            this.draw.once('drawend', (evt) => {

                this._removeInteraction();
                resolve(evt.feature.clone());
                evt.feature.setStyle([]);

            });

        });

    }

    _drawPolygon() {

        this._setGeomType('pol');

        this.draw.once('drawend', () => {

            this._removeInteraction();

        });
        this._addModifyInteraction();

    }

    _editFeature(feature) {

        this._layer.getSource().clear();
        this._layer.getSource().addFeature(feature);
        this._addModifyInteraction();
        document.getElementById(this.map.elementId).style.cursor = 'crosshair';

    }

    _getEditGeom(format) {

        let geom = null;

        if (format === 'geojson') {

            geom = (new ol.format.GeoJSON()).writeFeatures(this._layer.getSource().getFeatures());

        } else if (format === 'wkt') {

            geom = (new ol.format.WKT()).writeFeatures(this._layer.getSource().getFeatures());

        } else {

            throw (`Formato não suportado "${format}".`);

        }

        return geom;

    }


}

export { Draw };