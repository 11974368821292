import { GeoWidget } from '../core/GeoWidget';

class GuideLines extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'GuideLines';
        config.title = config.title || 'GuideLines';
        config.class = config.class || 'guide-lines';
        config.geometryType = 'linestring';
        config.defaultDistance = config.defaultDistance || 1;
        config.defaultUnits = config.defaultUnits || 'm';
        super(config);

        this._source = config.source || new ol.source.Vector();
        this._orthoPointFeature = null;
        this._feature1 = new ol.Feature();
        this._feature2 = new ol.Feature();
        this._orthoPointFeature = new ol.Feature();
        this._segment = new ol.Feature();
        this._selectClick = null;
        this.ui = this._getUiTemplate();

    }

    initialize() {

        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();
            this._initInternalLayer();
            this._initInteractions();

        });

    }

    _initInteractions() {

        this._selectClick = new ol.interaction.Select({
            condition: ol.events.condition.click,
            multi: false,
            hitTolerance: 5,
        });
        this._selectClick.on('select', (e) => this._getSegment(e));
        this._selectClick.setActive(false);
        this.map.ol.addInteraction(this._selectClick);

    }

    _initInternalLayer() {

        this._ortoLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });

        this._ortoPointLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });

        this._pointsLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });

        this._paralelLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });

        this._ortoLayer.setZIndex(9998);
        this._ortoPointLayer.setZIndex(9998);
        this._pointsLayer.setZIndex(9998);
        this._paralelLayer.setZIndex(9998);

        map.ol.addLayer(this._ortoLayer);
        map.ol.addLayer(this._ortoPointLayer);
        map.ol.addLayer(this._pointsLayer);
        map.ol.addLayer(this._paralelLayer);

    }

    _getUiTemplate() {

        return `
            <div class='m-0 p-3' style="min-width: 460px; max-width: 500px;">
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-orto-${this.id}">
                            <label class="form-check-label small" for="gb-orto-${this.id}">
                                Guias de 0, 45 e 90 graus
                            </label>
                         </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-orto-point-${this.id}">
                            <label class="form-check-label  small" for="gb-orto-point-${this.id}">
                                Guia de ponto ortogonal
                            </label>
                         </div>
                    </div>
                    <div class="col-4">
                        <a id="gb-point-btn-${this.id}" href="#" class="btn btn-primary btn-sm active float-right" role="button" >Obter Ponto</a>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-points-${this.id}">
                            <label class="form-check-label  small" for="gb-points-${this.id}">
                                Guia por dois pontos quaisquer
                            </label>
                         </div>
                    </div>
                    <div class="col-3">
                        <a id="gb-p1-btn-${this.id}" href="#" class="btn btn-primary btn-sm active float-right" role="button" >Obter P1</a>
                    </div>
                    <div class="col-3">
                        <a id="gb-p2-btn-${this.id}" href="#" class="btn btn-primary btn-sm active float-right" role="button" >Obter P2</a>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-segment-${this.id}">
                            <label class="form-check-label small" for="gb-segment-${this.id}">
                                Guia paralela a um segmento por distância
                            </label>
                         </div>
                    </div>
                   
                    <div class="col-8">
                        <div class="input-group input-group-sm mb-3">
                        <input id="gb-input-${this.id}" min="0.001" type="number" value="${this._config.defaultDistance}" class="form-control">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-sm">metros</span>
                        </div>
                        </div>
                    </div>

                    <div class="col-4">
                        <a id="gb-segment-btn-${this.id}" href="#" class="btn btn-primary btn-sm active float-right" role="button" >Obter Segmento</a>
                    </div>

                </div>

                <hr>

                <div class="row">
                    <div class="col-12">
                        <a id="gb-clear-btn-${this.id}" href="#" class="btn btn-primary btn-sm active btn-block" role="button" >Limpar linhas guias</a>
                    </div>
                    
                </div>
                
               
            </div>
        `;

    }

    _registerElements() {

        this._checkOrtoElement = document.getElementById(`gb-orto-${this.id}`);
        this._checkOrtoPointElement = document.getElementById(`gb-orto-point-${this.id}`);
        this._checkPointsElement = document.getElementById(`gb-points-${this.id}`);
        this._checkSegmentElement = document.getElementById(`gb-segment-${this.id}`);
        
        this._inputElement = document.getElementById(`gb-input-${this.id}`);

        this._btnOrtoPointElement = document.getElementById(`gb-point-btn-${this.id}`);
        this._btnP1Element = document.getElementById(`gb-p1-btn-${this.id}`);
        this._btnP2Element = document.getElementById(`gb-p2-btn-${this.id}`);
        this._btnSegmentElement = document.getElementById(`gb-segment-btn-${this.id}`);
        this._btnClearElement = document.getElementById(`gb-clear-btn-${this.id}`);

    }

    _ortoGuidesHandler() {

        this._ortoLayer.getSource().clear();

        if (this._checkOrtoElement.checked) {
            this._generateOrthoGuides();
        }

    }

    _ortoPointHandler() {

        this._ortoPointLayer.getSource().clear();

        if (this._checkOrtoPointElement.checked) {
            this._createOrthoPoint();
        }

    }

    _pointsHandler() {

        this._pointsLayer.getSource().clear();

        if (this._checkPointsElement.checked) {
            this._generatePointsGuide();
        }
    }
    _segmentHandler() {

        this._paralelLayer.getSource().clear();

        if (this._checkSegmentElement.checked) {
            this._generateParalelSegment();
        }

    }

    _generateParalelSegment() {


        if (this._segment.getGeometry()) {

            let extent = this.map.ol.getView().calculateExtent();
            let dist = this._plainDistance([extent[0], extent[1]], [extent[2], extent[3]]);

            let l1 = this._segment.getGeometry().getCoordinates().slice();
           
            let az = this._getAzimuth(l1[0], l1[1]);

            let pa = this._pointAzDist(l1[0], az + (Math.PI/2), this._config.defaultDistance);
            let pb = this._pointAzDist(l1[0], az - (Math.PI/2), this._config.defaultDistance);

            let p1 = this._pointAzDist(pa, az, dist);
            this._createOlLineFeature([pa, p1], this._paralelLayer.getSource());

            let p2 = this._pointAzDist(pa, az + Math.PI, dist);
            this._createOlLineFeature([pa, p2], this._paralelLayer.getSource());

            let p3 = this._pointAzDist(pb, az, dist);
            this._createOlLineFeature([pb, p3], this._paralelLayer.getSource());

            let p4 = this._pointAzDist(pb, az + Math.PI, dist);
            this._createOlLineFeature([pb, p4], this._paralelLayer.getSource());

        } 

    }

    _getSegment(e) {

        const feature = e.selected[0]
        const format = new ol.format.GeoJSON();

        if (feature) {

            feature.getGeometry().transform(this.map.srid, 'EPSG:4326');

            const clickPoint = turf.point(ol.proj.transform(e.mapBrowserEvent.coordinate, this.map.srid, 'EPSG:4326'));
            const polygon = format.writeFeatureObject(e.selected[0]);
            let minDist = Number.MAX_VALUE;

            turf.segmentEach(polygon, (segment) => {

                const dist = turf.pointToLineDistance(clickPoint, segment, { units: 'kilometers' });

                if (dist < minDist) {
                    minDist = dist;

                    let sFeature = format.readFeatureFromObject(segment);
                    sFeature.getGeometry().transform('EPSG:4326', this.map.srid);
                    this._segment.setGeometry(sFeature.getGeometry());

                }

            });

            feature.getGeometry().transform('EPSG:4326', this.map.srid);

        }

        this._selectClick.getFeatures().clear();
        this._selectClick.setActive(false);
        document.getElementById(this.map.elementId).style.cursor = 'auto';
        this._segmentHandler();

    }

    _generatePointsGuide() {

        if (this._feature1.getGeometry() && this._feature2.getGeometry()) {

            let extent = this.map.ol.getView().calculateExtent();
            let dist = this._plainDistance([extent[0], extent[1]], [extent[2], extent[3]]);

            let c1 = this._feature1.getGeometry().getCoordinates().slice();
            let c2 = this._feature2.getGeometry().getCoordinates().slice();

            let az = this._getAzimuth(c1, c2);

            let p1 = this._pointAzDist(c1, az, dist);
            this._createOlLineFeature([c1, p1], this._pointsLayer.getSource());

            let p2 = this._pointAzDist(c1, az + Math.PI, dist);
            this._createOlLineFeature([c1, p2], this._pointsLayer.getSource());

        }

    }

    _generateOrthoGuides() {

        let segments;
        let features = this._source.getFeatures();
        let extent = this.map.ol.getView().calculateExtent();


        for (let i = 0; i < features.length; i++) {

            let feature = features[i];

            if (feature.getGeometry()) {

                segments = turf.lineSegment(turf.lineString(feature.getGeometry().getCoordinates()[0]));

                for (let j = 0; j < segments.features.length; j++) {

                    let segment = segments.features[j].geometry.coordinates;

                    let az = this._getAzimuth(segment[0], segment[1]);
                    let dist = this._plainDistance([extent[0], extent[1]], [extent[2], extent[3]]);

                    for (let k = 1; k < 9; k++) {

                        let p1 = this._pointAzDist(segment[0], (az + k * (Math.PI / 4)), dist);
                        this._createOlLineFeature([segment[0], p1], this._ortoLayer.getSource());

                    }

                }

            }

        }

    }

    _getPointOrtho() {

        this.map.toolbox.draw.getPoint().then((point) => {

            this._orthoPointFeature = point;
            this._ortoPointHandler();

        });

    }

    _createOrthoPoint() {

        let line, point, ptOnLine;
        let features = this._source.getFeatures();
        let extent = this.map.ol.getView().calculateExtent();
        let dist = this._plainDistance([extent[0], extent[1]], [extent[2], extent[3]]);


        for (let i = 0; i < features.length; i++) {

            let feature = features[i];

            if (feature.getGeometry() && this._orthoPointFeature.getGeometry()) {

                let originalCoordFeature = feature.getGeometry().getCoordinates().slice();
                let originalCoordPoint = this._orthoPointFeature.getGeometry().getCoordinates().slice();

                feature.getGeometry().transform(this.map.srid, 'EPSG:4326');
                this._orthoPointFeature.getGeometry().transform(this.map.srid, 'EPSG:4326');

                line = turf.lineString(feature.getGeometry().getCoordinates()[0]);
                point = turf.point(this._orthoPointFeature.getGeometry().getCoordinates());

                var snapped = turf.nearestPointOnLine(line, point, { units: 'meters' });

                let index = snapped.properties.index;
                let c2 = originalCoordFeature[0][index + 1];
                let c1 = originalCoordFeature[0][index];

                console.log(snapped)

                snapped = snapped.geometry.coordinates;
                point = point.geometry.coordinates;

                let az = this._getAzimuth(c1, c2);

                let p1 = this._pointAzDist(originalCoordPoint, az + Math.PI / 2, dist);
                let p2 = this._pointAzDist(originalCoordPoint, az - Math.PI / 2, dist);
                this._createOlLineFeature([originalCoordPoint, p1], this._ortoPointLayer.getSource());
                this._createOlLineFeature([originalCoordPoint, p2], this._ortoPointLayer.getSource());

                feature.setGeometry(new ol.geom.Polygon(originalCoordFeature));
                this._orthoPointFeature.setGeometry(new ol.geom.Point(originalCoordPoint));

            }

        }


    }

    _createOlLineFeature(lineArray, source) {

        let feature = new ol.Feature();
        let line = new ol.geom.LineString(lineArray);
        feature.setGeometry(line);
        source.addFeature(feature);

    }

    _pointAzDist(firstPoint, az, dist) {

        let x = firstPoint[0] + Math.sin(az) * dist;
        let y = firstPoint[1] + Math.cos(az) * dist;

        return [x, y];

    }

    _plainDistance(p1, p2) {

        return Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));

    }

    _pointOnLine(segment, point) {

        let p1 = {
            x: segment[0][0],
            y: segment[0][1],
        };

        let p2 = {
            x: segment[1][0],
            y: segment[1][1],
        };

        let pa = {
            x: point[0],
            y: point[1]
        };

        return Math.abs(((p2.y - p1.y) / (p2.x - p1.x)) * (pa.x - p1.x) - (pa.y - p1.y)) < 10e-4;

    }

    _getAzimuth(p1, p2) {

        let azimuth = Math.atan2(p2[0] - p1[0], p2[1] - p1[1]);
        azimuth = azimuth < 0 ? azimuth + (2 * Math.PI) : azimuth;

        return azimuth;


    }

    _clear(){

        this._ortoLayer.getSource().clear();
        this._ortoPointLayer.getSource().clear();
        this._pointsLayer.getSource().clear();
        this._paralelLayer.getSource().clear();

        this._orthoPointFeature.setGeometry(null);
        this._feature1.setGeometry(null);
        this._feature2.setGeometry(null);
        this._orthoPointFeature.setGeometry(null);
        this._segment.setGeometry(null);

    }


    _registerEvents() {

        this._checkOrtoElement.addEventListener('click', () => this._ortoGuidesHandler());
        this._checkOrtoPointElement.addEventListener('click', () => this._ortoPointHandler());
        this._checkPointsElement.addEventListener('click', () => this._pointsHandler());
        this._checkSegmentElement.addEventListener('click', () => this._segmentHandler());

        this._btnOrtoPointElement.addEventListener('click', () => this._getPointOrtho());

        this._btnP1Element.addEventListener('click', () => {


            this.map.toolbox.draw.getPoint().then((point) => {

                this._feature1.setGeometry(point.getGeometry());
                this._generatePointsGuide();

            });


        });

        this._btnP2Element.addEventListener('click', () => {

            this.map.toolbox.draw.getPoint().then((point) => {

                this._feature2.setGeometry(point.getGeometry());
                this._generatePointsGuide();

            });

        });

        this._btnSegmentElement.addEventListener('click', () => {

            this._selectClick.setActive(true);
            document.getElementById(this.map.elementId).style.cursor = 'pointer';

        });

        this._inputElement.addEventListener('change', () => {

            this._config.defaultDistance = parseFloat(this._inputElement.value);
            this._segmentHandler();

        });

        this._btnClearElement.addEventListener('click', () => {

           this._clear();

        });


        this.map.ol.getView().on('change', () => {

            this._ortoGuidesHandler();
            this._ortoPointHandler();
            this._pointsHandler();
            this._segmentHandler();

        });


    }

    activate() {

        this.show();

    }

    deactivate() {

        this.hide();

    }

}

export { GuideLines };