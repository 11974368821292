import { GeoWidget } from '../core/GeoWidget';

class LayerSwitcher extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Controle de Camadas';
        config.title = config.title || 'Controle de Camadas';
        config.class = config.class || 'gb-layer-switcher-control';
        config.docked = config.docked || true;
        config.minWidth = config.minWidth || '400';
        config.minHeight = config.minHeight || '400';
        config.maxWidth = config.maxWidth || '400';
        config.maxHeight = config.maxHeight || '600';
        super(config);

        this.ui = null;

        this._listElm = null;

    }

    initialize() {

        this.ui = this.builUi();
        this._overlay = new ol.Overlay({
            position: [0, 0],
            positioning: 'left-top',
            element: document.createElement('div'),
            stopEvent: false,
            autoPan: true,
            autoPanMargin: 100
        });
        this.map.ol.addOverlay(this._overlay);

    }

    builUi() {

        let listElm = document.createElement('ul');
        listElm.className = 'list-group';
        listElm.style.width = '100%';

        return listElm;

    }

    _listLayers() {

        this.ui.innerHTML = '';

        let layerCount = this.map.content.length;
        for (let i = 0; i < layerCount; i++) {

            let group = this.map.content[i];

            if (group.name != 'Service Layer') {

                let liElm = this._createItemElms({
                    title: group.name,
                    isGroup: true,
                    i: i,
                    class: 'gb-ls-title',
                    isFirstItem: i == 0,
                    isLastItem: i == layerCount - 1,
                    display: group.display,
                    source: group
                });
                this.ui.appendChild(liElm);

                let ulElm = document.createElement('ul');
                ulElm.className = 'list-group list-group-flush ' + group.class + `${this.map.content[i].isOpen ? '' : ' d-none'}`;
                ulElm.style.width = '100%';
                ulElm.dataset.gbGroup = i;


                for (let j = 0; j < group.layers.length; j++) {

                    let layer = group.layers[j];

                    let liElm = this._createItemElms({
                        title: layer.name,
                        isGroup: false,
                        class: '',
                        i: i,
                        j: j,
                        isFirstItem: j == 0,
                        isLastItem: j == group.layers.length - 1,
                        isUnique: group.layers.length == 1,
                        img: layer.legendImg,
                        display: layer.display,
                        source: layer,
                        group: group
                    });

                    ulElm.appendChild(liElm);

                }

                this.ui.appendChild(ulElm);

            }

        }

        this.ui.addEventListener('click', (e) => {

            if (!e.target.classList.contains('gb-ls-group-menu')) {

                let menus = this.ui.getElementsByClassName('gb-ls-list-menu');
                for (let i = 0; i < menus.length; i++) {
                    menus[i].classList.add('d-none');

                }

            }

        });

    }

    _createItemElms(conf) {

        let liElm = document.createElement('li');
        liElm.className = 'list-group-item pt-2 pb-2 pr-0 mr-0 gb-ls-font ' + conf.class;
        liElm.style.fontSize = '14px';

        let rowElm = document.createElement('div');
        rowElm.className = 'row pr-0 mr-0 ';

        let titleElm = document.createElement('div');
        titleElm.className = 'col overflow-hidden pr-0 mr-0 ';
        titleElm.innerHTML = conf.title;

        let upElm = document.createElement('div');
        upElm.title = 'Mover para cima';
        upElm.innerHTML = 'Mover para cima';
        upElm.className = 'gb-up';

        let downElm = document.createElement('div');
        downElm.title = 'Mover para baixo';
        downElm.innerHTML = 'Mover para baixo';
        downElm.className = 'gb-down';

        let explodeElm = document.createElement('div');
        explodeElm.title = 'Separar camada';
        explodeElm.innerHTML = 'Separar camada';
        explodeElm.className = 'gb-explode';

        let groupElm = document.createElement('div');
        groupElm.title = 'Agrupar com...';
        groupElm.innerHTML = 'Agrupar com...';
        groupElm.className = 'gb-union ';

        let infoElm = document.createElement('div');
        infoElm.title = 'Identificar feição';
        infoElm.innerHTML = 'Identificar feição';
        infoElm.className = 'gb-map-info ';

        let menuElm = document.createElement('div');
        menuElm.title = 'Menu';
        menuElm.className = 'col-1 gb-ls-group-menu';
        menuElm.style.marginRight = '15px';

        let listElm = document.createElement('div');
        listElm.className = 'gb-ls-list-menu d-none';
        menuElm.append(listElm);

        let groupListElm = document.createElement('div');
        groupListElm.className = 'gb-ls-list-menu d-none ';
        menuElm.append(groupListElm);

        for (let i = 0; i < this.map.content.length; i++) {

            let group = this.map.content[i];
            if (group.type.toLowerCase() == 'wms') {

                let groupElm = document.createElement('div');
                groupElm.title = group.name;
                groupElm.innerHTML = group.name;
                groupElm.className = 'gb-no-icon-menu';
                groupListElm.append(groupElm);

                groupElm.addEventListener('click', () => {

                    const layer = Object.assign({}, this.map.content[conf.i].layers[conf.j]);
                    this.map.content[i].layers.push(layer);

                    this.map.content[conf.i].layers.splice(conf.j, 1);
                    if (this.map.content[conf.i].layers.length == 0) {
                        this.map.content.splice(conf.i, 1);
                    }

                    this._listLayers();
                    this.map._removeAllLayers();
                    this.map._drawLayers();

                    new Notify({ message: `"${layer.name}" adicionado ao grupo "${this.map.content[i].name}".`, type: 'success', timeout: 5 }).show();

                });

            }

        }

        let checkElm = document.createElement('div');
        checkElm.className = 'gb-ls-group-visibility ' + (conf.display ? 'gb-checked' : 'gb-unchecked');
        titleElm.prepend(checkElm);

        rowElm.appendChild(titleElm);
        listElm.appendChild(explodeElm);
        listElm.appendChild(upElm);
        listElm.appendChild(downElm);
        rowElm.appendChild(menuElm);
        liElm.appendChild(rowElm);

        if (conf.isGroup) {


            explodeElm.classList.add('d-none');
            titleElm.style.fontWeight = 600;
            titleElm.style.padding = 0;

            let expandElm = document.createElement('div');
            //expandElm.className = 'gb-ls-group-expand ' + (conf.source.class != 'd-none' ?  'gb-ls-group-expand-collapse' : '');
            expandElm.className = 'gb-ls-group-expand';
            expandElm.style.marginRight = '15px';
            menuElm.style.marginRight = '0';
            rowElm.append(expandElm);

            expandElm.addEventListener('click', () => {

                let listElm = document.querySelector(`[data-gb-group='${conf.i}']`);
                let isHidden = listElm.classList.contains('d-none');

                if (isHidden) {

                    conf.source.class = '';
                    listElm.classList.remove('d-none');
                    expandElm.classList.add('gb-ls-group-expand-collapse');
                    this.map.content[conf.i].isOpen = true;

                } else {

                    conf.source.class = 'd-none';
                    listElm.classList.add('d-none');
                    expandElm.classList.remove('gb-ls-group-expand-collapse');
                    this.map.content[conf.i].isOpen = false;
                }

            });

        } else {

            listElm.appendChild(groupElm);
            listElm.appendChild(infoElm);

            rowElm.classList.add('ml-1');

            if (conf.source.showLegend && conf.img) {

                conf.img.classList.add('gb-ls-img');
                liElm.append(conf.img);
            }

        }

        if (conf.isUnique) {
            explodeElm.classList.add('gb-clear-btn-layer');
        }
        if (conf.isFirstItem) {
            upElm.classList.add('gb-clear-btn-layer');
        }
        if (conf.isLastItem) {
            downElm.classList.add('gb-clear-btn-layer');
        }

        upElm.addEventListener('click', () => {
            this._moveLayer(conf, -1);
            new Notify({ message: `"${conf.source.name}" movido para cima.`, type: 'success', timeout: 5 }).show();

        });

        downElm.addEventListener('click', () => {
            this._moveLayer(conf, +1);
            new Notify({ message: `"${conf.source.name}" movido para baixo.`, type: 'success', timeout: 5 }).show();
        });

        explodeElm.addEventListener('click', () => {
            this._explodeLayer(conf);
            new Notify({ message: `"${conf.source.name}" separado com sucesso!`, type: 'success', timeout: 5 }).show();
        });

        groupElm.addEventListener('click', function (e) {

            e.stopImmediatePropagation();
            groupListElm.classList.remove('d-none');

        });

        infoElm.addEventListener('click', (e) => {

            this._getFeatureInfo(conf);

        });

        checkElm.addEventListener('click', (evt) => {

            let display;
            if (conf.isGroup) {
                this.map.content[conf.i].display = !this.map.content[conf.i].display;
                display = this.map.content[conf.i].display;

                if (evt.ctrlKey) {
                    for (let i = 0; i < this.map.content[conf.i].layers.length; i++) {
                        this.map.content[conf.i].layers[i].display = display;
                    }
                }

            } else {
                this.map.content[conf.i].layers[conf.j].display = !this.map.content[conf.i].layers[conf.j].display;
                display = this.map.content[conf.i].layers[conf.j].display;
            }

            checkElm.className = 'gb-ls-group-visibility ' + (display ? 'gb-checked' : 'gb-unchecked');
            this.map._removeAllLayers();
            this.map._drawLayers();
            this._listLayers();

        });

        menuElm.addEventListener('click', () => {

            let isHidden = listElm.classList.contains('d-none');
            if (isHidden) {
                listElm.classList.remove('d-none');
            } else {
                listElm.classList.add('d-none');
            }


        })

        return liElm;

    }

    _arrayMove(arr, fromIndex, toIndex) {

        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);

    }

    _moveLayer(conf, offset) {

        let arr = conf.isGroup ? this.map.content : this.map.content[conf.i].layers;
        let idx = conf.isGroup ? conf.i : conf.j;
        this._arrayMove(arr, idx, idx + offset);

        this._listLayers();
        this._reloadLayers();

    }

    _explodeLayer(conf) {

        const layer = Object.assign({}, this.map.content[conf.i].layers[conf.j]);
        const group = Object.assign({}, this.map.content[conf.i]);
        group.layers = [];
        group.layers.push(layer);
        group.name = layer.name;

        layer.originalI = conf.i;
        layer.originalJ = conf.j;

        this.map.content[conf.i].layers.splice(conf.j, 1);
        this.map.content.splice(0, 0, group);

        this._listLayers();
        this._reloadLayers();

    }

    _removeAllLayers() {

        let groupCount = this.map.content.length;
        for (let i = 0; i < groupCount; i++) {

            let group = this.map.content[i];

            if (group.olLayer) {

                this.map.ol.removeLayer(group.olLayer);
                delete group.olLayer;

            } else {

                for (let j = 0; j < group.layers.length; j++) {

                    let layer = group.layers[j];
                    this.map.ol.removeLayer(layer.olLayer);
                    delete layer.olLayer;
                }

            }

        }

    }

    _getGeometryName(conf) {

        return new Promise((resolve, reject) => {

            let url = conf.group.source +
                '/wfs?service=wfs&version=1.0.0&request=GetFeature&' +
                'typeNames=' + conf.group.workspace + ':' + conf.source.layer + '&' +
                'maxFeatures=1&' +
                'outputFormat=application/json&' +
                'exceptions=application/json';

            let xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            if (conf.group.auth) {
                xhr.setRequestHeader('Authorization', conf.group.auth);
            }
            xhr.onload = () => {
                if (xhr.status == 200) {

                    let data = JSON.parse(xhr.responseText);
                    if (data.numberReturned > 0) {
                        resolve(data.features[0].geometry_name);
                    } else {
                        reject();
                    }

                } else {
                    reject();
                }
            }
            xhr.onerror = function () {
                reject();
            }
            xhr.send();

        });

    }

    _getFeatureInfo(conf) {

        this.map.toolbox.draw.getPoint().then(feature => {

            this._getGeometryName(conf).then(geometryName => {


                document.body.style.cursor = 'progress';
                this.map.mapElement.style.cursor = 'progress';

                let geom = feature.getGeometry()//.transform(this.map.ol.getView().getProjection().getCode(), 'EPSG:3857');

                const fullCode = this.map.ol.getView().getProjection().getCode();
                const unit = this.map.ol.getView().getProjection().getUnits();
                const d = unit == 'm' ? 1 : 0.00001;
                const c = geom.getCoordinates();
                const wkt = `SRID=${fullCode.replace('EPSG:', '')};LINESTRING(${c[0] - d} ${c[1] - d},${c[0] + d} ${c[1] + d})`;

                let url = conf.group.source +
                    '/wfs?service=wfs&version=2.0.0&request=GetFeature&' +
                    'typeNames=' + conf.group.workspace + ':' + conf.source.layer + '&' +
                    'srsName=' + fullCode + '&' +
                    'outputFormat=application/json&' +
                    'exceptions=application/json&' +
                    'CQL_FILTER=INTERSECTS(' + geometryName + ', ' + wkt + ')';


                var xhr = new XMLHttpRequest();
                xhr.open('GET', url);
                if (conf.group.auth) {
                    xhr.setRequestHeader('Authorization', conf.group.auth);
                }
                xhr.onload = () => {

                    document.body.style.cursor = 'unset';
                    this.map.mapElement.style.cursor = 'unset';

                    if (xhr.status == 200) {

                        let data = JSON.parse(xhr.responseText);

                        if (data.exceptions) {
                            new Notify({ message: `Não foi possível obter as informações da feição.`, type: 'warning', timeout: 5 }).show();
                            return;
                        }

                        console.log(data.numberReturned)

                        if (data.numberReturned == 0) {
                            new Notify({ message: `Nenhuma feição foi encontrada.`, type: 'success', timeout: 5 }).show();
                            return;
                        }

                        this._showPopUp(data, conf, feature);

                    } else {

                        new Notify({ message: `Não foi possível obter as informações da feição.`, type: 'warning', timeout: 5 }).show();

                    }
                }
                xhr.onerror = function () {

                    document.body.style.cursor = 'unset';
                    this.map.mapElement.style.cursor = 'unset';

                    new Notify({ message: `Não foi possível obter as informações da feição.`, type: 'warning', timeout: 5 }).show();

                }

                xhr.send();

            });


        })

    }

    _showPopUp(data, conf, feature) {

        this.map.clearDrawingGeometries();
        let attributes = '';

        for (let i = 0; i < data.features.length; i++) {

            this.map.drawGeometry(data.features[i].geometry, 'geojson', {
                style: new ol.style.Style({
                    fill: new ol.style.Fill({
                        color: 'rgba(255, 0, 255, 0.2)'
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#f0f',
                        width: 2
                    }),
                    image: new ol.style.Circle({
                        radius: 7,
                        fill: new ol.style.Fill({
                            color: '#f0f'
                        })
                    })
                })
            });

            let keys = Object.keys(data.features[0].properties);
            let props = data.features[0].properties;

            for (let j = 0; j < keys.length; j++) {

                if (keys[j] == 'fields') {

                    let fields = props[keys[j]];
                    let nKeys = Object.keys(fields);

                    for (let k = 0; k < nKeys.length; k++) {
                        attributes += `<p><strong>${nKeys[k]}: </strong>${fields[nKeys[k]]}</p>`;
                    }

                } else {

                    attributes += `<p><strong>${keys[j]}: </strong>${props[keys[j]]}</p>`;

                }

            }

            attributes += `<hr>`;

        }

        let content = `
        <div class="card" style="max-width: 400px; max-height: 400px; overflow: auto; font-size: 14px;">
            <div class="ol-popup-closer"></div>
            <div class="card-body">
                <h6 class="card-title" style="font-size: 18px;">${conf.group.name} - ${conf.source.name}</h6>
                ${attributes}
            </div>
        </div>`;

        let container = document.createElement('div');
        container.innerHTML = content;
        container.addEventListener('scroll', (e) => {
            e.stopImmediatePropagation();
            e.stopPropagation();
        });

        let closeBtn = document.createElement('button');
        closeBtn.type = 'button';
        closeBtn.className = 'btn btn-light';
        closeBtn.innerHTML = 'Fechar';
        //container.getElementsByClassName('card')[0].append(closeBtn);

        this._overlay.setElement(container);
        this._overlay.setPosition(feature.getGeometry().getCoordinates())

        closeBtn.addEventListener('click', e => this._closePopUp());
        let closeBtn2 = container.getElementsByClassName('ol-popup-closer')[0];
        closeBtn2.addEventListener('click', e => this._closePopUp());

    }

    _closePopUp() {

        this.map.clearDrawingGeometries();
        this._overlay.setElement(document.createElement('div'));

    }

    _reloadLayers() {

        this._removeAllLayers();
        this.map._createLayers();
        this.map._drawLayers();

    }

    activate() {

        this._listLayers();
        this.show();

        // let elms = document.getElementsByClassName('gb-ls-group-expand-collapse');
        // for (let i = 0; i < elms.length; i++) {
        //     const element = elms[i];
        //     element.click();
        // }

    }

    deactivate() {

        this.hide();

    }

}

export { LayerSwitcher };