import { GeoWidget } from '../core/GeoWidget';

class SaveStatus extends GeoWidget {

	constructor(config) {
        
        config = config || {};
        config.hasUI = false;
        super(config);
        
        this._firstMoveMap = true;
        this._hasUI = false;
    }
    
    initialize(){

        this._registerEvents();

    }

    _saveMapState() {

        let d = new Date();
        let state = {
            center: this.map.ol.getView().getCenter(),
            zoom: this.map.ol.getView().getZoom()
        };

        state = encodeURIComponent(JSON.stringify(state));
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 5); // 5 dias
        document.cookie = 'gb_' + this.map.target + '=' + state + ';path=/;expires=' + d.toGMTString();

    }

    _saveMapHistory() {

        let stateHistory = [];
        let localStateHistory = JSON.parse(localStorage.getItem('stateHistory'));

        if(localStateHistory) {
            Array.prototype.push.apply(stateHistory, localStateHistory);
        } 

        let state = {
            center: this.map.ol.getView().getCenter(),
            zoom: this.map.ol.getView().getZoom()
        };

        stateHistory.push(state);

        if(stateHistory.length > 20) {
            stateHistory.shift();
        }

        localStorage.setItem('stateHistory', JSON.stringify(stateHistory));

    }

    _restoreMapState() {

        let v = document.cookie.match(`(^|;) ?gb_${this.map.target}=([^;]*)(;|$)`);

        if (v) {
            let state = JSON.parse(decodeURIComponent(v[2]));
            this.map.ol.getView().setCenter(state.center);
            this.map.ol.getView().setZoom(state.zoom);
        }

    }

    _hasMapState() {

        let v = document.cookie.match(`(^|;) ?gb_${this.map.target}=([^;]*)(;|$)`);

        if (v) {
            return true;
        }

        return false;

    }

    _showPreviousViewDlg() {

        let self = this;

        let msgElementId = `gb_state_float_msg_${this.map.target}`;
        let yesElementId = `gb_state_restore_pv_${this.map.target}`;
        let noElementId = `gb_state_ignore_pv_${this.map.target}`;

        let msgElement = document.createElement('div');
        msgElement.className = 'gb gb-float-dlg';
        msgElement.id = msgElementId;
        msgElement.innerHTML = ` Deseja restaurar a visão anterior? <br>
            <span id='${yesElementId}'>Sim</span>
            <span id='${noElementId}'>Não</span>`;

        document.getElementById(this.map.target).append(msgElement);
        document.getElementById(msgElementId).style.display = 'block';

        // Events
        window.setTimeout(function () {
            document.getElementById(msgElementId).style.display = 'none';
        }, 5000);

        document.getElementById(yesElementId).addEventListener('click', function () {
            self._restoreMapState();
            document.getElementById(msgElementId).style.display = 'none';
        });

        document.getElementById(noElementId).addEventListener('click', function () {
            document.getElementById(msgElementId).style.display = 'none';
        });

    }


    _registerEvents() {

        let self = this;

         this.map.ol.once('postrender', function() {

            if (self._hasMapState()) {
                self._showPreviousViewDlg();
            }

        });

        this.map.ol.on('moveend', () => {

            if (!self._firstMoveMap) {
                self._saveMapState();
                self._saveMapHistory();
            }
            self._firstMoveMap = false;

        });

    }

}

export { SaveStatus };