import { GeoButton } from '../core/GeoButton';

class FullScreen extends GeoButton {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Alternar Tela Cheia';
        config.class =  'gb-blank-control';
        super(config);

        this._fsControl = null;

    }

    initialize() {

        this._fsControl = new ol.control.FullScreen({
            source: this.map.target
        });

        this._fsControl.element.classList.remove('ol-control');

        this.on('ready', () => {

            this._fsControl.setTarget(this.element);
            this.map.ol.addControl(this._fsControl);

        });

    }

    click() {
        
        let zoom = this.map.ol.getView().getZoom() - 1;
        this.map.ol.getView().setZoom(zoom);
    }

}

export { FullScreen };