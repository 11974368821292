import { GeoWidget } from '../core/GeoWidget';

class MousePosition extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.hasUI = false;
        config.position = config.position || 'rb';
        super(config);

        this.scaleLineControl = null;
        this._hasUI = false;
        this._currentProjection = null;

    }

    initialize() {

        this._srsList = this.map._config.srs;

        let panelElementId = this.map.getPanel(this._config.position).elementId;
        this._panelElement = document.getElementById(panelElementId);
        this._panelElement.appendChild(this._getTemplate());

        let projectionCode = this.map.ol.getView().getProjection().getCode();

        this._currentProjection = null;
        for (let i = 0; i < this._srsList.length; i++) {
            if (this._srsList[i].code == projectionCode) {
                this._currentProjection = this._srsList[i];
                this.map.currentProjection = this._srsList[i];
            }
        }
        this._currentProjection = this._currentProjection || this._srsList[0];
        this._registerEvents();

        this._selectElement.value = this.map.ol.getView().getProjection().getCode();

    }

    _getTemplate() {

        let container = document.createElement('div');
        container.style.padding = '4px 0 0 0';

        this._inputElement = document.createElement('span');
        this._inputElement.style.textAlign = 'right';
        container.appendChild(this._inputElement);

        this._selectElement = document.createElement('select');
        this._selectElement.className = 'gb-hidden-select';
        for (let i = 0; i < this._srsList.length; i++) {
            this._srsList[i].element = document.createElement('option');
            this._srsList[i].element.innerHTML = this._srsList[i].name;
            this._srsList[i].element.value = i;
            this._selectElement.appendChild(this._srsList[i].element);
        }

        container.appendChild(this._selectElement);

        return container;

    }

    _projectPoint(coordinate) {

        return coordinate;

    }

    _formatCoord(coordinate) {

        let displayCoords = '';

        if (this._currentProjection.format === 'DMS') {

            displayCoords = ol.coordinate.toStringHDMS(coordinate, this.map.config.precision || 3);

        } else if (this._currentProjection.format === 'DD') {

            displayCoords = coordinate.map((e) => {
                return Number(e.toFixed(8));
            });
            displayCoords = displayCoords.reverse().toString();

        } else {

            displayCoords = coordinate.map((e) => {
                return Number(e.toFixed(this.map.config.precision || 3));
            });
            displayCoords = displayCoords.toString();

        }

        displayCoords = displayCoords.replace(',', ' ').replace(',', ' ').replace('.', ',').replace('.', ',');

        return displayCoords;

    }

    _registerEvents() {

        this.map.ol.on('pointermove', (evt) => {

            let projectedCoord = this._projectPoint(evt.coordinate);
            let formatedCoord = this._formatCoord(projectedCoord);
            this._inputElement.innerText = formatedCoord;

        });



        this._selectElement.addEventListener('change', () => {

            this._currentProjection = this._srsList[this._selectElement.value];
            this.map.currentProjection = this._srsList[this._selectElement.value];

            let center = ol.proj.transform(this.map.ol.getView().getCenter(), this.map.ol.getView().getProjection().getCode(), this._srsList[this._selectElement.value].code);
            let zoom = this.map.ol.getView().getZoom();

            var newView = new ol.View({
                projection: ol.proj.get(this._srsList[this._selectElement.value].code),
                center: center,
                zoom: zoom
            })

            this.map.ol.setView(newView);

            let controls = this.map.ol.getControls().getArray();
            for (let i = 0; i < controls.length; i++) {
                let control = controls[i];

                if (control instanceof ol.control.OverviewMap) {
                    this.map.ol.removeControl(control);
                }

            }

            let overviewMap = new ol.control.OverviewMap({
                view: new ol.View({
                    projection: ol.proj.get(this._srsList[this._selectElement.value].code),
                    center: center
                }),
                className: 'ol-overviewmap bottom-overview-map',
                label: '«',
                collapseLabel: '»',
                tipLabel: 'Mapa de localização',
                layers: [
                    new ol.layer.Tile({
                        source: new ol.source.OSM(),
                    })
                ]
            });

            this.map.ol.addControl(overviewMap);

        });

    }

}


export { MousePosition };