import { GeoWidget } from '../core/GeoWidget';

class ScaleSelector extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.hasUI = false;
        config.position = config.position || 'rb';
        config.predefinedScales = config.predefinedScales || [250, 500, 1000, 5000, 10000, 50000, 100000, 1000000];
        super(config);

        this.scaleLineControl = null;
        this._hasUI = false;
        this._inputId = '';

    }

    initialize() {

        let panelElementId = this.map.getPanel(this._config.position).elementId;
        this._panelElement = document.getElementById(panelElementId);
        this._panelElement.appendChild(this._getTemplate());
        this._inputElement.style.width = '9ch';
        this._inputElement.value = this.map.getScaleDenominator();
        this._registerEvents();

    }

    _getTemplate() {

        let container = document.createElement('div');
        this._selectElement = document.createElement('select');
        this._selectElement.className = 'gb-hidden-select';
        this._inputElement = document.createElement('input');
        this._inputElement.type = 'text';
        let scaleNumElement = document.createElement('span');
        scaleNumElement.innerText = '     1 :';

        container.appendChild(scaleNumElement);
        container.appendChild(this._inputElement);
        container.appendChild(this._selectElement);

        for(let i=0; i < this._config.predefinedScales.length; i++){

            let option = document.createElement('option');
            option.value = this._config.predefinedScales[i];
            option.innerText = this._config.predefinedScales[i]; 

            this._selectElement.add(option);
          
        }
     
        return container;

    }

    _registerEvents(){

        this.map.ol.getView().on('change:resolution', () => {

            this._inputElement.value = this.map.getScaleDenominator();

        });

        this._selectElement.addEventListener('change', () => {

            this._inputElement.value = this._selectElement.value;
            this.map.setScaleDenominator(this._selectElement.value);

        });

        this._inputElement.addEventListener('keypress', (e) => {
            var key = e.which || e.keyCode;
            if (key === 13) { // 13 is enter
                
                this.map.setScaleDenominator(this._inputElement.value);

            }
        });

       


    }

}

export { ScaleSelector };