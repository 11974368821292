import { GeoWidget } from '../core/GeoWidget';

class PrintMap extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Imprimir Mapa';
        config.title = config.title || 'Imprimir Mapa';
        config.class = config.class || 'gb-measure-line-control';
        config.maximized = true;
        config.dockable = false;

        config.templates = config.templates || [
            {
                path: '/templates/a0_landscape.html',
                name: 'Folha A0 Paisagem - Legenda padrão',
                description: 'Folha A0 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data']
            },
            {
                path: '/templates/a0_portrait.html',
                name: 'Folha A0 Retrato - Legenda padrão',
                description: 'Folha A0 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data']
            },
            {
                path: '/templates/a1_landscape.html',
                name: 'Folha A1 Paisagem - Legenda padrão',
                description: 'Folha A1 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data']
            },
            {
                path: '/templates/a1_portrait.html',
                name: 'Folha A1 Retrato - Legenda padrão',
                description: 'Folha A1 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data']
            },
            {
                path: '/templates/a2_landscape.html',
                name: 'Folha A2 Paisagem - Legenda padrão',
                description: 'Folha A2 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data']
            },
            {
                path: '/templates/a2_portrait.html',
                name: 'Folha A2 Retrato - Legenda padrão',
                description: 'Folha A2 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data']
            },
            {
                path: '/templates/a3_landscape.html',
                name: 'Folha A3 Paisagem - Legenda padrão',
                description: 'Folha A3 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data']
            },
            {
                path: '/templates/a3_portrait.html',
                name: 'Folha A3 Retrato - Legenda padrão',
                description: 'Folha A3 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data']
            },
            {
                path: '/templates/a4_landscape.html',
                name: 'Folha A4 Paisagem - Legenda padrão',
                description: 'Folha A4 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data']
            },
            {
                path: '/templates/a4_portrait.html',
                name: 'Folha A4 Retrato - Legenda padrão',
                description: 'Folha A4 no padrão NBR 10068',
                elements: ['map', 'legend', 'title', 'vendor', 'scale', 'data'],
                default: true
            }
        ];

        config.css = config.css || '/libs/openlayers/v5.2.0/ol.css';

        super(config);

        this._graticule = null;
        this._editor = null;
        this._preview = null;
        this._mapTemplateId = null;
        this._titleTemplateId = null;
        this._legendTemplateId = null;
        this._vendorTemplateId = null;
        this._scaleTemplateId = null;
        this._dataTemplateId = null;
        this._referralSystemTemplateId = null;
        this._overviewMapTemplateId = null;
        this._templateElementId = null;
        this._cTitleElementId = null;
        this._titleElementId = null;
        this._cScaleElementId = null;
        this._scaleElementId = null;
        this._cLengendElementId = null;
        this._cNorthElementId = null;
        this._cGridElementId = null;
        this._cGraphicScaleElementId = null;
        this._cLogoElementId = null;
        this._cDataElementId = null;
        this._cReferralSystemElementId = null;
        this._cOverviewMapElementId = null;
        this._scaleLineControl = null;
        this._btnSaveImage = null;
        this._zoomElementId = null;
        this.overviewMap = null;
        this.minMap = config.minMap || null;
        this.ui = this._builUi();

    }

    initialize() { }

    _formTemplate() {

        return `
            <form class="position-absolute gb-w-16" id="${this._formElementId}">

                <div class="form-group">
                    <div class="form-check">
                        <label class="form-check-label" for="gridCheck">
                            Modelo
                        </label>
                    </div>
                    <select class="form-control" id="${this._templateElementId}"></select>
                </div>

                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cTitleElementId}" checked>
                        <label class="form-check-label" for="${this._cTitleElementId}">
                            Título
                        </label>
                    </div>
                    <input class="form-control" id="${this._titleElementId}" type="text" >
                </div>

                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cScaleElementId}" checked>
                        <label class="form-check-label" for="${this._cScaleElementId}">
                            Escala
                        </label>
                    </div>
                    <input class="form-control" id="${this._scaleElementId}" type="text" >
                </div>

                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cLengendElementId}" checked>
                        <label class="form-check-label" for="${this._cLengendElementId}">
                            Legenda
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cNorthElementId}" checked>
                        <label class="form-check-label" for="${this._cNorthElementId}">
                            Indicador do Norte
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cGridElementId}" checked>
                        <label class="form-check-label" for="${this._cGridElementId}">
                            Grade de Coordenadas
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cGraphicScaleElementId}" checked>
                        <label class="form-check-label" for="${this._cGraphicScaleElementId}">
                            Escala Gráfica
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cLogoElementId}" checked>
                        <label class="form-check-label" for="${this._cLogoElementId}">
                            Logo
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cOverviewMapElementId}" checked>
                        <label class="form-check-label" for="${this._cOverviewMapElementId}">
                            Mapa de Localização
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cDataElementId}" checked>
                        <label class="form-check-label" for="${this._cDataElementId}">
                            Data
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="${this._cReferralSystemElementId}" checked>
                        <label class="form-check-label" for="${this._cReferralSystemElementId}">
                            Sistema de Referência
                        </label>
                    </div>

                </div>

                <div class="form-group">
                    <button type="submit" class="btn btn-dark">Imprimir</button>
                </div>

                <div class="form-group">
                    <button id="${this._btnSaveImage}" class="btn btn-dark">Salvar Imagem</button>
                </div>

                <div class="form-group">
                    <label for="customRange1">Zoom</label>
                    <input id="${this._zoomElementId}" type="range" class="custom-range" value="1.0" min="0.1" max="1.0" step="any">
                </div>
                
            </form> 
        `;

    }

    _builUi() {

        let container = document.createElement('div');
        container.className = 'row w-100 h-100 m-0';

        this._editor = document.createElement('div');
        //this._editor.className = 'col-2 m-3 float-left';
        this._editor.className = 'gb-print-config p-3 float-left';

        this._preview = document.createElement('div');
        this._preview.className = 'gb-print-preview bg-dark float-left';

        this._modalAlert = document.createElement('div');
        this._modalDialog= document.createElement('div');
        this._modalContent= document.createElement('div');
        this._modalHeader = document.createElement('div');
        this._modalHeaderTitle = document.createElement('h4');
        this._modalBody = document.createElement('div');
        this._modalFooter = document.createElement('div');
        this._modalFooterCheck = document.createElement('input');
        this._modalFooterText = document.createElement('p')
        this._modalFooterBtn = document.createElement('button');
        
        this._modalHeader.appendChild(this._modalHeaderTitle);
        this._modalFooter.append(this._modalFooterCheck, this._modalFooterText,this._modalFooterBtn);
        this._modalContent.append(this._modalHeader, this._modalBody, this._modalFooter);
                    
        this._modalDialog.append(this._modalContent);
        this._modalAlert.append(this._modalDialog);
               

        container.appendChild(this._editor);
        container.appendChild(this._preview);
        container.appendChild(this._modalAlert);

        return container;

    }
    _createModal(){

        this._modalAlert.id = 'modal-print-alert';
        this._modalAlert.tabIndex = '-1"';       
        this._modalAlert.style.paddingTop = '300px';
        this._modalAlert.style.paddingLeft = '800px';
        this._modalAlert.style.width = '1600px';

        this._modalAlert.className  = 'modal';
        this._modalDialog.className = 'modal-dialog';
        this._modalContent.className = 'modal-content'
        this._modalHeader.className = 'modal-header';
        this._modalHeaderTitle.className = 'modal-title';
        this._modalBody.className = 'modal-body';
        this._modalFooter.className = 'modal-footer';
        this._modalFooterBtn.className = 'btn btn-primary';
        this._modalFooterCheck.type = 'checkbox'
        
        this._modalHeaderTitle.innerText ='Aviso sobre Uso dos Dados Geoespaciais';
        this._modalBody.innerHTML = 'Os dados aqui disponibilizados são de responsabilidade das respectivas instituições produtoras. Para mais informações, consulte os metadados de cada camada.'
        this._modalFooterBtn.innerText = 'ok';
        this._modalFooterText.style.fontSize = '12px';
        this._modalFooterText.innerText = 'Li e estou ciente das restrições de uso de utilização dos dados geoespaciais.';
        $('#modal-print-alert').modal('show');

        this._modalFooterBtn.addEventListener('click',()=>{ 
            localStorage.setItem('geodado_alert', true);           
            $('#modal-print-alert').modal('hide');
            


        })

    }

    _listTemplates() {

        let list = document.getElementById(this._templateElementId);
        list.innerHTML = '';

        for (let i = 0; i < this._config.templates.length; i++) {

            let option = document.createElement('option');
            option.value = i;
            option.innerText = this._config.templates[i].name;
            list.appendChild(option);
        }


    }

    _setDefaultTemplate() {

        for (let i = 0; i < this._config.templates.length; i++) {
            if (this._config.templates[i].default) {
                this._activeTemplate = this._config.templates[i];
                this._activeTemplate.order = i;
            }
        }

        if (!this._activeTemplate) {
            this._activeTemplate = this._config.templates[0];
            this._activeTemplate.order = 0;
        }

    }

    _addGraticule() {
        let code = this.map.ol.getView().getProjection().getCode();
        if (code == 'EPSG:4326' || code == 'EPSG:3857') {

            this._graticule = new ol.layer.Graticule({
                // the style to use for the lines, optional.
                strokeStyle: new ol.style.Stroke({
                    color: 'rgba(0,0,0,0.8)',
                    width: 1
                }),
                showLabels: true,
                targetSize: 200
            });
            console.log(this._graticule)

            

            this._graticule.setMap(this.map.ol);

        }
    }

    _addScaleLine() {

        this._scaleLineControl = new ol.control.ScaleLine();
        this._scaleLineControl.setTarget(this._graphicScaleTemplateId);
        this._scaleLineControl.element.classList.remove('ol-scale-line');
        this.map.ol.addControl(this._scaleLineControl);

    }

    _fitTemplate() {

        let wHeight = document.getElementById(this._contentId).clientHeight;
        let pHeight = this._preview.clientHeight;

        let wWidth = document.getElementById(this._contentId).clientWidth;
        let pWidth = this._preview.clientWidth;

        let zoomFactor = (wHeight / pHeight) < (wWidth / pWidth) ? (wHeight / pHeight) : (wWidth / pWidth);

        this._preview.style.zoom = `${zoomFactor * 100}%`;

    }

    _getTemplate(path) {

        let self = this;
        this.map.ol.removeControl(this.overviewMap);
        let xhr = new XMLHttpRequest();
        xhr.open('GET', path);
        xhr.onload = function () {
            if (xhr.status === 200) {
                self._showTemplate(xhr.responseText);
            }
            else {
                self.map.notify('Não foi possível obter a lista de templates');
                self.deactivate();
            }
        };
        xhr.send();

    }

    _showTemplate(templateContent) {

        templateContent = this._setTemplateIds(templateContent);
        this._preview.innerHTML = templateContent;
        this._editor.innerHTML = this._formTemplate();
        this._listTemplates();
        this._setValues();
        this._handleView();
        this._registerEvents();

        this.map.ol.setTarget(this._mapTemplateId);

        let legendElm = document.getElementById('gb-thematic-neo-legend');
        //document.getElementById(this._mapTemplateId).querySelector('canvas').appendChild(legendElm);

        this._addGraticule();
        this._addScaleLine();
        this._addOverviewMap();

        if (legendElm) document.getElementById(this._legendTemplateId).innerHTML = legendElm.innerHTML;
        document.getElementById(this._legendTemplateId).innerHTML += this.map.getLegend();

        let legends = document.getElementsByClassName('img-legend-print');
        let northImage = document.getElementById(this._northTemplateId);
        let clientLogo = document.getElementById('clientLogo');

        this._toDataURL(northImage.src, (dataUrl) => {
            northImage.src = dataUrl;
            let rotation = this.map.ol.getView().getRotation();
            northImage.style.transform = `rotate(${rotation}rad)`;

            this.map.ol.getView().on("change:rotation", () => {
                let rotation = this.map.ol.getView().getRotation();
                northImage.style.transform = `rotate(${rotation}rad)`;
            });
        });

        this._toDataURL(clientLogo.src, function (dataUrl) {
            clientLogo.src = dataUrl;
        });

        for (let i = 0; i < legends.length; i++) {
            this._toDataURL(legends[i].src, function (dataUrl) {
                legends[i].src = dataUrl;
            });
        }

    }

    _addOverviewMap() {
        this.overviewMap = new ol.control.OverviewMap({
            collapsible: false,
            className: 'ol-overviewmap gb-overview-map',
            layers: [
                new ol.layer.Tile({
                    source: new ol.source.OSM()
                })
            ],
            view: new ol.View({
                projection: this.map.ol.getView().getProjection()
            })
        })
        this.map.ol.addControl(this.overviewMap);
    }

    _setTemplateIds(templateContent) {

        this._mapTemplateId = `gb_print_map_${this.id}_${this.map.target}`;
        this._titleTemplateId = `gb_print_title_${this.id}_${this.map.target}`;
        this._legendTemplateId = `gb_print_legend_${this.id}_${this.map.target}`;
        this._dataTemplateId = `gb_print_data_${this.id}_${this.map.target}`;
        this._referralSystemTemplateId = `gb_print_referral_system_${this.id}_${this.map.target}`;
        this._overviewMapTemplateId = `gb_print_overview_map_${this.id}_${this.map.target}`;
        this._vendorTemplateId = `gb_print_vendor_${this.id}_${this.map.target}`;
        this._scaleTemplateId = `gb_print_scale_${this.id}_${this.map.target}`;
        this._northTemplateId = `gb_print_north_${this.id}_${this.map.target}`;
        this._graphicScaleTemplateId = `gb_print_graphicScale_${this.id}_${this.map.target}`;
        this._logoTemplateId = `gb_print_logo_${this.id}_${this.map.target}`;

        this._formElementId = `gb_print_c_form_${this.id}_${this.map.target}`;
        this._templateElementId = `gb_print_c_template_${this.id}_${this.map.target}`;
        this._titleElementId = `gb_print_c_title_${this.id}_${this.map.target}`;
        this._scaleElementId = `gb_print_c_scale_${this.id}_${this.map.target}`;

        this._cTitleElementId = `gb_print_cc_title_${this.id}_${this.map.target}`;
        this._cScaleElementId = `gb_print_cc_scale_${this.id}_${this.map.target}`;

        this._cLengendElementId = `gb_print_cc_legend_${this.id}_${this.map.target}`;
        this._cNorthElementId = `gb_print_cc_north_${this.id}_${this.map.target}`;
        this._cGridElementId = `gb_print_cc_grid_${this.id}_${this.map.target}`;
        this._cGraphicScaleElementId = `gb_print_cc_graphic_scale_${this.id}_${this.map.target}`;
        this._cLogoElementId = `gb_print_cc_logo_${this.id}_${this.map.target}`;
        this._cDataElementId = `gb_print_cc_data_${this.id}_${this.map.target}`;
        this._cReferralSystemElementId = `gb_print_cc_referral_system_${this.id}_${this.map.target}`;
        this._cOverviewMapElementId = `gb_print_cc_overview_map_${this.id}_${this.map.target}`;

        this._btnSaveImage = `gb_print_saveImage_${this.id}_${this.map.target}`;

        this._zoomElementId = `gb_zoom_map_${this.id}_${this.map.target}`;

        templateContent = templateContent.replace('{{map}}', this._mapTemplateId);
        templateContent = templateContent.replace('{{title}}', this._titleTemplateId);
        templateContent = templateContent.replace('{{legend}}', this._legendTemplateId);
        templateContent = templateContent.replace('{{data}}', this._dataTemplateId);
        templateContent = templateContent.replace('{{referralSystem}}', this._referralSystemTemplateId);
        templateContent = templateContent.replace('{{vendor}}', this._vendorTemplateId);
        templateContent = templateContent.replace('{{scale}}', this._scaleTemplateId);
        templateContent = templateContent.replace('{{north}}', this._northTemplateId);
        templateContent = templateContent.replace('{{graphicScale}}', this._graphicScaleTemplateId);
        templateContent = templateContent.replace('{{logo}}', this._logoTemplateId);
        templateContent = templateContent.replace('{{overviewmap}}', this._overviewMapTemplateId);

        return templateContent;
    }

    _setValues() {

        document.getElementById(this._titleTemplateId).innerText = this._activeTemplate.name;
        document.getElementById(this._titleElementId).value = this._activeTemplate.name;
        document.getElementById(this._scaleElementId).value = this.map.getScaleDenominator();
        document.getElementById(this._scaleTemplateId).innerText = ' 1:' + this.map.getScaleDenominator();
        document.getElementById(this._templateElementId).value = this._activeTemplate.order;
        document.getElementById(this._dataTemplateId).innerText = new Date().toLocaleDateString();
        let code = this.map.ol.getView().getProjection().getCode();
        let referralSystem = '';

        switch (code) {
            case 'EPSG:3857':
                referralSystem = 'WGS84 PSEUDOMERCATOR';
                break;
            case 'EPSG:4326':
                referralSystem = 'WGS84';
                break;
            case 'EPSG:31982':
                referralSystem = 'SIRGAS 2000 / UTM zone 22S';
                break;
            default:
                break;
        }

        document.getElementById(this._referralSystemTemplateId).innerText = referralSystem;

    }

    _handleView() {

        document.getElementById(this._cLogoElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {

                document.getElementById(this._logoTemplateId).style.display = 'block';

            } else {

                document.getElementById(this._logoTemplateId).style.display = 'none';

            }

        });


        document.getElementById(this._cGraphicScaleElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {

                this.map.ol.addControl(this._scaleLineControl);

            } else {

                this.map.ol.removeControl(this._scaleLineControl);

            }

        });

        document.getElementById(this._cGridElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {


                this._graticule.setMap(this.map.ol);


            } else {

                if(this._graticule){
                    this._graticule.setMap(null);
                }

            }

        });

        document.getElementById(this._cNorthElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {

                document.getElementById(this._northTemplateId).style.display = 'block';

            } else {

                document.getElementById(this._northTemplateId).style.display = 'none';

            }

        });

        document.getElementById(this._cLengendElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {

                document.getElementById(this._legendTemplateId).style.display = 'flex';
                document.getElementById(this._legendTemplateId).innerHTML = this.map.getLegend();

            } else {

                document.getElementById(this._legendTemplateId).style.display = 'none';

            }

        });

        document.getElementById(this._cScaleElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {

                document.getElementById(this._scaleTemplateId).style.display = 'flex';
                document.getElementById(this._scaleElementId).removeAttribute('readonly');


            } else {

                document.getElementById(this._scaleTemplateId).style.display = 'none';
                document.getElementById(this._scaleElementId).setAttribute('readonly', null);

            }

        });

        document.getElementById(this._cTitleElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {

                document.getElementById(this._titleTemplateId).parentElement.style.display = 'block';
                document.getElementById(this._titleElementId).removeAttribute('readonly');


            } else {

                document.getElementById(this._titleTemplateId).parentElement.style.display = 'none';
                document.getElementById(this._titleElementId).setAttribute('readonly', null);

            }

        });

        document.getElementById(this._cDataElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {

                document.getElementById(this._dataTemplateId).style.display = 'flex';
                document.getElementById(this._dataTemplateId).removeAttribute('readonly');


            } else {

                document.getElementById(this._dataTemplateId).style.display = 'none';
                document.getElementById(this._dataTemplateId).setAttribute('readonly', null);

            }

        });

        document.getElementById(this._cReferralSystemElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {

                document.getElementById(this._referralSystemTemplateId).style.display = 'flex';
                document.getElementById(this._referralSystemTemplateId).removeAttribute('readonly');


            } else {

                document.getElementById(this._referralSystemTemplateId).style.display = 'none';
                document.getElementById(this._referralSystemTemplateId).setAttribute('readonly', null);

            }

        });

        document.getElementById(this._cOverviewMapElementId).addEventListener('change', (evt) => {

            if (evt.target.checked) {

                this.map.ol.addControl(this.overviewMap);
                document.getElementById(this._overviewMapTemplateId).style.display = 'flex'

            } else {

                this.map.ol.removeControl(this.overviewMap);
                document.getElementById(this._overviewMapTemplateId).style.display = 'none'

            }

        });

    }

    _toDataURL(url, callback) {
        var httpRequest = new XMLHttpRequest();
        httpRequest.onload = function () {
            var fileReader = new FileReader();
            fileReader.onloadend = function () {
                callback(fileReader.result);
            },
                fileReader.readAsDataURL(httpRequest.response);
        };
        httpRequest.open('GET', url);
        httpRequest.responseType = 'blob';
        httpRequest.send();
    }

    _print() {

        let canvasMap = document.getElementById(this._mapTemplateId).getElementsByTagName('canvas');
        let overviewMapTemplate = document.getElementById(this._cOverviewMapElementId);
        if (overviewMapTemplate.checked) {
            let canv = null;
            for (let i = 0; i < canvasMap.length; i++) {
                if (canvasMap[i].width > 130 && canvasMap[i].width < 165) {
                    canv = canvasMap[i];
                }
            }
            if (canv) {
                document.getElementById(this._overviewMapTemplateId).style.background = 'url(' + canv.toDataURL() + ')';
            }
        }

        // let scale = document.getElementsByClassName('ol-scale-line-inner')[1];
        // let textScale = scale.innerHTML;
        // let span = document.createElement('span');
        // span.className = 'gb-scale';
        // span.textContent = textScale;
        // span.style.color = '#ffffff';
        // span.style.fontFamily = 'Arial, Helvetica, sans-serif';
        // scale.innerHTML = '';
        // scale.appendChild(span);

        //document.getElementById(this._mapTemplateId).style.background = 'url(' + canvasMap[0].toDataURL() + ')';
        document.getElementById(this._mapTemplateId).style.background = 'url(' + this.map.getMapAsDataURL() + ')';

        this._preview.id = 'gb_preview_print_map';

        window.printJS({
            printable: 'gb_preview_print_map',
            type: 'html',
            documentTitle: 'Mapa',
            css: this._config.css,
            scanStyles: false
        });

        //document.getElementsByClassName('ol-scale-line-inner')[1].innerHTML = textScale;
        document.getElementById(this._overviewMapTemplateId).style.background = 'none';
        document.getElementById(this._mapTemplateId).style.background = 'none';

    }

    _saveImage(filename) {

        html2canvas(this._preview.getElementsByTagName('div')[0], { scale: 1, useCORS: true }).then((canvas) => {

            this._saveAs(canvas.toDataURL(), filename + '.png');

        });

    }

    _saveAs(uri, filename) {

        let link = document.createElement('a');

        if (typeof link.download === 'string') {

            link.href = uri;
            link.download = filename;

            //Firefox requires the link to be in the body
            document.body.appendChild(link);

            //simulate click
            link.click();

            //remove the link when done
            document.body.removeChild(link);

        } else {

            window.open(uri);

        }
    }

    _registerEvents() {

        document.getElementById(this._templateElementId).addEventListener('change', () => {

            let div = document.createElement('div');
            div.style.height = '10px';
            div.style.width = '10px';
            div.style.top = '-100%';
            div.style.left = '-100%';
            div.style.position = 'absolute';
            document.body.appendChild(div);

            if (this._graticule){
                this._graticule.setMap(null);
            }
            this.map.ol.setTarget(div);
            let order = document.getElementById(this._templateElementId).value;
            this._activeTemplate.order = order;
            this._activeTemplate = this._config.templates[order];
            this._getTemplate(this._activeTemplate.path);

            document.body.removeChild(div);

        });

        document.getElementById(this._titleElementId).addEventListener('input', () => {

            document.getElementById(this._titleTemplateId).innerText = document.getElementById(this._titleElementId).value;

        });

        document.getElementById(this._scaleElementId).addEventListener('change', () => {

            this.map.setScaleDenominator(document.getElementById(this._scaleElementId).value);
            document.getElementById(this._scaleTemplateId).innerText = ' 1:' + document.getElementById(this._scaleElementId).value;

        });

        this.map.ol.getView().on('change:resolution', () => {

            document.getElementById(this._scaleElementId).value = this.map.getScaleDenominator();
            document.getElementById(this._scaleTemplateId).innerText = ' 1:' + this.map.getScaleDenominator();

        });

        document.getElementById(this._formElementId).addEventListener('submit', (e) => {

            document.getElementsByClassName('gb-template-leaf')[0].style.transform = 'scale(1.0)';
            e.preventDefault();
            this._print();

        });

        document.getElementById(this._btnSaveImage).addEventListener('click', (e) => {

            document.getElementsByClassName('gb-template-leaf')[0].style.transform = 'scale(1.0)';
            e.preventDefault();
            this._saveImage(this._btnSaveImage);

        });


        document.getElementById(this._zoomElementId).addEventListener('input', (e) => {

            document.getElementsByClassName('gb-template-leaf')[0].style.transform = `scale(${e.currentTarget.value})`;

        });

    }

    activate() {
        localStorage.getItem('geodado_alert') != 'true'? this._createModal() : ''   ;  

        this.show();
        this.resizeToMax();
        this._setDefaultTemplate();
        this._getTemplate(this._activeTemplate.path);

        if (this.minMap) {
            this.map.ol.removeControl(this.minMap);
        }

        let legendElm = document.getElementById('gb-thematic-neo-legend');
        if (legendElm) legendElm.classList.add('d-none');

    }

    deactivate() {

        this.hide();
        this.map.ol.setTarget(this.map.elementId);
        if (this._graticule){
            this._graticule.setMap(null);
        }
        this.map.ol.removeControl(this.overviewMap);

        if (this.minMap) {
            this.map.ol.addControl(this.minMap);
        }

        let legendElm = document.getElementById('gb-thematic-neo-legend');
        if (legendElm) legendElm.classList.remove('d-none');

    }


}

export { PrintMap };