import { GeoWidget } from '../core/GeoWidget';

class PointFromTopo extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'PointFromTopo';
        config.title = config.title || 'PointFromTopo';
        config.class = config.class || 'point-from-topo';
        config.geometryType = 'linestring';
        config.defaultDistance = config.defaultDistance || 1;
        config.defaultUnits = config.defaultUnits || 'm';
        super(config);

        this._source = config.source || new ol.source.Vector();
        this._feature = null;

        this.ui = this._getUiTemplate();

    }

    initialize() {

        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();

        });

    }

    _getUiTemplate() {

        return `
        <div class='m-0 p-3'>

            <div class="row">
        
                <div class="col">
                    <label for="gb-pbi-wkt-${this.id}">WKT</label>
                    <input type="text" class="form-control" id="gb-pbi-wkt-${this.id}">
                </div>
        
                <div class="col">
                    <label for="gb-pbi-src-${this.id}">SRC</label>
                    <select id="gb-pbi-src-${this.id}" class="form-control">
                        <option value="EPSG:3857" selected>Pseudo Mercator - SIRGAS 2000</option>
                        <option value="EPSG:4326">(Lon, Lat) - SIRGAS 2000</option>
                    </select>
                </div>
        
               
            </div>

            <div class="row pt-3">
        
                <div class="col">
                    <label for="gb-pbi-dist-${this.id}">Distância (m)</label>
                    <input type="number" class="form-control" id="gb-pbi-dist-${this.id}">
                </div>
        
                <div class="col">
                    <label for="gb-pbi-az-${this.id}">Azimute (gd)</label>
                    <input type="number" class="form-control" id="gb-pbi-az-${this.id}">
                </div>
        
                <div class="col-3">
                    <label style="color: #fff"> _</label>
                    <br>
                    <button id="gb-pbi-calc-${this.id}" class="btn btn-primary btn-block">Calcular</button>
                </div>
            </div>
        </div>
        `;

    }

    _registerElements() {

        this._inputWktElement = document.getElementById(`gb-pbi-wkt-${this.id}`);
        this._btnCalcElement = document.getElementById(`gb-pbi-calc-${this.id}`);
        this._selectSrcElement = document.getElementById(`gb-pbi-src-${this.id}`);
        this._inputAzElement = document.getElementById(`gb-pbi-az-${this.id}`);
        this._inputDistElement = document.getElementById(`gb-pbi-dist-${this.id}`);

    }

    _createFromWkt(wkt){

        try{

            let format = new ol.format.WKT();

            this._feature = format.readFeature(wkt, {
                dataProjection: this._selectSrcElement.value || 'EPSG:4326',
                featureProjection: this.map.ol.getView().getProjection().getCode()
            });

            if(this._feature.getGeometry().getType().toLocaleLowerCase() === 'point'){

                //this._source.addFeature(this._feature);
                this._calculatePoint();

            } else {

                throw("O WKT inserido não é do tipo ponto.");

            } 

        } catch(e){

            this.map.notify("Não foi possível inserir a geometria desejada.<br> WKT mal formatado ou não é um ponto.");

        }

    }

    _projectPoint(coordinate) {

        return ol.proj.transform(coordinate, this.map.srid, 'EPSG:3857');

    }

    _calculatePoint(){

        let projCoords = this._projectPoint(this._feature.getGeometry().getCoordinates());
        let dist = parseFloat(this._inputDistElement.value); 
        let ang = parseFloat(this._inputAzElement.value); 

        if(projCoords && dist > 0 && ang >= 0){

            let newCoords = this._pointAzDist(projCoords, ang, dist);
            let feature = new ol.Feature(new ol.geom.Point(newCoords));
            this._source.addFeature(feature);


        } else {

            this.map.notify('Preencha os campos');

        }
    
    }

    _pointAzDist(firstPoint, az, dist) {

        az = az * Math.PI / 180;

        let x = firstPoint[0] + Math.sin(az) * dist;
        let y = firstPoint[1] + Math.cos(az) * dist;

        return [x, y];

    }

    _registerEvents() {

        this._btnCalcElement.addEventListener('click', () => {
            
            let wkt = this._inputWktElement.value;
            this._createFromWkt(wkt);

        });

    }

    activate() {

        this.show();

    }

    deactivate() {

        this.hide();

    }

}

export { PointFromTopo };