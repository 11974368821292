import { GeoWidget } from '../core/GeoWidget';

class LandView extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Mapeamento 360';
        config.title = config.title || 'Mapeamento 360 - LandView';
        config.class = config.class || 'gb-landview-control';
        config.dockable = false;
        config.minHeight = '400px';
        config.minWidth = '400px';
        super(config);

        this.ui = this._getUiTemplate();
        this._hasUI = true;
        this.LANDRUNNER_SPHERE_RADIUS = 20.0;
        this._months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

        this._provider = null;
        this._panorama = null;
        this._isLoadingPanorama = false;
        this._currentPhoto = null;
        this._triangleVectorLayer = null;
        this._currentMapProjection = null;
        this._landViewContainer = null;
        this._landViewDate = null;
        this._isOriginalMap = true;

    }

    initialize() {

        this._checkIntegrity();
        this._createLayer();
        this._initLandViewProvider();

        this.on('ready', () => {

            this._mapContainer = this.map.mapElement;
            this._landViewContainer = document.getElementById(`landview-widget-${this.id}`);
            this._initLVElements();

            this._container = document.createElement('div');
            this._container.id = 'container-' + this.id;
            this._container.style.width = '100%';
            this._container.style.height = '100%';
            this._container.style.position = 'absolute';
            this._container.style.top = '0';
            this._container.style.right = '0';
            this._container.style.zIndex = '99';
            this._container.style.display = 'none';
            this.map.mapElement.appendChild(this._container);

        });

    }

    _getUiTemplate() {

        return `
            <div id="landview-widget-${this.id}" style="width: 100%; height: 100%;">
            </div>
        `;

    }

    _checkIntegrity() {

        if (!this.config.provider) {
            throw 'Set a provider property for the Landview Control';
        }

        if (!this.config.tileServer) {
            throw 'Set a tileServer property for the Landview Control';
        }

        if (!this.config.defaultCoordinates) {
            throw 'Set a defaultCoordinates property for the Landview Control';
        }

        if (!this.config.triangleSymbology) {
            throw 'Set a triangleSymbology property for the Landview Control';
        }

    }

    _createLayer() {

        this._triangleVectorLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: this.config.triangleSymbology.polygonStyle.fill
                }),
                stroke: new ol.style.Stroke({
                    color: this.config.triangleSymbology.polygonStyle.stroke,
                    width: this.config.triangleSymbology.polygonStyle.strokeWidth
                }),
                image: new ol.style.Circle({
                    radius: this.config.triangleSymbology.pointStyle.pointRadius,
                    fill: new ol.style.Fill({
                        color: this.config.triangleSymbology.pointStyle.color
                    })
                })
            })
        });

        this._triangleVectorLayer.setZIndex(999);
        this.map.ol.addLayer(this._triangleVectorLayer);

    }

    _setMapDefaultCoordinates() {

        if (this.config.defaultCoordinates && this.config.goToDefaultCoordinates) {
            let mapView = this.map.ol.getView();
            mapView.setCenter(ol.proj.transform([this.config.defaultCoordinates.lon, this.config.defaultCoordinates.lat], 'EPSG:4326', mapView.getProjection()));
            mapView.setZoom(this.config.goToZoomLevel || 18);
        }

    }

    _initLandViewProvider() {

        if (this.config.provider.type === 'ArcGISServer') {

            this._provider = new LandViewJS.LandViewPanoramaArcGISServerProvider({
                url: this.config.provider.url,
                searchRadiusMeters: this.config.provider.searchRadiusMeters,
                attributes: this.config.provider.attributes
            });

        } else {

            this._provider = new LandViewJS.LandViewPanoramaWFSProvider({
                url: this.config.provider.url,
                typeName: this.config.provider.typeName,
                serverType: this.config.provider.serverType,
                geojsonOutputFormat: this.config.provider.geojsonOutputFormat,
                geometryFieldName: this.config.provider.geometryFieldName,
                searchRadiusMeters: this.config.provider.searchRadiusMeters,
                useJSONP: this.config.provider.useJSONP,
                attributes: this.config.provider.attributes
            });
        }

    }

    _createDefaultPanorama() {

        this._panorama = new LandViewJS.LandViewPanorama({
            divId: this._landViewContainer.id,
            tileServerUrl: this.config.tileServer.url,
            provider: this._provider,
            maximumGroundDistance: 10.0,
            autoRotation: true
        });

        this._panorama.addEventListener('pano_changed', (e) => this._onLoadPanorama(e));
        this._panorama.addEventListener('pov_changed', (e) => this._drawFov(e));
        this._panorama.addEventListener('pano_error', (e) => this._onErrorLoadPanorama(e));

    }

    _setDefaultPanorama() {

        if (this._panorama && this.config.goToDefaultCoordinates) {

            const pt = new LandViewJS.LandViewLatLng(this.config.defaultCoordinates.lat, this.config.defaultCoordinates.lon);
            this._panorama.setPosition(pt);

        } else {

            const center = ol.proj.transform(this.map.ol.getView().getCenter(), this.map.ol.getView().getProjection().getCode(), 'EPSG:4326');
            const pt = new LandViewJS.LandViewLatLng(center[1], center[0]);
            this._panorama.setPosition(pt);

        }

    }

    _onLoadPanorama(photo) {

        this._isLoadingPanorama = false;
        this._currentPhoto = photo;

        this._landViewDate.innerHTML = 'Data de Captura: ' + photo.getDateOfPictureTaken().toLocaleString() + ' | ' + 'Panorama Lat/Lon: ' + photo.getPosition().toString(8).split(',').reverse().join(' ');
        this._drawFov(this._panorama.getPov());
    }

    _onErrorLoadPanorama(e) {

        this._isLoadingPanorama = false;

        new Notify({
            message: `Panorama não encontrado!`,
            type: 'warning',
            timeout: appConfig.timeoutMsg
        }).show();

    }

    _drawFov(cameraPov) {

        if (this._currentPhoto) {

            let fovDiv2 = THREE.Math.degToRad(cameraPov.getFieldOfView() / 2.0);
            let dx = this.LANDRUNNER_SPHERE_RADIUS * Math.cos(fovDiv2);
            let dy = this.LANDRUNNER_SPHERE_RADIUS * Math.sin(fovDiv2);

            var angle = THREE.Math.degToRad(this._currentPhoto.getAngle());
            var heading = THREE.Math.degToRad(cameraPov.getHeading());

            let p1 = LandViewJS.LandViewWebMercatorUtils.geographicToWebMercator(this._currentPhoto.getPosition());

            let p2 = new LandViewJS.LandViewXY();
            p2.x = p1.x + dx;
            p2.y = p1.y + dy;

            let p3 = new LandViewJS.LandViewXY();
            p3.x = p1.x + dx;
            p3.y = p1.y - dy;

            p2.x = p2.x - p1.x;
            p2.y = p2.y - p1.y;
            p3.x = p3.x - p1.x;
            p3.y = p3.y - p1.y;

            p2 = this._rigidBodyTransform(p2, angle);
            p3 = this._rigidBodyTransform(p3, angle);

            p2 = this._rigidBodyTransform(p2, -heading);
            p3 = this._rigidBodyTransform(p3, -heading);

            p2.x = p2.x + p1.x;
            p2.y = p2.y + p1.y;
            p3.x = p3.x + p1.x;
            p3.y = p3.y + p1.y;

            p1 = this._lndViewPointToOlPoint(p1);
            p2 = this._lndViewPointToOlPoint(p2);
            p3 = this._lndViewPointToOlPoint(p3);

            let polygon = new ol.geom.Polygon([[p1.getCoordinates(), p2.getCoordinates(), p3.getCoordinates()]]);

            let trianglePointFeature = new ol.Feature({
                name: 'Point',
                geometry: p1
            });

            let triangleFeature = new ol.Feature({
                name: 'Triangle',
                geometry: polygon
            });

            this._triangleVectorLayer.getSource().clear();
            this._triangleVectorLayer.getSource().addFeatures([trianglePointFeature, triangleFeature]);

            // if (!this._pointOnExtent(p1.getCoordinates())) {
            //     this.map.ol.getView().animate({
            //         center: p1.getCoordinates(),
            //         duration: 2000
            //     });
            // }

        }

    }

    _pointOnExtent(point) {

        const extent = this.map.ol.getView().calculateExtent();

        if (extent[0] < point[0] &&
            point[0] < extent[2] &&
            extent[1] < point[1] &&
            point[1] < extent[3]) {

            return true;

        }
    }

    _rigidBodyTransform(coordinates, angleRadians) {

        let newCoordinates = new LandViewJS.LandViewXY();
        let sina = Math.sin(angleRadians);
        let cosa = Math.cos(angleRadians);

        newCoordinates.x = coordinates.x * cosa - coordinates.y * sina;
        newCoordinates.y = coordinates.x * sina + coordinates.y * cosa;

        return newCoordinates;
    }

    _lndViewPointToOlPoint(pt) {

        let pCoords = ol.proj.transform([pt.x, pt.y], 'EPSG:3857', this.map.ol.getView().getProjection().getCode())
        return new ol.geom.Point(pCoords);

    }

    _onMapClick(e) {

        if (!this._isLoadingPanorama) {

            let coordinate = ol.proj.transform(e.coordinate, e.target.getView().getProjection(), 'EPSG:4326');
            let pt = new LandViewJS.LandViewLatLng(coordinate[1], coordinate[0]);
            this._panorama.setPosition(pt);

        }
    }

    _registerEvents() {

        this._event = this.map.ol.on('click', (e) => { this._onMapClick(e); });

    }

    _unregisterEvents() {

        this.map.ol.un('click', this._event.listener);

    }

    _removeAllEvents(old_element) {

        var new_element = old_element.cloneNode(true);
        old_element.parentNode.replaceChild(new_element, old_element);

        return document.getElementById(old_element.id);

    }

    _initLVElements() {

        this._panorama = null;
        this._currentPhoto = null;

        this.ui.innerHTML = this._getUiTemplate();
        this._landViewContainer = document.getElementById(`landview-widget-${this.id}`);

        this._landViewContainer.innerHTML = '';
        this._landViewContainer = this._removeAllEvents(this._landViewContainer);
        this._landViewContainer.innerHTML = `
                <div id="landview-lv-${this.id}" class="gb-lv-screen"></div>
                <div id="landview-lv-tip-${this.id}" class="gb-lv-screen-tip">Trocar Posição</div>
                <div id="landview-date-${this.id}" class="gb-lv-date"></div>
        `;

        this._landViewDate = document.getElementById(`landview-date-${this.id}`);
        this._changeButtom = document.getElementById(`landview-lv-${this.id}`);
        this._changeButtomTip = document.getElementById(`landview-lv-tip-${this.id}`);

        this._changeButtom.addEventListener('mouseover', () => {
            this._changeButtomTip.style.display = 'block';
        });

        this._changeButtom.addEventListener('mouseout', () => {
            this._changeButtomTip.style.display = 'none';
        });

        this._changeButtom.addEventListener('click', () => {
            this._changePosition();
        });

    }

    _changePosition() {

        clearInterval(this._sizeInterval);
        let position = this._currentPhoto.getPosition();

        this._unregisterEvents();
        this._initLVElements();

        if (this._isOriginalMap) {

            this.map.ol.setTarget(this._landViewContainer);
            this._registerChangeMapSize();

            this._landViewContainer.style.cursor = 'crosshair';
            this._container.style.cursor = 'auto';
            this._container.style.display = 'block';

            this._panorama = new LandViewJS.LandViewPanorama({
                divId: this._container.id,
                tileServerUrl: this.config.tileServer.url,
                provider: this._provider,
                maximumGroundDistance: 10.0,
                autoRotation: true
            });

            this._isOriginalMap = false;

        } else {

            this._container.innerHTML = '';
            this._container = this._removeAllEvents(this._container);
            this._container = this._removeAllEvents(this._container);
            this.map.ol.setTarget(this._mapContainer);

            this._panorama = new LandViewJS.LandViewPanorama({
                divId: this._landViewContainer.id,
                tileServerUrl: this.config.tileServer.url,
                provider: this._provider,
                maximumGroundDistance: 10.0,
                autoRotation: true
            });

            this._landViewContainer.style.cursor = 'auto';
            this._container.style.display = 'none';
            this._isOriginalMap = true;

        }

        this._registerEvents();
        this._panorama.addEventListener('pano_changed', (e) => this._onLoadPanorama(e));
        this._panorama.addEventListener('pov_changed', (e) => this._drawFov(e));
        this._panorama.addEventListener('pano_error', (e) => this._onErrorLoadPanorama(e));
        this._panorama.setPosition(position);

        //this._eventClear();

    }

    _registerChangeMapSize() {

        this._size = {
            width: this._landViewContainer.clientWidth,
            height: this._landViewContainer.clientHeight
        }

        this._sizeInterval = setInterval(() => {

            let width = this._landViewContainer.clientWidth;
            let height = this._landViewContainer.clientHeight;

            if (width != this._size.width || height != this._size.height) {

                this._size = {
                    width: this._landViewContainer.clientWidth,
                    height: this._landViewContainer.clientHeight
                }

                this.map.updateSize();

            }


        }, 300);

    }

    _eventClear() {

        const widgetElm = this.__elements.widget;

        widgetElm.addEventListener(
            "mousedown",
            function (ev) {
                ev.stopPropagation();
            }
        );
        widgetElm.addEventListener(
            "mousemove",
            function (ev) {
                ev.stopPropagation();
            }
        );
        widgetElm.addEventListener(
            "mouseout",
            function (ev) {
                ev.stopPropagation();
            }
        );
        widgetElm.addEventListener(
            "mouseup",
            function (ev) {
                ev.stopPropagation();
            }
        );
        widgetElm.addEventListener(
            "mousewheel",
            function (ev) {
                ev.stopPropagation();
            },
        );
        widgetElm.addEventListener(
            "DOMMouseScroll",
            function (ev) {
                ev.stopPropagation();
            }
        ); // Firefox
        widgetElm.addEventListener(
            "touchstart",
            function (ev) {
                ev.stopPropagation();
            }
        );
        widgetElm.addEventListener(
            "touchmove",
            function (ev) {
                ev.stopPropagation();
            }
        );
        widgetElm.addEventListener(
            "touchend",
            function (ev) {
                ev.stopPropagation();
            }
        );
        widgetElm.addEventListener(
            "dblclick",
            function (ev) {
                ev.stopPropagation();
            }
        );

    }

    activate() {

        this.map.closeAllTools();
        super.activate();

        this._setMapDefaultCoordinates();
        this._registerEvents();
        this._createDefaultPanorama();
        this._setDefaultPanorama();

        this.on('activate', () => {
            this.map.hideDisabledControls();
        });

        this.show();

    }

    deactivate() {

        super.deactivate();
        this._unregisterEvents();
        this._initLVElements();
        this._triangleVectorLayer.getSource().clear();
        this.map.showDisabledControls();
        this._mapContainer.style.cursor = 'auto';

        if (!this._isOriginalMap) {
            this._container.innerHTML = '';
            this._container = this._removeAllEvents(this._container);
            this.map.ol.setTarget(this._mapContainer);
            this._isOriginalMap = true;
            this._container.style.display = 'none';
        }
        this.hide();

    }
}

export { LandView };