import { GeoWidget } from '../core/GeoWidget';

class OgcExporter extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Exportar';
        config.title = config.title || 'Exportar Dados';
        config.class = config.class || 'gb-measure-line-control';
        config.formats = config.formats || [
            { format: 'DXF', name: 'AutoCAD DXF', ext: 'dxf' },
            { format: 'SHAPE-ZIP', name: 'ESRI Shape File', ext: 'zip' },
            { format: 'application/json', name: 'GeoJSON', ext: 'geojson' },
            { format: 'csv', name: 'CSV', ext: 'csv' },
            { format: 'application/vnd.google-earth.kml+xml', name: 'Google Earth KML', ext: 'kml' }
        ];
        super(config);
        this.ui = this._builUi();
        this._supportedFormats = null;
        this._layersElements = [];
        this._hasBtn = false;


    }

    initialize() {

        this._getFormats();

    }

    _writeLayers(content) {

        let layers = content.layers;
        for (let j = 0; j < layers.length; j++) {

            let layerElement = document.createElement('input');
            let nameElement = document.createElement('span');
            let newLineElement = document.createElement('br');

            layerElement.type = 'checkbox';
            layerElement.name = 'gb-ogc-exporter-' + this.id + '-' + this.map.target;
            layerElement.value = content.workspace + ':' + layers[j].layer;
            layers[j].exportElement = layerElement;

            nameElement.innerHTML = ' ' + layers[j].name;

            this._listElm.appendChild(layerElement);
            this._listElm.appendChild(nameElement);
            this._listElm.appendChild(newLineElement);

        }

        let newLineElement = document.createElement('br');
        this._listElm.appendChild(newLineElement);


    }

    _writeFormats() {

        this._formatsElement.className = 'form-control mt-3 mb-3';

        //for (let i = 0; i < this._supportedFormats.length; i++) {

        for (let j = 0; j < this.config.formats.length; j++) {

            // if (this._supportedFormats[i] === this.config.formats[j].format) {

            let option = document.createElement('option');
            option.text = this.config.formats[j].name;
            option.value = this.config.formats[j].format;
            this._formatsElement.add(option);

            // }
        }
        //}

    }

    _writeSendBtn() {

        this._btnElement.className = 'btn btn-dark btn-block';
        this._btnElement.innerText = 'Exportar';
        this._btnElement.addEventListener('click', () => this._getFile());

    }

    _getFile() {

        let format = this._formatsElement.value;
        for (let i = 0; i < this.map.content.length; i++) {
            let layersExport = [];

            let layers = this.map.content[i].layers;
            for (let j = 0; j < layers.length; j++) {

                let element = layers[j].exportElement;
                if (element && element.checked) {
                    layersExport.push(element.value);
                }

            }

            if (layersExport.length > 0) {
                let path = this.map.content[i].source + '/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=' + layersExport.join(',') + '&outputFormat=' + format;
                let auth = this.map.content[i].auth;
                this.map.containerElement.style.cursor = 'wait';
                this._open(path, auth, format);
            }



        }

    }

    _open(path, auth, format) {

        for (let i = 0; i < this.config.formats.length; i++) {

            if (this.config.formats[i].format == format) {
                format = this.config.formats[i].ext;
            }

        }

        let msg = new Notify({ message: `Preparando arquivo...`, type: 'success', timeout: 60 });
        msg.show();

        let mapElm = this.map.containerElement;
        let fileName = '';
        let save = this._saveFile;
        let xhr = new XMLHttpRequest();
        xhr.open('GET', path);
        xhr.withCredentials = true;
        if (auth) xhr.setRequestHeader('Authorization', auth);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var blob = xhr.response;
            if (xhr.getResponseHeader('Content-Disposition')) {
                var contentDispo = xhr.getResponseHeader('Content-Disposition');
                fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            } else {
                fileName = Date.now() + '_exportacao.' + format;
            }

            save(blob, fileName);
            mapElm.style.cursor = '';
            msg.hide();

        }
        xhr.send();
    }

    _saveFile(blob, fileName) {

        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.dispatchEvent(new MouseEvent('click'));

    }

    _builUi() {

        let container = document.createElement('div');
        let br = document.createElement('br');
        container.className = 'm-0 p-3';

        this._listElm = document.createElement('div');
        this._btnElement = document.createElement('button');
        this._formatsElement = document.createElement('select');

        container.appendChild(this._listElm);
        container.appendChild(this._formatsElement);
        container.appendChild(this._btnElement);
        container.appendChild(br);

        return container;

    }

    _getFormats() {

        this._writeSendBtn();
        this._writeFormats();

        for (let i = 0; i < this.map.content.length; i++) {

            switch (this.map.content[i].type.toLowerCase()) {
                case 'wms':
                case 'wfs':
                case 'ogc':
                    this._writeLayers(this.map.content[i]);
                    break;
            }

        }

    }

    _xmlToJson(xml) {

        // Create the return object
        var obj = {};

        if (xml.nodeType == 1) { // element
            // do attributes
            if (xml.attributes.length > 0) {
                obj['@attributes'] = {};
                for (var j = 0; j < xml.attributes.length; j++) {
                    var attribute = xml.attributes.item(j);
                    obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
                }
            }
        } else if (xml.nodeType == 3) { // text
            obj = xml.nodeValue;
        }

        // do children
        // If just one text node inside
        if (xml.hasChildNodes() && xml.childNodes.length === 1 && xml.childNodes[0].nodeType === 3) {
            obj = xml.childNodes[0].nodeValue;
        }
        else if (xml.hasChildNodes()) {
            for (var i = 0; i < xml.childNodes.length; i++) {
                var item = xml.childNodes.item(i);
                var nodeName = item.nodeName;
                if (typeof (obj[nodeName]) == 'undefined') {
                    obj[nodeName] = this._xmlToJson(item);
                } else {
                    if (typeof (obj[nodeName].push) == 'undefined') {
                        var old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(this._xmlToJson(item));
                }
            }
        }

        return obj;

    }

    activate() {

        this.show();

    }

    deactivate() {

        this.hide();

    }

}

export { OgcExporter };