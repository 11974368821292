import { GeoButton } from '../core/GeoButton';

class Undo extends GeoButton {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Voltar posição';
        config.class = config.class || 'gb-undo-control';
        super(config);
    }

    initialize() { 
    
        this._registerEvents();

    }

    click() {

        this._undoMapState();

    }

    _undoMapState() {

        let localStateHistoryUndo = JSON.parse(localStorage.getItem('stateHistory'));
        let localStateHistoryRedo = JSON.parse(localStorage.getItem('stateHistoryRedo'));
        let stateHistoryRedo = [];

        if (localStateHistoryUndo) {

            if(localStateHistoryRedo) {
                Array.prototype.push.apply(stateHistoryRedo, localStateHistoryRedo);
            }

            stateHistoryRedo.push(localStateHistoryUndo[localStateHistoryUndo.length-1]);

            if(stateHistoryRedo.length > 20) {
                stateHistoryRedo.shift();
            }

            localStateHistoryUndo.pop();

            if(localStateHistoryUndo.length < 1) {
                return;
            }

            let lastState = localStateHistoryUndo[localStateHistoryUndo.length-1];

            this.map.ol.getView().animate({
                center: lastState.center,
                zoom: lastState.zoom,
                duration: 300
            });

            localStateHistoryUndo.pop();

            localStorage.setItem('stateHistory', JSON.stringify(localStateHistoryUndo));
            localStorage.setItem('stateHistoryRedo', JSON.stringify(stateHistoryRedo));

        }

    }

    _registerEvents() {
        
        let self = this;

        window.addEventListener('keyup', function(e){
            if (e.ctrlKey && e.which == 90) {
                self._undoMapState();
            }
        });

    }

}

export { Undo };