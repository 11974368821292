import { GeoWidget } from '../core/GeoWidget';

class Heatmap extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Mapa de Calor';
        config.title = config.title || 'Mapa de Calor';
        config.class = config.class || 'gb-heatmap-control';
        super(config);

        this._radiusInptElement = null;
        this._blurInptElement = null;
        this._layersSlctElement = null;
        this._componentSlctElement = null;
        this._btnElement = null;
        this._maxInptElement = null;

        this._vector = null;

        this.ui = this.builUi();

    }

    initialize() {

        this.on('ready', () => {

            this._getLayersOgc();
            this._registerEvents();

        });

    }

    builUi() {

        let heatmapElement = document.createElement('div');
        heatmapElement.className = 'gb-measure-content';
        heatmapElement.classList.add('w-100');

        let inputsContainer = document.createElement('div');
        inputsContainer.classList.add('d-flex');
        inputsContainer.classList.add('justify-content-center');
        inputsContainer.classList.add('align-items-center');
        inputsContainer.classList.add('mt-2');

        let formGroup = document.createElement('div');
        formGroup.className = 'form-group';

        let labelRadius = document.createElement('label');
        labelRadius.textContent = 'Tamanho do Raio';

        this._radiusInptElement = document.createElement('input');
        this._radiusInptElement.setAttribute('type', 'range');
        this._radiusInptElement.setAttribute('min', '1');
        this._radiusInptElement.setAttribute('max', '50');
        this._radiusInptElement.setAttribute('step', '1');
        this._radiusInptElement.setAttribute('value', '5');
        this._radiusInptElement.className = 'custom-range';

        //////////////
        let labelMaxFeatures = document.createElement('label');
        labelMaxFeatures.textContent = 'Máximo de Feições';

        this._maxInptElement = document.createElement('input');
        this._maxInptElement.setAttribute('type', 'number');
        this._maxInptElement.setAttribute('min', '1');
        this._maxInptElement.setAttribute('value', '5000');
        this._maxInptElement.className = 'form-control';
        //////////////

        let labelLayers = document.createElement('label');
        labelLayers.textContent = 'Camadas';

        this._layersSlctElement = document.createElement('select');
        this._layersSlctElement.classList = 'form-control';
        this._layersSlctElement.classList.add('mb-2');

        let labelFields = document.createElement('label');
        labelFields.textContent = 'Campos';

        this._componentSlctElement = document.createElement('select');
        this._componentSlctElement.classList = 'form-control';
        this._componentSlctElement.classList.add('mb-2');

        let labelBlur = document.createElement('label');
        labelBlur.textContent = 'Tamanho do Borrão';

        this._blurInptElement = document.createElement('input');
        this._blurInptElement.setAttribute('type', 'range');
        this._blurInptElement.setAttribute('min', '1');
        this._blurInptElement.setAttribute('max', '50');
        this._blurInptElement.setAttribute('step', '1');
        this._blurInptElement.setAttribute('value', '15');
        this._blurInptElement.className = 'custom-range';

        this._btnElement = document.createElement('button');
        this._btnElement.className = 'btn btn-dark btn-block';
        this._btnElement.classList.add('mt-4');
        this._btnElement.innerText = 'Gerar';

        formGroup.appendChild(labelLayers);
        formGroup.appendChild(this._layersSlctElement);
        formGroup.appendChild(labelFields);
        formGroup.appendChild(this._componentSlctElement);
        formGroup.appendChild(labelMaxFeatures);
        formGroup.appendChild(this._maxInptElement);
        formGroup.appendChild(labelBlur);
        formGroup.appendChild(this._blurInptElement);
        formGroup.appendChild(labelRadius);
        formGroup.appendChild(this._radiusInptElement);
        formGroup.appendChild(this._btnElement);

        inputsContainer.appendChild(formGroup);
        heatmapElement.appendChild(inputsContainer);

        return heatmapElement;

    }

    _getLayersOgc() {

        for (let i = 0; i < this.map.content.length; i++) {
            if (this.map.content[i].type == 'ogc') {

                for (let j = 0; j < this.map.content[i].layers.length; j++) {
                    let option = document.createElement('option');
                    option.setAttribute('i', i);
                    option.setAttribute('j', j);
                    option.text = this.map.content[i].name + ': ' + this.map.content[i].layers[j].name;
                    this._layersSlctElement.add(option);
                }

            }
        }

    }

    _initLayers() {

        let selectedLayer = this._layersSlctElement.options[this._layersSlctElement.selectedIndex];
        let selectedField = this._componentSlctElement.options[this._componentSlctElement.selectedIndex];
        let i = selectedLayer.getAttribute('i');
        let j = selectedLayer.getAttribute('j');

        if (this._vector) {
            this.map.ol.removeLayer(this._vector);
            this._vector = null;
        }

        this._vector = new ol.layer.Heatmap({
            source: new ol.source.Vector({
                url: this.map.content[i].source + '/ows?service=WFS&version=1.0.0&request=GetFeature&maxFeatures='+ this._maxInptElement.value +'&typeName=' + this.map.content[i].workspace + ':' + this.map.content[i].layers[j].layer + '&outputFormat=application%2Fjson&srsName=EPSG:900913',
                format: new ol.format.GeoJSON()
            }),
            blur: parseInt(this._blurInptElement.value, 10),
            radius: parseInt(this._radiusInptElement.value, 10)
        });        

        this._vector.setZIndex(99999);
        this.map.ol.addLayer(this._vector);

        if (selectedField != undefined) {
            this._vector.getSource().on('addfeature', function (event) {
                event.feature.set('weight', event.feature.get(selectedField.value));
            });
        }

    }


    _getFields() {

        let self = this;

        let selectedLayer = this._layersSlctElement.options[this._layersSlctElement.selectedIndex];
        let i = selectedLayer.getAttribute('i');
        let j = selectedLayer.getAttribute('j');

        let xhr = new XMLHttpRequest();
        xhr.open('GET', this.map.content[i].source + '/wfs?request=describeFeatureType&typename=' + this.map.content[i].workspace + ':' + this.map.content[i].layers[j].layer + '&outputFormat=application%2Fjson');
        xhr.onload = function () {
            if (xhr.status === 200) {
                self._writeFields(JSON.parse(xhr.responseText));
            }
            else {
                self.map.notify('Não foi possível obter a lista dos campos');
                self.deactivate();
            }
        };
        xhr.send();

    }

    _writeFields(fields) {

        this._componentSlctElement.innerHTML = '';

        for (let i = 0; i < fields.featureTypes[0].properties.length; i++) {
            let option = document.createElement('option');
            option.setAttribute('i', i);
            option.text = fields.featureTypes[0].properties[i].name;
            this._componentSlctElement.add(option);
        }

    }

    _registerEvents() {

        this._blurInptElement.addEventListener('input', () => {
            if (this._vector) {
                this._vector.setBlur(parseInt(this._blurInptElement.value, 10));
            }
        });

        this._layersSlctElement.addEventListener('change', () => {
            this._getFields();
        });

        this._radiusInptElement.addEventListener('input', () => {
            if (this._vector) {
                this._vector.setRadius(parseInt(this._radiusInptElement.value, 10));
            }
        });

        this._btnElement.addEventListener('click', () => {
            this._initLayers();
        });

    }

    activate() {
        this.show();
        this._getFields();
        this._initLayers();
    }

    deactivate() {
        this.map.ol.removeLayer(this._vector);
        this._vector = null;
        this.hide();
    }

}

export { Heatmap };