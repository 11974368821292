import { returnOrUpdate } from 'ol/extent';
import Layer from 'ol/layer/Layer';
import { GeoWidget } from '../core/GeoWidget';

class SLDEditor extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'StyleEditor';
        config.title = config.title || 'Lista de camadas';
        config.class = config.class || 'gb-menu-control';
        config.docked = config.docked || true;
        config.hasUI = false;
        config.untitled = true;

        super(config);

        this._hasUI = false;

        this.dict = [];
        this.on('ready', () => {


        });

    }

    initialize() {

        this._createUiElms();
        this._showInterface();


    }

    _createUiElms() {

        this.map.hideAllControls();
        this.map.openDock('left', 15);
        this.map.mapElement.style.right = '0';

        this.panel = this.map.getDock('left');
        this.panel.style.background = 'linear-gradient(to bottom, #464A5B 50%, #24303C)';
        this.panel.style.width = '20%';
        this.panel.style.fontSize = '12px';

        this.layersList = document.createElement('div');
        this.layersList.style.padding = '0';
        this.layersList.style.overflow = 'auto'
        this.layersList.style.width = '100%'
        this.layersList.style.height = '100%'
        this.layersList.style.float = 'left';
        this.layersList.innerHTML = `
            <h5 class="text-center p-3 text-light">
                Camadas
            </h5>
        `;

        this.layersRule = document.createElement('div');
        this.layersRule.style.width = '30%'
        this.layersRule.style.height = '100%'
        this.layersRule.style.float = 'left'
        this.layersRule.style.padding = '5px'
        this.layersRule.style.display = 'none'
        this.layersRule.innerHTML = `
            <h5 class="text-center p-3 text-light">
                Regras
            </h5>
            <div id="rules-list-${this.id}" style="cursor: pointer;"></div>
            <button id="btn-add-style-${this.id}" type="button" class="btn btn-dark btn-sm float-right mt-3">Adicionar</button>
        `;

        this.layerStyleElm = document.createElement('div');
        this.layerStyleElm.style.width = '40%'
        this.layerStyleElm.style.height = '100%'
        this.layerStyleElm.style.float = 'left'
        this.layerStyleElm.style.padding = '5px'
        this.layerStyleElm.style.display = 'none'
        this.layerStyleElm.style.overflow = 'auto';
        this.layerStyleElm.innerHTML = `
            <h5 class="text-center p-3 text-light">
                Estilos
            </h5>
            <div id="style-list-${this.id}" style="cursor: pointer;"></div>
            <button id="btn-save-style-${this.id}" type="button" class="btn btn-dark btn-sm float-right mt-3">Salvar</button>
        `;

        this.panel.append(this.layersList);
        this.panel.append(this.layersRule);
        this.panel.append(this.layerStyleElm);

        this.layersList.innerHTML += this._getContentListElm();
        this._addLayerListEvents();
        this.ruleContainer = document.getElementById(`rules-list-${this.id}`);;
        this.styleContainer = document.getElementById(`style-list-${this.id}`);;
        this.btnAddRule = document.getElementById(`btn-add-style-${this.id}`);
        this.btnSaveStyle = document.getElementById(`btn-save-style-${this.id}`);

        this.btnSaveStyle.addEventListener('click', () => {

            this._save();

        });


    }


    _showInterface(col) {


        switch (col) {
            case 'layers-rules':

                this.panel.style.width = '40%';
                this.layersList.style.width = '50%';
                this.layersRule.style.width = '50%';
                this.layersRule.style.display = 'block';
                this.layerStyleElm.style.display = 'none';

                break;

            case 'layers-rules-style':

                this.panel.style.width = '60%';
                this.layersList.style.width = '33.3333%';
                this.layersRule.style.width = '33.3333%';
                this.layerStyleElm.style.width = '33.3333%';
                this.layersRule.style.display = 'block';
                this.layerStyleElm.style.display = 'block';

                break;

            case 'layers-style':

                this.panel.style.width = '40%';
                this.layersList.style.width = '50%';
                this.layerStyleElm.style.width = '50%';
                this.layersRule.style.display = 'none';
                this.layerStyleElm.style.display = 'block';

                break;

            default:

                this.panel.style.width = '20%';
                this.layersList.style.width = '100%';
                this.layersRule.style.display = 'none';
                this.layerStyleElm.style.display = 'none';

                break;
        }



    }

    _getContentListElm() {

        let list = '';
        for (let i = 0; i < this.map.content.length; i++) {

            const content = this.map.content[i];
            list += `
            <ul class="list-group m-2">
                <li class="list-group-item list-group-item-light disabled pl-1">
                ${content.name}
                </li>
                ${this._getLayersListElm(content.layers, i)}

            </ul>
            `;

        }

        return list;


    }

    _getLayersListElm(layers, i) {

        let list = '';
        for (let k = 0; k < layers.length; k++) {
            const layer = layers[k];

            list += `
            <li data-i="${i}" data-j="${k}" class="layer-item list-group-item pl-3" style="cursor: pointer;">
                <span class="pl-3" style="display: grid">${layer.name}</span>
            </li>`

        }

        return list;

    }

    _addLayerListEvents() {

        let items = document.getElementsByClassName('layer-item');
        for (let i = 0; i < items.length; i++) {

            let layerItem = items[i];
            layerItem.addEventListener('click', () => {

                let i = layerItem.getAttribute('data-i');
                let j = layerItem.getAttribute('data-j');
                this._showLayerStyle(i, j);

            })

        }

    }

    _showLayerStyle(i, j) {

        this.ruleContainer.innerHTML = '';
        let list = document.createElement('ul');
        list.className = 'list-group'
        this.ruleContainer.append(list);

        let content = this.map.content[i];
        this.i = i;
        this.j = j;

        if (content.type.toLowerCase() == 'wms' ||
            content.type.toLowerCase() == 'wfs' ||
            content.type.toLowerCase() == 'ogc') {

            this._getLayerStyle(i, j).then((r) => {

                this._showInterface('layers-rules');

                let rules = this.style.getElementsByTagName('se:Rule');

                for (let k = 0; k < rules.length; k++) {

                    let rule = rules[k];


                    let item = document.createElement('li');
                    item.className = 'list-group-item';
                    try {
                        item.innerHTML = rule.getElementsByTagName('se:Name')[0].innerHTML;
                    } catch (e) {
                        item.innerHTML = 'Estilo sem nome';
                    }

                    item.addEventListener('click', () => {
                        this._rule = rules[k];
                        this._showStyle(rule);
                    });

                    list.append(item);

                }

            });

        } else {

            this._showInterface('layers-style');
            this.styleContainer.innerHTML = '';
            this.styleContainer.append(this._getLayerOpacityElms());

        }

    }

    _showStyle(rule) {

        this._showInterface('layers-rules-style');

        this.styleContainer.innerHTML = '';

        this.styleContainer.append(this._getNameElm(rule));
        this.styleContainer.append(this._getScaleElm(rule, 'se:MinScaleDenominator', 'Denominador Mínimo de Escala'));
        this.styleContainer.append(this._getScaleElm(rule, 'se:MaxScaleDenominator', 'Denominador Máximo de Escala'));
        this.styleContainer.append(this._getSizeElm(rule));
        this.styleContainer.append(this._getPointSymbolizerElms(rule));
        this.styleContainer.append(this._getLineSymbolizerElms(rule));
        this.styleContainer.append(this._getPolygonSymbolizerElms(rule));
        this.styleContainer.append(this._getLayerOpacityElms(rule));


    }

    _getLayerOpacityElms() {

        let layer = this.map.content[this.i].layers[this.j];
        layer.style = layer.style || {};
        layer.style.opacity = layer.style.opacity || 1;


        let container = document.createElement('div');
        container.className = 'form-group p-3 bg-white rounded';

        let label = document.createElement('label');
        label.innerHTML = 'Transparência da Camada';

        let input = document.createElement('input');
        input.className = 'form-control form-control-sm';
        input.type = 'number';
        input.min = 0;
        input.max = 100;
        input.step = 10;
        input.value = parseInt((1 - layer.style.opacity) * 100);

        input.addEventListener('change', () => {

            layer.modifiedStyle = true;
            layer.style.opacity = 1 - (Number(input.value) / 100);
            console.log(this.map.content[this.i].layers[this.j].style.opacity)

        });

        container.append(label);
        container.append(input);

        return container;
    }

    _getPointSymbolizerElms(rule) {

        const sList = rule.getElementsByTagName('se:PointSymbolizer');
        let container = document.createElement('div');

        if (sList.length > 0) {

            container.className = 'form-group p-3 bg-white rounded';
            const symbolizer = sList[0];
            container.append(this._getMarkElm(symbolizer, 'point'));
            return container;

        }

        return document.createElement('div');
    }

    _getLineSymbolizerElms(rule) {

        const sList = rule.getElementsByTagName('se:LineSymbolizer');
        let container = document.createElement('div');

        if (sList.length > 0) {

            container.className = 'form-group p-3 bg-white rounded';

            const symbolizer = sList[0];
            container.append(this._getStrokeElem(symbolizer));
            return container;

        }

        return document.createElement('div');
    }

    _getPolygonSymbolizerElms(rule) {

        const sList = rule.getElementsByTagName('se:PolygonSymbolizer');
        let container = document.createElement('div');

        if (sList.length > 0) {

            container.className = 'form-group p-3 bg-white rounded';
            const symbolizer = sList[0];

            container.append(this._getMarkElm(symbolizer, 'polygon'));
            if (container.innerHTML == '<div></div>') {
                container.append(this._getFillElm(symbolizer));
                container.append(this._getStrokeElem(symbolizer));
            }


            return container;

        }

        return document.createElement('div');
    }

    _getNameElm(rule) {

        let container = document.createElement('div');

        const nameList = rule.getElementsByTagName('se:Name');

        if (nameList.length > 0) {

            container.className = 'form-group p-3 bg-white rounded';

            const name = nameList[0];

            let label = document.createElement('label');
            label.innerHTML = 'Nome';

            let input = document.createElement('input');
            input.className = 'form-control form-control-sm';
            input.value = name.innerHTML;

            container.append(label);
            container.append(input);

            this.dict.push({
                element: input,
                style: name
            });

        }

        return container;

    }

    _getSizeElm(rule) {

        let container = document.createElement('div');
        const nameList = rule.getElementsByTagName('se:Size');

        if (nameList.length > 0) {

            container.className = 'form-group p-3 bg-white rounded';

            const name = nameList[0];

            let label = document.createElement('label');
            label.innerHTML = 'Tamanho';

            let input = document.createElement('input');
            input.className = 'form-control form-control-sm';
            input.type = 'number';
            input.step = 0.1;
            input.min = 0.1;
            input.value = name.innerHTML;

            container.append(label);
            container.append(input);

            this.dict.push({
                element: input,
                style: name
            });

        }

        return container;

    }

    _getScaleElm(rule, tag, title) {

        let container = document.createElement('div');
        const scaleList = rule.getElementsByTagName(tag);

        if (scaleList.length > 0) {

            container.className = 'form-group p-3 bg-white rounded';

            const scale = scaleList[0];

            let label = document.createElement('label');
            label.innerHTML = title;

            let input = document.createElement('input');
            input.className = 'form-control form-control-sm';
            input.type = 'number';
            input.step = 0.1;
            input.min = 0.1;
            input.value = scale.innerHTML;

            container.append(label);
            container.append(input);

            this.dict.push({
                element: input,
                style: scale
            });

        }

        return container;

    }

    _getWellKnownNameMarkElm(doc) {

        const list = doc.getElementsByTagName('se:WellKnownName');
        let container = document.createElement('div');
        container.className = 'form-group';

        if (list.length > 0) {

            const well = list[0];
            container.innerHTML = `
                <label>Tipo do Marcador</label>
                <select class="form-control form-control-sm">
                    <option value="circle">Círculo</option>
                    <option value="square">Quadrado</option>
                    <option value="triangle">Triângulo</option>
                    <option value="star">Estrela</option>
                    <option value="cross">Cruz</option>
                    <option value="x">X</option>
                </select>
            `;

            container.getElementsByTagName('select')[0].value = well.innerHTML;
            this.dict.push({
                element: container.getElementsByTagName('select')[0],
                style: well
            });

        }

        return container;

    }

    _getWellKnownNameMarkFillElm(doc) {

        const list = doc.getElementsByTagName('se:WellKnownName');
        let container = document.createElement('div');
        container.className = 'form-group';

        if (list.length > 0) {

            const well = list[0];
            container.innerHTML = `
                <label>Tipo do Marcador</label>
                <select class="form-control form-control-sm">
                    <option value="shape://vertline">Linha Vertical </option>
                    <option value="shape://horline">Linha Horizontal</option>
                    <option value="shape://slash">Linha Diagonal 1</option>
                    <option value="shape://backslash">Linha Diagonal 2</option>
                    <option value="shape://dot">Círculo Pequeno</option>
                    <option value="shape://plus">+</option>
                    <option value="shape://times">X</option>
                    <option value="shape://oarrow">Seta Aberta</option>
                    <option value="shape://carrow">Seta Fechada</option>
                </select>
            `;

            container.getElementsByTagName('select')[0].value = well.innerHTML;
            this.dict.push({
                element: container.getElementsByTagName('select')[0],
                style: well
            });

        }

        return container;

    }

    _getFillElm(doc) {

        let container = document.createElement('div');
        const fillRule = doc.querySelectorAll('[name="fill"]');
        if (fillRule.length > 0) {

            const fill = fillRule[0];

            let group = document.createElement('div');
            group.className = 'form-group';

            let label = document.createElement('label');
            label.innerHTML = 'Cor Preenchimento';

            let input = document.createElement('input');
            input.type = 'color';
            input.className = 'form-control form-control-sm';
            input.value = fill.innerHTML;

            group.append(label);
            group.append(input);
            container.append(group);

            this.dict.push({
                element: input,
                style: fill
            });

        }

        const fillORule = doc.querySelectorAll('[name="fill-opacity"]');
        if (fillORule.length > 0) {

            const opacity = fillORule[0];

            let group = document.createElement('div');
            group.className = 'form-group';

            let label = document.createElement('label');
            label.innerHTML = 'Opacidade Preenchimento';

            let input = document.createElement('input');
            input.type = 'number';
            input.max = 1;
            input.min = 0;
            input.step = 0.1;
            input.className = 'form-control form-control-sm';
            input.value = opacity.innerHTML;

            group.append(label);
            group.append(input);
            container.append(group);

            this.dict.push({
                element: input,
                style: opacity
            });

        }

        return container;

    }

    _getStrokeElem(doc) {

        let container = document.createElement('div');
        const strokeRule = doc.querySelectorAll('[name="stroke"]');
        if (strokeRule.length > 0) {

            const stroke = strokeRule[0];

            let group = document.createElement('div');
            group.className = 'form-group';

            let label = document.createElement('label');
            label.innerHTML = 'Cor Borda';

            let input = document.createElement('input');
            input.type = 'color';
            input.className = 'form-control form-control-sm';
            input.value = strokeRule[0].innerHTML;

            group.append(label);
            group.append(input);
            container.append(group);

            this.dict.push({
                element: input,
                style: stroke
            });


        }

        const strokeWRule = doc.querySelectorAll('[name="stroke-width"]');
        if (strokeWRule.length > 0) {

            const strokeW = strokeWRule[0];

            let group = document.createElement('div');
            group.className = 'form-group';

            let label = document.createElement('label');
            label.innerHTML = 'Espessura da Borda';

            let input = document.createElement('input');
            input.type = 'number';
            input.step = 0.1;
            input.className = 'form-control form-control-sm';
            input.value = strokeWRule[0].innerHTML;

            group.append(label);
            group.append(input);
            container.append(group);

            this.dict.push({
                element: input,
                style: strokeW
            });


        }

        const dashRule = doc.querySelectorAll('[name="stroke-dasharray"]');
        if (dashRule.length > 0) {

            const dash = dashRule[0];

            let group = document.createElement('div');
            group.className = 'form-group';

            let label = document.createElement('label');
            label.innerHTML = 'Estilo da Linha';

            let input = document.createElement('input');
            input.type = 'text';
            input.className = 'form-control form-control-sm';
            input.value = dashRule[0].innerHTML;

            group.append(label);
            group.append(input);
            container.append(group);

            this.dict.push({
                element: input,
                style: dash
            });


        }

        return container;

    }


    _getMarkElm(symbolizer, p) {

        let container = document.createElement('div');
        const list = symbolizer.getElementsByTagName('se:Mark');

        if (list.length > 0) {

            const markSymb = list[0];
            if (p == 'point') container.append(this._getWellKnownNameMarkElm(markSymb));
            if (p == 'polygon') container.append(this._getWellKnownNameMarkFillElm(markSymb));
            container.append(this._getFillElm(markSymb));
            container.append(this._getStrokeElem(markSymb));

        }

        return container;


    }

    _saveStyleGeowise() {

        let content = styleMap.getModifiedStyles();
        if (content.length > 0) {
            document.getElementById('loader').style.display = 'block';
            var data = {
                content: content,
                target: mapSelect.value
            };
            $.ajax({
                url: '/geowise/sub_camadas/update_estilos',
                type: 'put',
                data: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
                dataType: 'json',
                success: function (data) {

                    document.getElementById('loader').style.display = 'none';

                    if (data.success) {
                        new MessageBox({ title: 'Alteração de estilo', message: 'Alteração de estilo realizada com sucesso!', type: 'success', timeout: 5 }).show();
                    } else {
                        new MessageBox({ title: 'Alteração de estilo', message: 'Não foi possível salvar o estilo.', type: 'danger', timeout: 5 }).show();
                    }

                },
                error: function () {
                    new MessageBox({ title: 'Alteração de estilo', message: 'Não foi possível completar a requisição.', type: 'danger', timeout: 5 }).show();
                }
            });
        }


    }


    _save() {


        for (let j = 0; j < this.dict.length; j++) {
            this.dict[j].style.innerHTML = this.dict[j].element.value;
        }

        if (this.layer) {

            const config = { ...this.layer };
            config.method = 'PUT';
            config.body = this.style;
            config.contentType = 'application/vnd.ogc.se+xml';

            this._ajax(config).then((res) => {

                console.log('deu')

                this._refreshLayer();

            }, reject => console.log(reject))

        }


    }

    _refreshLayer() {

        if (this.map.content[this.i].olLayer) {
            this.map.content[this.i].olLayer.getSource().changed();
        } else {
            this.map.content[this.i].layers[this.j].olLayer.getSource().changed();
        }

    }

    _getLayerStyle(i, j) {

        return new Promise((resolve, reject) => {

            const { workspace, source, auth } = this.map.content[i];
            const layer = this.map.content[i].layers[j].layer;
            const config = {
                url: `${source}/rest/layers/${workspace}:${layer}`,
                accept: 'application/json',
                method: 'GET',
                auth
            };

            this._ajax(config).then((r) => {

                try {


                    const res = JSON.parse(r);
                    const w = res.layer.defaultStyle.workspace;
                    const style = res.layer.defaultStyle.name.split(':')[1];
                    const config = {
                        url: `${source}/rest/workspaces/${w}/styles/${style}`,
                        accept: 'application/vnd.ogc.se+xml',
                        method: 'GET',
                        auth
                    }

                    this._ajax(config).then((xml) => {

                        this.style = this._parseXml(xml);
                        this.layer = config;
                        resolve();

                    }, reject)

                } catch (e) {

                    reject(e);

                }

            }, reject);

        });

    }

    _ajax(config) {

        return new Promise((resolve, reject) => {

            const { method, url, auth, accept, body, contentType } = config;

            let xhr = new XMLHttpRequest();
            xhr.open(method, url);
            xhr.withCredentials = true;
            if (auth) xhr.setRequestHeader('Authorization', auth);
            if (accept) xhr.setRequestHeader('Accept', accept);
            if (contentType) xhr.setRequestHeader('Content-type', contentType);

            xhr.onload = function () {
                resolve(xhr.responseText)
            }

            xhr.onerror = function () {
                reject();
            }

            xhr.send(body);

        });

    }

    _parseXml(xml) {

        var dom = null;
        if (window.DOMParser) {
            try {
                dom = (new DOMParser()).parseFromString(xml, "text/xml");
            }
            catch (e) { dom = null; }
        }
        else if (window.ActiveXObject) {
            try {
                dom = new ActiveXObject('Microsoft.XMLDOM');
                dom.async = false;
                if (!dom.loadXML(xml)) // parse error ..

                    window.alert(dom.parseError.reason + dom.parseError.srcText);
            }
            catch (e) { dom = null; }
        }
        else
            alert("cannot parse xml string!");
        return dom;

    }

}

export { SLDEditor };
