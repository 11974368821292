import { GeoTool } from '../core/GeoTool';

class ZoomByRect extends GeoTool {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Zoom por Região';
        config.class = config.class || 'gb-zoom-rect-control';
        super(config);

        this._drawPromise = null;

    }

    initialize() {

        if (!this.map.toolbox.draw) {
            throw ('DrawControl is not instantiated');
        }

    }

    _setZoom() {

        this._drawPromise = this.map.toolbox.draw.getBox();
        
        this._drawPromise.then((feature) => {

            let geom = feature.getGeometry();
            this.map.ol.getView().fit(geom, this.map.ol.getSize());

            this._setZoom();

        });

    }

    activate() {

        super.activate();
        this._setZoom();
        
    }

    deactivate() {

        this._drawPromise = null;
        this.map.toolbox.draw.stopDrawing();

        super.deactivate();
    }

}

export { ZoomByRect };