import { GeoTool } from '../core/GeoTool';

class CutGeom extends GeoTool {

    //POC Gov Celso Ramos

    constructor(config) {
        config = config || {};
        config.tip = config.tip || 'Cortar geometria';
        config.class = config.class || 'gb-edit-geom';
        config.direction = config.direction || 'x';
        super(config);

    }

    initialize() { };

    activate() {

        super.activate();

        this.map.toolbox.draw.getPolyline().then((line) => {

            let format = new ol.format.GeoJSON();
            let jLine = format.writeFeatureObject(line, {
                dataProjection: 'EPSG:4326',
                featureProjection: this.map.ol.getView().getProjection().getCode()

            });
            let buffered = turf.buffer(jLine, 0.001, { units: 'meters' });

            let layers = this.map.ol.getLayers().getArray();

            layers.forEach(layer => {

                if (layer.getSource() instanceof ol.source.Vector) {

                    let oFeatures = layer.getSource().getFeatures();

                    oFeatures.forEach(oFeature => {

                        let jFeature = format.writeFeatureObject(oFeature, {
                            dataProjection: 'EPSG:4326',
                            featureProjection: this.map.ol.getView().getProjection().getCode()

                        });

                        turf.geomEach(jFeature, currentGeometry => {


                            let diff = turf.difference(currentGeometry, buffered);
                            if (diff) {

                                let tFeature = format.readFeatureFromObject(diff, {
                                    dataProjection: 'EPSG:4326',
                                    featureProjection: this.map.ol.getView().getProjection().getCode()
                                });

                                oFeature.setGeometry(tFeature.getGeometry());
                            }

                        });

                    });

                }

            });
        });

    }

    deactivate() {

        this.map.mapElement.style.cursor = 'auto';
        this.map.ol.removeInteraction(this.select);
        this.select = null;
        super.deactivate();

    }
}

export { CutGeom };