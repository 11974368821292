import { GeoWidget } from '../core/GeoWidget';

class ParallelDrag extends GeoWidget {

    constructor(config) {
      config.source = config.source || new ol.source.Vector();
      config.tip = config.tip || 'Arrastar aresta paralelamente';
      config.title = config.title || 'Arrastar aresta paralelamente';
      config.class = config.class || 'gb-set-edge-size';
      config.defaultDistance = config.defaultDistance || 1;
      super(config);
      this._source = config.source;
      this._selectClick = null;
      this._selectedFeature = null;
      this._btnTransform = null;
      this._btnAjust = null;
      this._segmentIndex = null;
      this._guideFeature = null;
      this._minDist = Number.MAX_VALUE;
      this.ui = this._getUiTemplate();
    }

    initialize() {
      this.on('ready', () => {
          this._registerElements();
          this._registerEvents();
          this._initInternalLayer();
      });
    };

    activate() {
      this.show();
      this._addSelectInteraction();
      document.getElementById(this.map.elementId).style.cursor = 'pointer';
    }

    _initInternalLayer() {
      this._paralelLayer = new ol.layer.Vector({
          source: new ol.source.Vector(),
          style: new ol.style.Style({
              fill: new ol.style.Fill({
                  color: 'rgba(0, 255, 255, 0.2)'
              }),
              stroke: new ol.style.Stroke({
                  color: '#0ff',
                  width: 2
              }),
              image: new ol.style.Circle({
                  radius: 7,
                  fill: new ol.style.Fill({
                      color: '#0ff'
                  })
              })
          })
      });

      this._paralelLayer.setZIndex(9998);
      map.ol.addLayer(this._paralelLayer);
    }

    _getUiTemplate() {

      return `
        <div class='m-0 p-3 d-flex flex-column'>
          <p class='gb-measure-tip'>Selecione a aresta para arrastar.</p>
          <p class='gb-parallel-tip' id="gb-p-info-${this.id}">Aresta Selecionada</p>
          <button id="gb-btn-edit-${this.id}" class="btn btn-dark btn-block btn-sm">Criar Guia</button>
          <button id="gb-btn-ajust-${this.id}" class="btn btn-dark btn-block btn-sm">Ajustar</button>
        </div>
      `;
    }

    _registerElements() {
      this._btnTransform = document.getElementById(`gb-btn-edit-${this.id}`);
      this._btnAjust = document.getElementById(`gb-btn-ajust-${this.id}`);
      document.getElementById(`gb-p-info-${this.id}`).style.display = 'none';
    }

    _registerEvents() {
      this._btnTransform.addEventListener('click', () => this._transformFeature());
      this._btnAjust.addEventListener('click', () => this._ajustFeature());
    }

    _addSelectInteraction() {
      this._selectClick = new ol.interaction.Select({
        condition: ol.events.condition.click,
        multi: false,
        hitTolerance: 5,
      });

      this._selectClick.on('select', (e) => this._getSegment(e));

      this.map.ol.addInteraction(this._selectClick);
    }

    _getSegment(e) {
      const feature = e.selected[0];
      let polygon = null;

      if (!feature) return;

      if (feature.getGeometry().getType() === 'Polygon') {
        polygon = turf.polygon(feature.getGeometry().transform(this.map.srid, 'EPSG:4326').getCoordinates());
      }

      if (!polygon) return;
      
      const clickPoint =  turf.point(ol.proj.transform(e.mapBrowserEvent.coordinate, this.map.srid, 'EPSG:4326'));

      turf.segmentEach(polygon, (segment, featureIndex, multiFeatureIndex, geometryIndex, index) => {
        const dist = turf.pointToLineDistance(clickPoint, segment, { units: 'kilometers' });
        
        if (dist < this._minDist) {
          this._minDist = dist;
          this._segmentIndex = index;
        }
      });

      if (this._minDist < 0.004) {
        document.getElementById(`gb-p-info-${this.id}`).style.display = 'block';
      } else {
        document.getElementById(`gb-p-info-${this.id}`).style.display = 'none';
      }

      feature.getGeometry().transform('EPSG:4326', this.map.srid);
      
      this._minDist = Number.MAX_VALUE;
      this._selectedFeature = feature;
      this._selectClick.getFeatures().remove(feature);
    }

    _transformFeature() {
      if (!this._selectedFeature) return;

      const polygon = turf.polygon(this._selectedFeature.getGeometry().getCoordinates());
      const segments = turf.lineSegment(polygon);

      const [p1, p2] = segments.features[this._segmentIndex].geometry.coordinates;

      let extent = this.map.ol.getView().calculateExtent();
      let dist = this._plainDistance([extent[0], extent[1]], [extent[2], extent[3]]);

      let az = this._getAzimuth(p1, p2);

      let p1_ = this._pointAzDist(p1, az, dist);
      let p2_ = this._pointAzDist(p1, az + Math.PI, dist);

      this._guideFeature = this._createOlLineFeature([p1_, p2_], this._paralelLayer.getSource());
    }

    _ajustFeature() {
      if (!this._selectedFeature) return;

      const polygon = turf.polygon(this._selectedFeature.getGeometry().getCoordinates());
      const segments = turf.lineSegment(polygon);

      const next = (this._segmentIndex + 1 > segments.features.length - 1) ? 0 : this._segmentIndex + 1;
      const prev = (this._segmentIndex - 1 < 0) ? segments.features.length - 1 : this._segmentIndex - 1;

      const [l1, l2] = segments.features[prev].geometry.coordinates;
      const [l1_, l2_] = segments.features[next].geometry.coordinates;
      const [ponto1, ponto2] = segments.features[this._segmentIndex].geometry.coordinates;

      let extent = this.map.ol.getView().calculateExtent();
      let dist = this._plainDistance([extent[0], extent[1]], [extent[2], extent[3]]);

      let az = this._getAzimuth(l1, l2);
      let p1_ = this._pointAzDist(l1, az, dist);
      let p2_ = this._pointAzDist(l1, az + Math.PI, dist);

      az = this._getAzimuth(l1_, l2_);
      let p1 = this._pointAzDist(l1_, az, dist);
      let p2 = this._pointAzDist(l1_, az + Math.PI, dist);

      const lineGuide = turf.lineString([
        this._guideFeature.getGeometry().getCoordinates()[0], 
        this._guideFeature.getGeometry().getCoordinates()[1]
      ]);

      const line1 = turf.lineString([p1_, p2_]);
      const line2 = turf.lineString([p1, p2]);

      const intersect1 = turf.lineIntersect(lineGuide, line1);
      const intersect2 = turf.lineIntersect(lineGuide, line2);

      const newCoords = [];

      polygon.geometry.coordinates[0].forEach(coord => {
        if (coord[0] === ponto2[0] && coord[1] === ponto2[1]) {
          newCoords.push(intersect2.features[0].geometry.coordinates);
        } else if (coord[0] === ponto1[0] && coord[1] === ponto1[1]) {
          newCoords.push(intersect1.features[0].geometry.coordinates);
        } else {
          newCoords.push(coord);
        }
      });

      this._selectedFeature.getGeometry().setCoordinates([newCoords]);
    }

    _plainDistance(p1, p2) {
      return Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));
    }

    _getAzimuth(p1, p2) {
      let azimuth = Math.atan2(p2[0] - p1[0], p2[1] - p1[1]);
      azimuth = azimuth < 0 ? azimuth + (2 * Math.PI) : azimuth;

      return azimuth;
    }

    _pointAzDist(firstPoint, az, dist) {
      let x = firstPoint[0] + Math.sin(az) * dist;
      let y = firstPoint[1] + Math.cos(az) * dist;

      return [x, y];
    }

    _createOlLineFeature(lineArray, source) {

      let feature = new ol.Feature();
      let line = new ol.geom.LineString(lineArray);
      feature.setGeometry(line);
      source.addFeature(feature);
      return feature;
    }

    deactivate() {
      document.getElementById(this.map.elementId).style.cursor = 'auto';
      this.map.ol.removeInteraction(this._selectClick);
      this._paralelLayer.getSource().clear();
      this._selectClick = null;
      this.hide();
    }
}

export { ParallelDrag };