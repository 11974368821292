import { GeoControl } from './GeoControl';

class GeoButton extends GeoControl {

	constructor(config) {
		super(config);
	}

}

export { GeoButton };