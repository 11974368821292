import { GeoButton } from '../core/GeoButton';

class GoToHome extends GeoButton {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Ir para o início';
        config.class = config.class || 'gb-home-control';
        super(config);
    }

    initialize() { }

    click() {

        let zoom = this.map.config.initialZoom || 15;
        let center = [0, 0];

        if (this.map.config.center) {
            center = ol.proj.transform(this.map.config.center.coordinates, this.map.config.center.srid || 'EPSG:4326', this.map.ol.getView().getProjection().getCode());
        }

        this.map.ol.getView().animate({
            center: center,
            zoom: zoom,
            duration: 1000
        });

    }

}

export { GoToHome };