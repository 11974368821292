import { GeoWidget } from '../core/GeoWidget';

class Measure extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Medir';
        config.title = config.title || 'Medir';
        config.class = config.class || 'gb-measure-line-control';
        config.geometryType = config.geometryType || 'linestring';
        super(config);

        this._pointBtnElement = null;
        this._lineBtnElement = null;
        this._polygonBtnElement = null;
        this._azimuthBtnElement = null;
        this._tipElement = null;
        this._textContainer = null;

        this._tipMsg = '';
        this._initialMsg = 'Selecione uma ferramenta';

        this._layer = null;
        this._overlays = [];
        this._pointStyle = null;
        this._lineStyle = null;
        this._polygontStyle = null;

        this.ui = this.builUi();

    }

    initialize() {

        this.on('ready', () => {

            this._registerEvents();

        });

     }

    _initLayer() {

        this._layer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#000',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#000'
                    })
                })
            })
        });

        this._layer.setZIndex(999);
        this.map.ol.addLayer(this._layer);

    }

    _initSefaultStyles() {

        this._pointStyle = new ol.style.Style({
            image: new ol.style.Circle({
                radius: 5,
                fill: new ol.style.Fill({
                    color: 'rgba(255,255,255,0.5)',
                }),
                stroke: new ol.style.Stroke({
                    color: '#F0F',
                    width: 2
                })
            })
        });

        this._lineStyle = new ol.style.Style({
            fill: new ol.style.Fill({
                color: 'rgba(0, 255, 255, 0.5)'
            }),
            stroke: new ol.style.Stroke({
                color: 'cyan',
                width: 1.5
            })
        });

        this._polygonStyle = new ol.style.Style({
            fill: new ol.style.Fill({
                color: 'rgba(255,255,255,0.5)',
            }),
            stroke: new ol.style.Stroke({
                color: '#F0F',
                width: 2
            })
        });



    }


    builUi() {

        let measureElement = document.createElement('div');
        measureElement.className = 'gb-measure-content';

        let btnsContainer = document.createElement('div');
        btnsContainer.style.display = 'flex';

        this._tipMsg = this._initialMsg;
        this._textContainer = document.createElement('div');
        this._textContainer.className = 'gb-measure-tip';
        this._textContainer.innerHTML = this._tipMsg;


        this._pointBtnElement = document.createElement('div');
        this._pointBtnElement.className = 'gb-measure-btn gb-measure-point';

        this._lineBtnElement = document.createElement('div');
        this._lineBtnElement.className = 'gb-measure-btn gb-measure-line';

        this._polygonBtnElement = document.createElement('div');
        this._polygonBtnElement.className = 'gb-measure-btn gb-measure-polygon';

        this._azimuthBtnElement = document.createElement('div');
        this._azimuthBtnElement.className = 'gb-measure-btn gb-measure-azimuth';

        this._clearBtnElement = document.createElement('div');
        this._clearBtnElement.className = 'gb-measure-btn gb-measure-clear';

        btnsContainer.appendChild(this._pointBtnElement);
        btnsContainer.appendChild(this._lineBtnElement);
        btnsContainer.appendChild(this._polygonBtnElement);
        btnsContainer.appendChild(this._azimuthBtnElement);
        btnsContainer.appendChild(this._clearBtnElement);
        measureElement.appendChild(btnsContainer);
        
        measureElement.appendChild(this._textContainer);
        //measureElement.appendChild(this._clearBtnElement);

        return measureElement;

    }

    _registerEvents() {

        // Click
        this._pointBtnElement.addEventListener('click', () => {

            this._tipMsg = 'Clique no mapa para obter um ponto';
            this.map.toolbox.draw.getPoint().then((point) => {

                this._tipMsg = this._initialMsg;
                this._textContainer.innerHTML = this._tipMsg;
                this._overlays.push(this.map.geodesc.pointDescriptor(point.getGeometry()));
                this._layer.getSource().addFeature(point);

            });

        });

        this._lineBtnElement.addEventListener('click', () => {

            this._tipMsg = 'Clique no mapa para desenhar uma linha';
            this.map.toolbox.draw.getPolyline().then((line) => {

                this._tipMsg = this._initialMsg;
                this._textContainer.innerHTML = this._tipMsg;
                this._overlays.push(this.map.geodesc.lineDescriptor(line.getGeometry()));
                this._layer.getSource().addFeature(line);

            });

        });

        this._polygonBtnElement.addEventListener('click', () => {

            this._tipMsg = 'Clique no mapa para obter um polígono';
            this.map.toolbox.draw.getPolygon().then((polygon) => {

                this._tipMsg = this._initialMsg;
                this._textContainer.innerHTML = this._tipMsg;
                this._overlays.push(this.map.geodesc.polygonDescriptor(polygon.getGeometry()));
                this._layer.getSource().addFeature(polygon);

            });

        });

        this._azimuthBtnElement.addEventListener('click', () => {

            this._tipMsg = 'Clique no mapa para desenhar uma direção';
            this.map.toolbox.draw.getPolyline().then((pline) => {

                this._tipMsg = this._initialMsg;
                this._textContainer.innerHTML = this._tipMsg;
                this._overlays.push(this.map.geodesc.azimuthDescriptor(pline.getGeometry()));
                this._layer.getSource().addFeature(pline);

            });

        });

        this._clearBtnElement.addEventListener('click', () => {

            this._clearOverlays();
            this._clearGeoms();

        });


        // MouseOver
        this._pointBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Obter coordenadas';
            this._textContainer.style.display = 'block';

        });

        this._lineBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Medir distâncias';
            this._textContainer.style.display = 'block';

        });

        this._polygonBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Medir áreas';
            this._textContainer.style.display = 'block';

        });

        this._azimuthBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Obter azimute';
            this._textContainer.style.display = 'block';

        });

        this._clearBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Limpar medidas';
            this._textContainer.style.display = 'block';

        });

        // MouseOut
        this._pointBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._lineBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._polygonBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._azimuthBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._clearBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

    }

    _clearOverlays(){

        this.map.ol.getOverlays().clear();

    }

    _clearGeoms(){

        this._layer.getSource().clear();

    }

    activate() {

        this._initSefaultStyles();
        this._initLayer();
        this.show();

    }

    deactivate() {

        this._clearOverlays();
        this._clearGeoms();
        this.hide();
    }

}

export { Measure };