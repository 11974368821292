import { GeoWidget } from '../core/GeoWidget';

class SetEdgeSize extends GeoWidget {

    constructor(config) {
      config.source = config.source || new ol.source.Vector();
      config.tip = config.tip || 'Ajustar tamanho da aresta';
      config.title = config.title || 'Ajustar tamanho da aresta';
      config.class = config.class || 'gb-set-edge-size';
      super(config);
      this._source = config.source;
      this._selectClick = null;
      this._selectedFeature = null;
      this._btnTransform = null;
      this._stateHistory = config.stateHistory,
      this._segmentIndex = null;
      this._minDist = Number.MAX_VALUE;
      this.ui = this._getUiTemplate();
    }

    initialize() {
      this.on('ready', () => {
          this._registerElements();
          this._registerEvents();
      });
    };

    activate() {
      this.show();
      this._addSelectInteraction();
      document.getElementById(this.map.elementId).style.cursor = 'pointer';
    }

    _getUiTemplate() {

      return `
        <div class='m-0 p-3 d-flex flex-column'>
          <p>Selecione a aresta para editar.</p>
          <div>
            <input id="gb-input-size-${this.id}" type="number" min="0" step=".001" />
            <span>Km</span>
          </div>
          <br />
          <button id="gb-btn-edit-${this.id}" class="btn btn-primary">Editar</button>
        </div>
      `;
    }

    _registerElements() {
      this._btnTransform = document.getElementById(`gb-btn-edit-${this.id}`);
    }

    _registerEvents() {
      this._btnTransform.addEventListener('click', () => this._transformFeature());
    }

    _addSelectInteraction() {
      this._selectClick = new ol.interaction.Select({
        condition: ol.events.condition.click,
        multi: false,
        hitTolerance: 5,
      });

      this._selectClick.on('select', (e) => this._getSegment(e));

      this.map.ol.addInteraction(this._selectClick);
    }

    _getSegment(e) {
      const feature = e.selected[0];
      let polygon = null;

      if (!feature) return;

      if (feature.getGeometry().getType() === 'Polygon') {
        polygon = turf.polygon(feature.getGeometry().transform(this.map.srid, 'EPSG:4326').getCoordinates());
      } 
      else if (feature.getGeometry().getType() === 'LineString') {
        polygon = turf.lineString(feature.getGeometry().transform(this.map.srid, 'EPSG:4326').getCoordinates());
      }

      if (!polygon) return;
      
      const clickPoint =  turf.point(ol.proj.transform(e.mapBrowserEvent.coordinate, this.map.srid, 'EPSG:4326'));
      let length = 0;
      let segment2 = null;

      turf.segmentEach(polygon, (segment, featureIndex, multiFeatureIndex, geometryIndex, index) => {
        const dist = turf.pointToLineDistance(clickPoint, segment, { units: 'kilometers' });
        
        if (dist < this._minDist) {
          this._minDist = dist;
          this._segmentIndex = index;

          const c1 = ol.proj.transform(segment.geometry.coordinates[0], 'EPSG:4326', this.map.srid);
          const c2 = ol.proj.transform(segment.geometry.coordinates[1], 'EPSG:4326', this.map.srid);

          length = this._distance(c1, c2);
        }
      });

      feature.getGeometry().transform('EPSG:4326', this.map.srid);

      if (this._minDist < 0.004) {
        document.getElementById(`gb-input-size-${this.id}`).value = Math.round(length * 1000) / 1000;
      } else {
        document.getElementById(`gb-input-size-${this.id}`).value = 0;
      }
      
      this._minDist = Number.MAX_VALUE;
      this._selectedFeature = feature;
      this._selectClick.getFeatures().remove(feature);
    }

    _transformFeature() {
      if (!this._selectedFeature) return;

      const dist = document.getElementById(`gb-input-size-${this.id}`).value;

      let polygon = null;

      if (this._selectedFeature.getGeometry().getType() === 'Polygon') {
        polygon = turf.polygon(this._selectedFeature.getGeometry().getCoordinates());
      } 
      else if (this._selectedFeature.getGeometry().getType() === 'LineString') {
        polygon = turf.lineString(this._selectedFeature.getGeometry().getCoordinates());
      }

      if (!polygon) return;

      const segments = turf.lineSegment(polygon);

      const [p1, p2] = segments.features[this._segmentIndex].geometry.coordinates;
      const azimuth = this._azimuthBetweenPoints(p1, p2);
      const newPoint = this._pointAzDist(p1, azimuth, dist);

      const newCoords = [];

      if (polygon.geometry.type === 'LineString') {
        polygon.geometry.coordinates.forEach(coord => {
          (coord[0] === p2[0] && coord[1] === p2[1]) ? newCoords.push(newPoint) : newCoords.push(coord);
        });

        this._selectedFeature.getGeometry().setCoordinates(newCoords);
      }
      else if (polygon.geometry.type === 'Polygon') {
        polygon.geometry.coordinates[0].forEach(coord => {
          (coord[0] === p2[0] && coord[1] === p2[1]) ? newCoords.push(newPoint) : newCoords.push(coord);
        });

        this._selectedFeature.getGeometry().setCoordinates([newCoords]);
      }
    }

    _azimuthBetweenPoints(p1, p2) {
      return Math.atan2(p2[0] - p1[0], p2[1] - p1[1]);
    }

    _pointAzDist(p0, az, dist) {
      let x = p0[0] + dist * Math.sin(az);
      let y = p0[1] + dist * Math.cos(az);

      return [x, y];
    }

    _distance(p1, p2) {
      return Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));
    }

    deactivate() {
      document.getElementById(this.map.elementId).style.cursor = 'auto';
      this.map.ol.removeInteraction(this._selectClick);
      this._selectClick = null;
      document.getElementById(`gb-input-size-${this.id}`).value = 0;
      this.hide();
    }
}

export { SetEdgeSize };