import { GeoWidget } from '../core/GeoWidget';

class GoToCoordinate extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Ir para coordenada';
        config.title = config.title || 'Ir para coordenadas';
        config.class = config.class || 'go-to-control';
        config.maxWidth = '550px';
        super(config);

        this._source = config.source || new ol.source.Vector();
        this._isListening = false;
        this._lastPoint = null;
        this._feature = new ol.Feature(null);
        this._mouseFeature = new ol.Feature(null);
        this._pointsList = [];

        this.ui = document.createElement('div');

    }

    initialize() {

        this.ui.innerHTML = this._getUiTemplate();

        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();
            this._initInternalLayer();

            this._selectSrcElement.value = this.map.ol.getView().getProjection().getCode();

        });

    }

    _initInternalLayer() {

        this._layer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 0, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#f0f',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#f0f'
                    })
                })
            })
        });

        this._layer.setZIndex(999);
        this.map.ol.addLayer(this._layer);

    }

    _getUiTemplate() {

        let srs = [];
        for (let i = 0; i < this.map.config.srs.length; i++) {
            const element = this.map.config.srs[i];
            if (element.format == 'DD' || element.type == 'projected') {
                srs.push(element)
            }

        }

        return `
        <div class='m-0 p-3'>

            <div class="row">

             <div class="col-12">
                    <p>Adicione um par de coordenadas planas "E, N" (metros) ou geográfica "Latitude Longitude" (deg) separando as coordenadas com um espaço.</p>
                </div>
        
                <div class="col-12">
                    <label for="gb-pbi-wkt-${this.id}">Par de coordenadas</label>
                    <input type="text" class="form-control" id="gb-pbi-wkt-${this.id}" placeholder="Adicione as coordenadas">
                </div>
        
                <div class="col-6">
                    <label for="gb-pbi-src-${this.id}">SRC</label>
                    <select id="gb-pbi-src-${this.id}" class="form-control">
        ${srs.map(wkid => (
            `<option value="${wkid.code}">${wkid.name}</option>`
        ))}
                    </select>
                </div>
        
                <div class="col-6">
                    <label style="color: #fff"> _</label>
                    <br>
                    <button id="gb-pbi-add-wkt-${this.id}" class="btn btn-primary btn-block btn-small">Adicionar</button>
                </div>
            </div>
        </div>
        `;

    }

    _registerElements() {

        this._inputWktElement = document.getElementById(`gb-pbi-wkt-${this.id}`);
        this._btnAddWktElement = document.getElementById(`gb-pbi-add-wkt-${this.id}`);
        this._selectSrcElement = document.getElementById(`gb-pbi-src-${this.id}`);

    }

    _format(wkt) {

        let repetidos = [...wkt.match(/(.)(?=.*\1)/gi)].map(x => x.toLowerCase());

        if (repetidos.indexOf('.') > -1 &&
            repetidos.indexOf(',') == -1 &&
            wkt.indexOf(',') > -1) {

            wkt = wkt.replace(',', ' ');

        } else if (repetidos.indexOf(',') > -1 &&
            wkt.indexOf('.') == -1) {

            wkt = wkt.replace(',', '.').replace(',', '.');

        }

        for (let i = 0; i < this.map.config.srs.length; i++) {

            const srs = this.map.config.srs[i];
            const coordSRS = this._selectSrcElement.value;

            if (coordSRS == srs.code) {
                if (srs.format == 'DD') {
                    wkt = wkt.split(' ').reverse().join(' ');
                }
            }

        }

        return wkt;
    }

    _createFromWkt(wkt) {
        if(wkt==""){

            new Notify({ message: 'Não foi possível reconhecer a geometria desejada.<br> Formato inadequado.', 
            type: 'success', 
            timeout: 5 }).show();
       }      
        try {
            wkt = this._format(wkt);

            let format = new ol.format.WKT();

            let feature = format.readFeature('POINT(' + wkt + ')', {
                dataProjection: this._selectSrcElement.value || 'EPSG:4326',
                featureProjection: this.map.ol.getView().getProjection().getCode()
            });

            this._layer.getSource().addFeature(feature);

            this.map.fitTo(feature.getGeometry(), 'openlayers')

        } catch (e) {

            new Notify({ message: 'Não foi possível reconhecer a geometria desejada.<br> Formato inadequado.', type: 'success', timeout: 5 }).show();

        }     
    
    }


    _registerEvents() {

        this._btnAddWktElement.addEventListener('click', () => {

            let wkt = this._inputWktElement.value;
            this._createFromWkt(wkt);

        });

    }

    activate() {
        

        this.map.closeAllTools();
        this.show();

    }

    deactivate() {

        this._layer.getSource().clear();
        this.hide();

    }

}
export { GoToCoordinate };