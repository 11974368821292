import { GeoWidget } from '../core/GeoWidget';
import Source from 'ol/source/Source';

class Snap extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.position = config.position || 'rt';
        config.class = config.class || 'gb-snap-control';
        config.tip = config.tip || 'Configuração do Snap';
        config.title = config.title || 'Configuração do Snap';
        super(config);
        this._scale = config.minScale || 250;
        this._sources = [];
        this._layers = [];
        this._snaps = [];
        this._format = null;
        this._units = 'px';
        this._tolerance = 50;
        this._toEdge = true;
        this._tovertex = true;
        this._maxResolution = 0;
        this.ui = document.createElement('div');
        this.ui.style.width = '100%';
        this.ui.style.height = '100%';

        this.on('ready', () => {

            this.ui.innerHTML = this._buildUi();
            this._listLayers();
            this._bindUi();
            this._registerEvents();

        });

    }

    initialize() {

        this._initFormat();
        this._createSources();
        this._initAuxLayers();
        this._addInteraction();
        this._initSelects();
        this._registerExternalInteraction();

    }

    _buildUi() {

        return `
        <div class="p-3">
            <div class="form-group">
                <label>Camadas</label>
                <div id="gb-snap${this.id}-layers">
                    ${this._listLayers()}
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <label>Tolerância</label>
                        <input type="number" class="form-control" id="gb-snap${this.id}-tol">
                    </div>
                    <div class="col">
                        <label>Unidade</label>
                        <select class="form-control" id="gb-snap${this.id}-unit">
                            <option value="m" selected>metro(s)</option>
                            <option value="px" >pixel(s)</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label>Escala máxima</label>
                <select class="form-control" id="gb-snap${this.id}-scales">
                    <option value="250" selected>1:250</option>
                    <option value="500">1:500</option>
                    <option value="1000">1:1000</option>
                    <option value="5000">1:5000</option>
                    <option value="10000">1:10000</option>
                    <option value="1000000">1:1000000</option>
                </select>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Tipo Snap</label>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-snap${this.id}-vertice">
                            <label class="form-check-label" for="gb-snap${this.id}-vertice">
                            Vértice
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-snap${this.id}-edge">
                            <label class="form-check-label" for="gb-snap${this.id}-edge">
                            Segmento
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-snap${this.id}-extention">
                            <label class="form-check-label" for="gb-snap${this.id}-extention">
                            Prolongamento
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-snap${this.id}-centroid">
                            <label class="form-check-label" for="gb-snap${this.id}-centroid">
                            Centróide
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-snap${this.id}-midpoint">
                            <label class="form-check-label" for="gb-snap${this.id}-midpoint">
                            Ponto Médio
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-snap${this.id}-intersection">
                            <label class="form-check-label" for="gb-snap${this.id}-intersection">
                            Intersecções
                            </label>
                        </div>
                        <div class="form-check d-none">
                            <input class="form-check-input" type="checkbox" value="" id="gb-snap${this.id}-orto">
                            <label class="form-check-label" for="gb-snap${this.id}-orto">
                            Linhas ortogonais
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Elementos Auxiliares</label>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="gb-snap${this.id}-show-lines">
                            <label class="form-check-label" for="gb-snap${this.id}-show-lines">
                                Mostrar
                            </label>
                        </div>
                    </div>
                </div>   
            </div>

            <a href="#" class="btn btn-dark float-right mb-3" role="button" id="gb-snap${this.id}-save">Aplicar configurações</a>

        </div>
        `;
    }

    _initAuxLayers() {

        this._auxLayers = {
            extendSegments: new ol.layer.Vector({
                source: new ol.source.Vector(),
                style: new ol.style.Style({
                    stroke: new ol.style.Stroke({
                        width: 1,
                        color: 'rgba(255, 0, 255, 0.1)',
                        lineDash: [2, 5]
                    }),
                })
            }),
            intersections: new ol.layer.Vector({
                source: new ol.source.Vector(),
                style: new ol.style.Style({
                    image: new ol.style.RegularShape({
                        stroke: new ol.style.Stroke({
                            width: 1,
                            color: 'rgba(255, 0, 255, 0.1)',
                        }),
                        points: 4,
                        radius: 5,
                        radius2: 0,
                        rotation: 0,
                        angle: 0,
                    }),
                })
            }),
            midPoints: new ol.layer.Vector({
                source: new ol.source.Vector(),
                style: new ol.style.Style({
                    image: new ol.style.RegularShape({
                        stroke: new ol.style.Stroke({
                            width: 1,
                            color: 'rgba(255, 0, 255, 0.1)',
                        }),
                        points: 3,
                        radius: 5,
                        rotation: 0,
                        angle: 0,
                    }),
                })
            }),
            centroids: new ol.layer.Vector({
                source: new ol.source.Vector(),
                style: new ol.style.Style({
                    image: new ol.style.Circle({
                        radius: 5,
                        stroke: new ol.style.Stroke({
                            width: 1,
                            color: 'rgba(255, 0, 255, 0.1)'
                        })
                    })
                })
            }),
            orthoLines: new ol.layer.Vector({
                source: new ol.source.Vector(),
            })
        };

        this.map.ol.addLayer(this._auxLayers.extendSegments);
        this.map.ol.addLayer(this._auxLayers.intersections);
        this.map.ol.addLayer(this._auxLayers.midPoints);
        this.map.ol.addLayer(this._auxLayers.centroids);
        this.map.ol.addLayer(this._auxLayers.orthoLines);

    }

    _initSelects() {

        let resolution = this.map.ol.getView().getResolution();
        let pixelTolerance = this._units === 'px' ? parseInt(this._tolerance) : Math.round(parseInt(this._tolerance) / resolution);

        this._select = {
            extendSegments: new ol.interaction.Select({
                condition: ol.events.condition.pointerMove,
                multi: true,
                layers: [
                    this._auxLayers.extendSegments
                ],
                hitTolerance: pixelTolerance,
                style: new ol.style.Style({
                    stroke: new ol.style.Stroke({
                        width: 1,
                        color: 'rgb(255, 0, 255)',
                        lineDash: [2, 5] //or other combinations
                    }),
                })
            }),
            intersections: new ol.interaction.Select({
                condition: ol.events.condition.pointerMove,
                multi: true,
                layers: [
                    this._auxLayers.intersections
                ],
                hitTolerance: pixelTolerance,
                style: new ol.style.Style({
                    image: new ol.style.RegularShape({
                        stroke: new ol.style.Stroke({
                            width: 1,
                            color: 'rgba(255, 0, 255, 1)',
                        }),
                        points: 4,
                        radius: 5,
                        radius2: 0,
                        rotation: 0,
                        angle: 0,
                    }),
                })
            }),
            midPoints: new ol.interaction.Select({
                condition: ol.events.condition.pointerMove,
                multi: true,
                layers: [
                    this._auxLayers.midPoints
                ],
                hitTolerance: pixelTolerance,
                style: new ol.style.Style({
                    image: new ol.style.RegularShape({
                        stroke: new ol.style.Stroke({
                            width: 1,
                            color: 'rgba(255, 0, 255, 1)',
                        }),
                        points: 3,
                        radius: 5,
                        rotation: 0,
                        angle: 0,
                    }),
                })
            }),
            centroids: new ol.interaction.Select({
                condition: ol.events.condition.pointerMove,
                multi: true,
                layers: [
                    this._auxLayers.centroids
                ],
                hitTolerance: pixelTolerance,
                style: new ol.style.Style({
                    image: new ol.style.Circle({
                        radius: 5,
                        stroke: new ol.style.Stroke({
                            width: 1,
                            color: 'rgba(255, 0, 255, 1)'
                        })
                    })
                })
            })
        };
    }

    _listLayers() {

        let content;
        let layers = '';
        layers += `
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="internal" group="-1" layer="-1" id="gb-snap${this.id}-internal" checked>
            <label class="form-check-label" for="gb-snap${this.id}-internal">
                Camadas de edição
            </label>
        </div>`;

        for (let i = 0; i < this.map.content.length; i++) {

            content = this.map.content[i];

            if (content.type == 'ogc' || content.type == 'wfs') {

                for (var k = 0; k < content.layers.length; k++) {
                    //if (content.layers[k].snap) {

                    layers += `
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" group="${i}" layer="${k}" id="gb-snap${this.id}-${i}-${k}">
                            <label class="form-check-label" for="gb-snap${this.id}-${i}-${k}">
                            ${content.name}:${content.layers[k].name}
                            </label>
                        </div>`;


                    //}
                }
            }
        }

        if (layers === '') {
            layers = 'Nenhuma camada disponível para snap.';
        }

        return layers;

    }

    _bindUi() {

        // Layers
        let content;
        for (let i = 0; i < this.map.content.length; i++) {

            content = this.map.content[i];

            if (content.type == 'ogc' || content.type == 'wfs') {

                for (var k = 0; k < content.layers.length; k++) {

                    document.getElementById(`gb-snap${this.id}-${i}-${k}`).checked = content.layers[k].snap;

                }
            }
        }

        document.getElementById(`gb-snap${this.id}-tol`).value = this._tolerance;
        document.getElementById(`gb-snap${this.id}-unit`).value = this._units;
        document.getElementById(`gb-snap${this.id}-scales`).value = this._scale;
        document.getElementById(`gb-snap${this.id}-vertice`).checked = this._tovertex;
        document.getElementById(`gb-snap${this.id}-edge`).checked = this._toEdge;
        document.getElementById(`gb-snap${this.id}-extention`).checked = this._toExtention;
        document.getElementById(`gb-snap${this.id}-centroid`).checked = this._toCentroid;
        document.getElementById(`gb-snap${this.id}-midpoint`).checked = this._toMidPoint;
        document.getElementById(`gb-snap${this.id}-intersection`).checked = this._toIntersection;
        document.getElementById(`gb-snap${this.id}-orto`).checked = this._toOrto;

    }

    _initFormat() {

        this._format = new ol.format.GeoJSON({
            featureProjection: 'EPSG:4326'
        });
        // Força leitura de geojson fornecido pelo geoserver
        this._format.readProjectionFromObject = function (object) {
            var geoJSONObject = object;
            var crs = geoJSONObject.crs;
            var projection;
            if (crs) {
                if (crs.type == 'name') {
                    projection = ol.proj.get(crs.properties.name);
                } else if (crs.type == 'EPSG') {
                    projection = ol.proj.get('EPSG:' + crs.properties.code);
                } else {
                    assert(false, 36);
                }
            } else {
                projection = ol.proj.get(this.map.srid);
            }

            return projection;
        };
    }

    _createSources() {

        let content;
        let subLayers = [];
        this._sources = [];

        for (let i = 0; i < this.map.content.length; i++) {

            content = this.map.content[i];

            if (content.type == 'ogc' || content.type == 'wfs') {

                for (var k = 0; k < content.layers.length; k++) {
                    if (content.layers[k].snap) {
                        subLayers.push(content.workspace + ':' + content.layers[k].layer);
                    }
                }
                if (subLayers.length > 0) {

                    let srid = this.map.srid;
                    let source = content.source;

                    let vectorSource = new ol.source.Vector({
                        format: this._format,
                        url: function (extent) {
                            return source + '/ows?service=WFS&' +
                                'version=1.0.0&request=GetFeature&' +
                                'typeName=' + subLayers.join() + '&' +
                                'outputFormat=json&srsname=' + srid +
                                '&bbox=' + extent.join(',') + ',' + srid + '&maxFeatures=2000';
                        },
                        strategy: ol.loadingstrategy.bbox
                    });

                    let scale = this._scale;
                    let constant = this._getConstant();
                    this._maxResolution = (parseInt(scale) + 1) / constant;

                    let snapLayer = new ol.layer.Vector({
                        source: vectorSource,
                        maxResolution: this._maxResolution
                    });

                    snapLayer.setOpacity(0);

                    this.map.ol.addLayer(snapLayer);

                    this._sources.push(vectorSource);
                    this._layers.push(snapLayer);

                }
            }
        }
    }

    _getInternalSources() {

        if (this._internalLayers) {

            let layers = this.map.ol.getLayers().getArray();

            for (let i = 0; i < layers.length; i++) {

                const layer = layers[i];
                if (layer instanceof ol.layer.Vector) {

                    this._sources.push(layer.getSource());

                }

            }

        }

    }

    _removeSources() {

        for (let i = 0; i < this._sources.length; i++) {

            this.map.ol.removeLayer(this._layers[i]);
            this._layers[i] = null;
            this._sources[i] = null;

        }

        this._sources = [];
        this._layers = [];

    }

    _getConstant() {

        let INCHES_PER_UNIT = {
            'm': 39.37,
            'dd': 4374754
        };

        let div = document.createElement('div');
        div.style.height = '1in';
        div.style.width = '1in';
        div.style.top = '-100%';
        div.style.left = '-100%';
        div.style.position = 'absolute';
        document.body.appendChild(div);
        let dpi = div.offsetHeight;
        document.body.removeChild(div);

        let unit = this.map.ol.getView().getProjection().getUnits();

        return (INCHES_PER_UNIT[unit] * dpi);

    }

    _addInteraction() {

        this._snaps = [];
        let resolution = this.map.ol.getView().getResolution();
        let pixelTolerance = this._units === 'px' ? parseInt(this._tolerance) : Math.round(parseInt(this._tolerance) / resolution);

        for (let i = 0; i < this._sources.length; i++) {

            let snap = new ol.interaction.Snap({
                source: this._sources[i],
                edge: this._toEdge,
                vertex: this._toVertex,
                pixelTolerance: pixelTolerance
            });

            this._snaps.push(snap);
            this.map.ol.addInteraction(snap);

        }

        let internalLayers = Object.values(this._auxLayers);
        for (let i = 0; i < internalLayers.length; i++) {

            let snap = new ol.interaction.Snap({
                source: internalLayers[i].getSource(),
                edge: this._toEdge,
                vertex: this._toVertex,
                pixelTolerance: pixelTolerance
            });

            this._snaps.push(snap);
            this.map.ol.addInteraction(snap);

        }

        if (this._showAuxFeatures) {

            this.map.ol.addInteraction(this._select.extendSegments);
            this.map.ol.addInteraction(this._select.intersections);
            this.map.ol.addInteraction(this._select.midPoints);
            this.map.ol.addInteraction(this._select.centroids);

        }

    }

    _getAllFeatures() {

        let allFeatures = [];
        let layers = this.map.ol.getLayers().getArray();

        for (let i = 0; i < layers.length; i++) {

            let layer = layers[i];
            if (layer.getSource() instanceof ol.source.Vector) {

                allFeatures = allFeatures.concat(layer.getSource().getFeatures())

            }

        }


        return allFeatures;

    }

    _getViewBBox() {

        let e = ol.proj.transformExtent(map.ol.getView().calculateExtent(), map.srid, 'EPSG:4326');
        let l = turf.lineString([[e[0], e[1]], [e[2], e[3]]]);
        return turf.envelope(l);

    }


    // TODO: Implementar esse método
    _getFeatures() {

        let olFeatures = this._getAllFeatures();
        let featCollection = this._format.writeFeaturesObject(olFeatures, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        let bbox = this._getViewBBox();
        let inBBox = false;
        let rFeatures = [];

        turf.geomEach(featCollection, (currentGeometry, featureIndex, featureProperties, featureBBox, featureId) => {

            // if (currentGeometry) {

            //     if (currentGeometry.type.toLowerCase().indexOf('point') > -1) {

            //         inBBox = turf.booleanPointInPolygon(currentGeometry, bbox);

            //     } else {

            //         // let centroid = turf.centroid(currentGeometry);
            //         // inBBox = turf.booleanPointInPolygon(centroid, bbox);

            //         let intersects = turf.lineIntersect(currentGeometry, bbox);
            //         inBBox = intersects.features.length > 0;


            //     }

            //     if (inBBox) rFeatures.push(turf.feature(currentGeometry));

            //     console.log(inBBox)

            // }

            rFeatures.push(turf.feature(currentGeometry));

            //inBBox = false;

        });

        return turf.featureCollection(rFeatures);

    }

    _createAuxFeatures() {

        this._clearAuxLayers();

        if (this._hasSnap() && this._internalLayers) {

            this._featCollection = this._getFeatures();

            if (this._toExtention) this._createExtendLines();
            if (this._toCentroid) this._createControids();
            if (this._toMidPoint) this._createMidPoints();
            if (this._toIntersection) this._createIntersections();

        }

    }

    _clearAuxLayers() {

        this._auxLayers.extendSegments.getSource().clear();
        this._auxLayers.centroids.getSource().clear();
        this._auxLayers.midPoints.getSource().clear();
        this._auxLayers.intersections.getSource().clear();

    }

    _getDrawDistance() {

        let e = ol.proj.transformExtent(map.ol.getView().calculateExtent(), map.srid, 'EPSG:4326');
        let l = turf.lineString([[e[0] * 1.01, e[1] * 1.01], [e[2] * 1.01, e[3] * 1.01]]);
        let d = turf.length(l, { units: 'meters' });
        return d * 2;

    }

    _getSegmentBearing(segment) {

        let c = segment.geometry.coordinates;
        let p1 = turf.point(c[0]);
        let p2 = turf.point(c[1]);

        return turf.bearing(p1, p2);

    }

    _createExtendLines() {

        let distance = this._getDrawDistance();

        turf.segmentEach(this._featCollection, (segment) => {

            let bearing = this._getSegmentBearing(segment);
            let p1 = turf.point(segment.geometry.coordinates[0]);
            let p2 = turf.point(segment.geometry.coordinates[1]);
            let dest1 = turf.destination(p1, distance, bearing, { units: 'meters' });
            let dest2 = turf.destination(p2, distance, bearing + 180, { units: 'meters' });
            var l1 = turf.lineString([dest1.geometry.coordinates, p2.geometry.coordinates], { __i__: true });
            var l2 = turf.lineString([dest2.geometry.coordinates, p1.geometry.coordinates], { __i__: true });
            var collection = turf.featureCollection([
                l1,
                l2
            ]);

            let olFeature = this._format.readFeaturesFromObject(collection, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            })

            this._auxLayers.extendSegments.getSource().addFeatures(olFeature);

        });

    }

    _createControids() {

        turf.geomEach(this._featCollection, (geom) => {

            if (geom) {

                let geomType = turf.getType(geom);

                if (geomType.toLowerCase().indexOf('polygon') > -1) {

                    let centroid = turf.centroid(geom);
                    let olFeature = this._format.readFeatureFromObject(centroid, {
                        dataProjection: 'EPSG:4326',
                        featureProjection: 'EPSG:3857'
                    })

                    this._auxLayers.centroids.getSource().addFeature(olFeature);

                }
            }
        });
    }

    _createMidPoints() {

        turf.segmentEach(this._featCollection, (segment) => {

            if (segment) {

                let centroid = turf.centroid(segment);
                let olFeature = this._format.readFeatureFromObject(centroid, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                })

                this._auxLayers.midPoints.getSource().addFeature(olFeature);

            }

        });

    }

    _createIntersections() {

        this._featCollection = this._getFeatures();

        turf.segmentEach(this._featCollection, (s1) => {

            if (s1) {

                turf.segmentEach(this._featCollection, (s2) => {

                    if (s2) {

                        let intersects = turf.lineIntersect(s1, s2);

                        if (intersects.features.length > 0) {

                            let olFeature = this._format.readFeaturesFromObject(intersects, {
                                dataProjection: 'EPSG:4326',
                                featureProjection: 'EPSG:3857'
                            })

                            this._auxLayers.intersections.getSource().addFeatures(olFeature);

                        }

                    }

                });

            }

        });

    }

    _removeInteractions() {

        for (let i = 0; i < this._snaps.length; i++) {

            this.map.ol.removeInteraction(this._snaps[i]);

        }

        this.map.ol.removeInteraction(this._select.extendSegments);
        this.map.ol.removeInteraction(this._select.intersections);
        this.map.ol.removeInteraction(this._select.midPoints);
        this.map.ol.removeInteraction(this._select.centroids);

    }

    // External Used
    _refreshInteractions() {

        for (let i = 0; i < this._snaps.length; i++) {

            this.map.ol.removeInteraction(this._snaps[i]);
            this.map.ol.addInteraction(this._snaps[i]);

        }

    }

    // External Used
    _addSource(source) {

        this._sources.push(source);
        this._addInteraction();

    }

    // External Used
    _hasSnap() {

        let scale = this._scale;
        let constant = this._getConstant();
        this._maxResolution = (parseInt(scale) + 1) / constant;

        return this._maxResolution >= this.map.ol.getView().getResolution();

    }

    // External Used
    _registerExternalInteraction() {

        this.map.toolbox.refreshSnap = () => this._refreshInteractions();
        this.map.toolbox.hasSnap = () => this._hasSnap();
        this.map.toolbox.addSnapSource = (source) => this._addSource(source);

    }

    _registerEvents() {

        document.getElementById(`gb-snap${this.id}-save`).addEventListener('click', () => {

            let content;
            for (let i = 0; i < this.map.content.length; i++) {

                content = this.map.content[i];

                if (content.type == 'ogc' || content.type == 'wfs') {

                    for (var k = 0; k < content.layers.length; k++) {

                        content.layers[k].snap = document.getElementById(`gb-snap${this.id}-${i}-${k}`).checked;

                    }
                }
            }

            this._tolerance = document.getElementById(`gb-snap${this.id}-tol`).value;
            this._units = document.getElementById(`gb-snap${this.id}-unit`).value;
            this._scale = document.getElementById(`gb-snap${this.id}-scales`).value;
            this._toVertex = document.getElementById(`gb-snap${this.id}-vertice`).checked;
            this._toEdge = document.getElementById(`gb-snap${this.id}-edge`).checked;

            this._internalLayers = document.getElementById(`gb-snap${this.id}-internal`).checked;
            this._toExtention = document.getElementById(`gb-snap${this.id}-extention`).checked;
            this._toCentroid = document.getElementById(`gb-snap${this.id}-centroid`).checked;
            this._toMidPoint = document.getElementById(`gb-snap${this.id}-midpoint`).checked;
            this._toIntersection = document.getElementById(`gb-snap${this.id}-intersection`).checked;
            this._toOrto = document.getElementById(`gb-snap${this.id}-orto`).checked;
            this._showAuxFeatures = document.getElementById(`gb-snap${this.id}-show-lines`).checked;

            this._removeInteractions();
            this._removeSources();
            this._createSources();
            this._getInternalSources();
            this._createAuxFeatures();
            this._addInteraction();

        });

        this.map.ol.getView().on('change:resolution', () => {

            this._createAuxFeatures();
            this._refreshInteractions();

        });

        this.map.ol.on('moveend', () => {

            this._createAuxFeatures();
            this._refreshInteractions();

        });

    }

    activate() {

        this.show();

    }

    deactivate() {

        this.hide();

    }

}

export { Snap };